import fileDefaultObject from '@snapask/common-components/dist/constants/uploadFileSchemaForZendesk';
import {
  submitReportSuccess,
  submitReportFailed,
  uploadFile,
  uploadFileSuccess,
  removeFile,
  toggleReportForm,
  saveCurrentData,
} from './action';

const INITIAL_FILE = fileDefaultObject();

const INITIAL_STATE = {
  files: [],
  isReportFormOpen: false,
  email: '',
  message: '',
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case submitReportSuccess.type:
    case submitReportFailed.type:
      return {
        ...INITIAL_STATE,
      };
    case uploadFile.type:
      return {
        ...state,
        files: [
          ...state.files,
          {
            ...INITIAL_FILE,
            name: payload.name,
            // To display the file upload process and disable submit button
            isUploading: true,
          },
        ],
      };
    case uploadFileSuccess.type:
      state.files.pop();
      return {
        ...state,
        files: [
          ...state.files,
          {
            ...INITIAL_FILE,
            ...payload,
            // To display the file delete button
            isUploaded: true,
          },
        ],
      };
    case removeFile.type:
      return {
        ...state,
        files: state.files.filter(file => file.token !== payload.token),
      };
    case toggleReportForm.type:
      return {
        ...state,
        isReportFormOpen: !state.isReportFormOpen,
      };
    case saveCurrentData.type:
      return {
        ...state,
        email: payload.email,
        message: payload.message,
      };

    default:
      return state;
  }
};

export default reducer;
