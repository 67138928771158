import { LIVE_TOPIC, COURSE } from 'constants/contentTypes';

export function transformRecommendLiveLesson(lessons) {
  return lessons.map(({ firstFreeLiveLesson }) => {
    const {
      id,
      name,
      liveTopicName,
      liveTopicId,
      livePictureUrl,
    } = firstFreeLiveLesson;
    return {
      id,
      liveTopicId,
      title: name,
      subTitle: liveTopicName,
      pictureUrl: livePictureUrl,
      type: LIVE_TOPIC,
    };
  });
}

export function transformRecommendCourseLesson(lessons) {
  return lessons.map(
    ({
      title: courseName,
      picture: { mediumUrl },
      id: courseId,
      firstPreviewableLesson: { title: courseLessonName, id },
    }) => {
      return {
        id,
        courseId,
        title: courseLessonName,
        subTitle: courseName,
        pictureUrl: mediumUrl,
        type: COURSE,
      };
    },
  );
}
