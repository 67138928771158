import { API_PLATFORM_FOR_WEB } from 'constants/index';
import { formatPriceNumber, formatPriceWithCurrency } from 'utils/price';

export function getFirstWebPayment(paymentMethods = []) {
  return (
    paymentMethods.find(({ platform }) => platform === API_PLATFORM_FOR_WEB) ||
    {}
  );
}

export function transformPlan(rawData) {
  if (!rawData) return rawData;

  const {
    id,
    name,
    longDescription,
    questionQuota: quota,
    designatedQuestion,
    isFocus,
    planType,
    description,
    highlight,
    contractPeriod,
    billingCyclePerMonths,
    hasMorePlans,
    paymentMethods,
    preDiscountPrice,
    expiredAt,
    lastDay,
  } = rawData;

  const {
    currency,
    displayCurrency,
    id: paymentMethodId,
    introductoryPrice,
    price,
    renewable,
  } = getFirstWebPayment(paymentMethods);

  const displayPrice = formatPriceNumber(currency, price);

  const displayPriceWithCurrency = formatPriceWithCurrency(
    currency,
    displayCurrency,
    price,
  );

  return {
    id,
    billingCyclePerMonths,
    contractPeriod,
    currency,
    longDescription,
    description,
    designatedQuestion,
    displayCurrency,
    displayPrice,
    displayPriceWithCurrency,
    hasMorePlans,
    highlight: isFocus ? highlight : '',
    introductoryPrice,
    name,
    paymentMethodId,
    planType,
    preDiscountPrice,
    price,
    quota,
    renewable,
    expiredAt,
    lastDay,
  };
}
