import { call, put, takeLatest } from 'redux-saga/effects';

import { fetchUser, fetchUserSuccess, fetchUserFailure } from './action';

function stop() {
  return new Promise(resolve => {
    setTimeout(() => resolve(), 5000);
  });
}

export function* fetchUserFlow() {
  try {
    yield call(stop);
    yield put(fetchUserSuccess({ name: 'Jacky' }));
  } catch (e) {
    yield put(fetchUserFailure(e.message));
  }
}

function* userSaga(helpers) {
  yield takeLatest(fetchUser.type, fetchUserFlow, helpers);
}

export default userSaga;
