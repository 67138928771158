import urlcat from 'urlcat';

/**
 * @param {string} userAgent
 */
export const getUserOS = userAgent => {
  if (!userAgent) return 'Unknown OS';
  if (userAgent.includes('Win')) return 'Windows';
  if (userAgent.includes('Android')) return 'Android';
  if (userAgent.includes('Mac') && userAgent.includes('Mobile')) return 'iOS';
  if (userAgent.includes('Mac')) return 'MacOS';
  if (userAgent.includes('X11')) return 'UNIX';
  if (userAgent.includes('Linux')) return 'Linux';
  return 'Unknown OS';
};

export const splitLocale = locale => {
  const [language, region] = locale.split('-');

  return {
    language,
    region,
  };
};

export const isLocaleFormat = value => {
  return /^[a-zA-Z]{2}-[a-zA-Z]{2}$/.test(value);
};

export const isRegionOrLocaleFormat = value => {
  return /^[a-zA-Z]{2}(?:-[a-zA-Z]{2})?$/i.test(value);
};

export const safeParseJSON = value => {
  let result;
  try {
    result = JSON.parse(value);
  } catch (e) {
    result = undefined;
  }
  return result;
};

export const generateURL = ({ path, baseUrl, params = {}, locale }) => {
  return urlcat(baseUrl, `/:locale${path}`, { locale, ...params });
};

/**
 * Get the next element based on the current element
 * If the current one is the last, then return itself
 *
 * @param {array} array
 * @param {number|string} currentItem
 * @returns {number|string}
 */
export const getNextItem = (array, currentItem, cycled = false) => {
  if (array.length === 0) return undefined;

  const currentIndex = array.findIndex(item => item === currentItem);
  const lastIndex = array.length - 1;

  const nextIndex = currentIndex + 1;

  if (cycled) return array[nextIndex % array.length];
  return currentIndex === lastIndex ? currentItem : array[nextIndex];
};

export const getNextItemByKey = (array, currentItem = {}, key) => {
  if (array.length === 0) return undefined;

  const currentIndex = array.findIndex(item => item[key] === currentItem[key]);
  const lastIndex = array.length - 1;

  const nextIndex = currentIndex + 1;
  return currentIndex === lastIndex ? currentItem : array[nextIndex];
};

export const convertSnakeToCamelCase = snakeCase => {
  const stringList = snakeCase.split('_');
  const stringExceptFirstList = stringList
    .splice(1)
    .map(string => `${string.charAt(0).toUpperCase()}${string.slice(1)}`);

  return `${stringList[0]}${stringExceptFirstList.join('')}`;
};

export const groupBy = (array, key) =>
  (array || []).reduce((acc, currentValue) => {
    (acc[currentValue[key]] = acc[currentValue[key]] || []).push(currentValue);
    return acc;
  }, {});

export const transformIndexToUppercaseLetter = index =>
  /* UTF-16 code '65' is A */
  String.fromCharCode(65 + index);

export const isServerSide = () => typeof window === 'undefined';

export const getFetchOptions = ({
  baseUrl,
  cookie,
  deviceId,
  os,
  platform,
}) => ({
  baseUrl,
  context: {
    cookie,
    userAgent: JSON.stringify({
      device_id: deviceId,
      os,
      platform,
    }),
  },
  store: null,
});

export const createMatchMedia = query => {
  const match = window.matchMedia(query);

  // for under safari 13
  match.addChangeEventListener = listener => {
    if (!match.addEventListener) {
      match.addListener(listener);
    } else {
      match.addEventListener('change', listener);
    }
  };

  // for under safari 13
  match.removeChangeEventListener = listener => {
    if (!match.removeEventListener) {
      match.removeListener(listener);
    } else {
      match.removeEventListener('change', listener);
    }
  };

  return match;
};

export const isObjectEmpty = obj => {
  if (typeof obj !== 'object') return true;

  return Object.keys(obj).length === 0;
};
