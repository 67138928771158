/**
 * The route for urlcat pathTemplate will suffix with _TEMPLATE
 */

// content types used for routing.
export const REGULAR_CLASS_TYPE = 'regular-class';
export const COURSE_TYPE = 'course';

export const HOME = '/';

// official site
export const LIVE = `/${REGULAR_CLASS_TYPE}`;
export const LIVE_UPCOMING = `${LIVE}/upcoming`;
export const LIVE_TOPIC = `${LIVE}/topics/:id`;
export const LIVE_TOPIC_LESSON = `${LIVE}/topics/:id/lesson/:lessonId`;
export const LIVE_TOPIC_LESSON_TEMPLATE = `${LIVE}/topics/:topicId/lesson/:lessonId`;
export const LIVE_TOPICS = `${LIVE}/topics`;
export const LIVE_SYLLABUS = `${LIVE}/topics/:id/syllabus-lesson/:lessonId`;
export const LIVE_SCHEDULE = `${LIVE}/schedule`;
export const LIVE_SUBSCRIBED = `${LIVE}/subscribed`;

export const COURSE = `/${COURSE_TYPE}`;
export const COURSE_INFORMATION = `${COURSE}/:id(\\d+)`;
export const COURSE_INFORMATION_TEMPLATE = `${COURSE}/:id`;
export const COURSE_INFORMATION_LESSON = `${COURSE}/:id(\\d+)/lesson/:lessonId(\\d+)`;
export const COURSE_INFORMATION_LESSON_TEMPLATE = `${COURSE}/:courseId/lesson/:lessonId`;
export const COURSE_LEARNING = `${COURSE}/learning/:id(\\d+)`;
export const COURSE_LEARNING_TEMPLATE = `${COURSE}/learning/:id`;
export const COURSE_LEARNING_LESSON = `${COURSE}/learning/:id(\\d+)/lesson/:lessonId(\\d+)`;
export const COURSE_LEARNING_LESSON_TEMPLATE = `${COURSE}/learning/:courseId/lesson/:lessonId`;
export const COURSE_SUBSCRIBED = `${COURSE}/subscribed`;
export const COURSE_GROUP = `${COURSE}/group/:id(\\d+)`;
export const COURSE_GROUP_TEMPLATE = `${COURSE}/group/:id`;

export const CONTENT_BUNDLE = '/bundle/:id(\\d+)';
export const CONTENT_BUNDLE_TEMPLATE = '/bundle/:id';
export const CONTENT_PREPACK = '/prepack/:id(\\d+)';
export const CONTENT_PREPACK_TEMPLATE = '/prepack/:id';

// instructor profile
export const INSTRUCTOR_LIST = '/instructors/:type([a-z-]+)';

export const INSTRUCTOR_REGULAR_CLASS_LIST = `/instructors/:id/regular-class`;
export const INSTRUCTOR_CONTENT_LIST = `/instructors/:id/:type([a-z-]+)`;
export const INSTRUCTOR_CONTENT_LIST_TEMPLATE = '/instructors/:id/:type';

export const INSTRUCTOR = '/instructors/:id(\\d+)';
export const INSTRUCTOR_TEMPLATE = '/instructors/:id';

// my learning
export const MY_LEARNING = '/my-learning';
export const MY_LEARNING_CONTINUE = '/my-learning/continue';

export const ACCOUNT_SETUP = '/account/setup';
// dragonfruit
export const LOGIN = '/login';
export const OAUTH_LOGIN = '/sso/login';
export const SIGNUP = '/signup';
export const ACCOUNT = '/account';
export const TERMS = '/terms';
export const PRIVACY = '/privacy';
export const LAW = '/law';
export const TUTORS = '/tutors';
export const INSTANT_QA = '/feature/instant-qa';
export const PREMIUM = '/premium';
export const MISSION = '/mission';

export const PURCHASE_TEMPLATE = '/purchase/:paymentMethodId';
export const SINGLE_PURCHASE_TEMPLATE = '/purchase/single/:id';
export const BUNDLE_PURCHASE_TEMPLATE = '/purchase/bundle/:id';
export const PREPACK_PURCHASE_TEMPLATE = '/purchase/prepack/:id';

export const UPGRADE = '/change-plan/upgrade/:subscriptionId';

// academy
export const DASHBOARD = '/dashboard';
export const POST = '/POST/:id';
