import { createAction, createCommonActions } from 'utils/redux';

export const initialBundleCart = createAction('INITIAL_BUNDLE_CART');

export const [
  bundleCartAddItem,
  bundleCartAddItemSuccess,
] = createCommonActions('BUNDLE_CART_ADD_ITEM');
export const [
  bundleCartRemoveItem,
  bundleCartRemoveItemSuccess,
] = createCommonActions('BUNDLE_CART_REMOVE_ITEM');
