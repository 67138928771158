import { combineReducers } from 'redux';

import { showModal, hideModal, changeLocale } from './action';

const LOCALE_INITIAL_STATE = '';
export const locale = (state = LOCALE_INITIAL_STATE, action) => {
  switch (action.type) {
    case changeLocale.type:
      return action.payload;
    default:
      return state;
  }
};

const MODAL_INITIAL_STATE = {
  visible: false,
  type: '',
  title: '',
  content: '',
  okText: 'OK',
};

export const modal = (state = MODAL_INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case showModal.type:
      return { ...state, ...payload, visible: true };

    case hideModal.type:
      return MODAL_INITIAL_STATE;

    default:
      return state;
  }
};

export default combineReducers({ modal, locale });
