export const getUserIsAuthenticated = state => !!state.user.token;
export const getAccountRequiresFieldList = state =>
  state.user.accountRequiresFieldList;
export const getUserInfo = state => state.user.info;
export const getUserAvatar = state => state.user.info?.avatar;
export const getUsername = state => state.user.info?.username;
export const getUserRole = state => state.user.info?.role;
export const getUserRoleStatus = state => state.user.info?.roleStatus;
export const getUserRegion = state => state.user.info?.region;
export const getUserFaqLink = state => state.user.faq;
export const getUserEmail = state => state.user.info?.email;
export const getUserLoginType = state => state.user.info?.loginType;
export const getIsNowTVUser = state => state.user.info?.isNowTVUser;
export const getUserExternalId = state => state.user.info?.externalId;
