import React from 'react';
import { func, shape, string, object, element } from 'prop-types';
import StyleContext from 'isomorphic-style-loader/StyleContext';
import { Provider as ReduxProvider } from 'react-redux';
import ApplicationContext from '../ApplicationContext';

import useScreenWidth from './hooks/useScreenWidth';
import SocialWidgetHandler from 'components/SocialWidgetHandler/SocialWidgetHandler';

export default function App({ context, insertCss, children }) {
  // NOTE: If you need to add or modify header, footer etc. of the app,
  // please do that inside the Layout component.
  return (
    <ReduxProvider store={context.store}>
      <StyleContext.Provider value={{ insertCss }}>
        <ApplicationContext.Provider
          value={{
            ...context,
            screenWidth: useScreenWidth(context?.userAgent),
          }}
        >
          {React.Children.only(children)}
          <SocialWidgetHandler />
        </ApplicationContext.Provider>
      </StyleContext.Provider>
    </ReduxProvider>
  );
}

App.propTypes = {
  // Enables critical path CSS rendering
  // https://github.com/kriasoft/isomorphic-style-loader
  insertCss: func.isRequired,
  context: shape({
    // Universal HTTP client
    fetchAPI: func.isRequired,
    pathname: string.isRequired,
    query: object,
    store: object.isRequired,
    userAgent: string,
  }).isRequired,
  children: element.isRequired,
};
