import { REGULAR_CLASS_TYPE } from 'constants/routingPath';
import { transformContentHashtags } from 'reduxModules/course/transform';

// eslint-disable-next-line import/prefer-default-export
export const transformRegularClassLessonList = lessonList =>
  lessonList.map(({ name, instructors, hashtag, ...props }) => ({
    ...props,
    contentType: REGULAR_CLASS_TYPE,
    name,
    instructors,
    instructorIdList: instructors
      .map(({ id: instructorId }) => instructorId)
      .join(),
    instructorNameList: instructors
      .map(({ name: instructorName }) => instructorName)
      .join(),
    hashtags: hashtag ? transformContentHashtags([hashtag]).hashtags : [],
  }));
