import { NOWTV_AUTH_TYPE } from 'constants/authType';

// eslint-disable-next-line import/prefer-default-export
export function transformProfileData({
  user: {
    firstName,
    lastName,
    snapaskUid: userId,
    profilePicUrl: avatar,
    role: { name: role },
    roleStatus,
    region: { name: region },
    email,
    username,
    externalId,
  },
  faq,
  loginType,
  userProfileRequiredFields: accountRequiresFieldList = [],
} = {}) {
  return {
    faq,
    accountRequiresFieldList,
    info: {
      firstName,
      lastName,
      username,
      userId,
      externalId,
      email,
      avatar,
      role,
      roleStatus,
      region,
      loginType,
      isNowTVUser: loginType === NOWTV_AUTH_TYPE,
    },
  };
}
