export const AU = 'au';
export const EN_AU = 'en-au';

export const HK = 'hk';
export const ZH_HK = 'zh-hk';
export const EN_HK = 'en-hk';

export const ID = 'id';
export const EN_ID = 'en-id';
export const ID_ID = 'id-id';

export const JP = 'jp';
export const JA_JP = 'ja-jp';

export const KR = 'kr';
export const KO_KR = 'ko-kr';

export const MY = 'my';
export const ZH_MY = 'zh-my';
export const EN_MY = 'en-my';
export const MS_MY = 'ms-my';

export const NZ = 'nz';
export const EN_NZ = 'en-nz';

export const SG = 'sg';
export const EN_SG = 'en-sg';

export const TH = 'th';
export const EN_TH = 'en-th';
export const TH_TH = 'th-th';

export const TW = 'tw';
export const ZH_TW = 'zh-tw';

export const VN = 'vn';
export const VI_VN = 'vi-vn';

export const SUPPORTED_LOCALE_LIST = {
  [ZH_TW]: ZH_TW,
  [EN_HK]: EN_HK,
  [ZH_HK]: ZH_HK,
  [ZH_MY]: ZH_MY,
  [EN_MY]: EN_MY,
  [MS_MY]: MS_MY,
  [EN_ID]: EN_ID,
  [ID_ID]: ID_ID,
  [VI_VN]: VI_VN,
  [EN_SG]: EN_SG,
  [KO_KR]: KO_KR,
  [JA_JP]: JA_JP,
  [TH_TH]: TH_TH,
  [EN_TH]: EN_TH,
  [EN_AU]: EN_AU,
  [EN_NZ]: EN_NZ,
};

export const SUPPORTED_REGION_LOCALE_LIST = {
  [AU]: EN_AU,
  [HK]: EN_HK,
  [ID]: ID_ID,
  [JP]: JA_JP,
  [KR]: KO_KR,
  [MY]: MS_MY,
  [NZ]: EN_NZ,
  [SG]: EN_SG,
  [TH]: TH_TH,
  [VN]: VI_VN,
  [TW]: ZH_TW,
};

export const SUPPORTED_LOCALE_AND_REGION_LIST = {
  ...SUPPORTED_LOCALE_LIST,
  ...SUPPORTED_REGION_LOCALE_LIST,
};

export const DEFAULT_LOCALE = EN_HK;

export const ACADEMY_SUPPORTED_LOCALE_LIST = [
  ZH_TW,
  ZH_HK,
  EN_HK,
  ZH_MY,
  EN_MY,
  MS_MY,
  EN_SG,
];

export const HOME_REVAMP_LOCAL_LIST = [TW, HK];
