import { createCommonActions, createAction } from 'utils/redux';

export const [
  fetchLiveFilterInfo,
  fetchLiveFilterInfoSuccess,
  fetchLiveFilterInfoFailure,
] = createCommonActions('FETCH_LIVE_FILTER_INFO');

export const setLiveFilterInfo = createAction('SET_LIVE_FILTER_INFO');

export const [
  fetchPromotionInfo,
  fetchPromotionInfoSuccess,
  fetchPromotionInfoFailure,
] = createCommonActions('FETCH_PROMOTION_INFO');

export const [
  fetchHomeLessonList,
  fetchHomeLessonListSuccess,
  fetchHomeLessonListFailure,
] = createCommonActions('FETCH_HOME_LESSON_LIST');

export const [
  fetchLiveUpcoming,
  fetchLiveUpcomingSuccess,
  fetchLiveUpcomingFailure,
  fetchLiveUpcomingClear,
] = createCommonActions('FETCH_LIVE_UPCOMING');

export const [
  fetchLiveTopic,
  fetchLiveTopicSuccess,
  fetchLiveTopicFailure,
] = createCommonActions('FETCH_LIVE_TOPIC');

export const [
  fetchLiveTopics,
  fetchLiveTopicsSuccess,
  fetchLiveTopicsFailure,
  fetchLiveTopicsClear,
] = createCommonActions('FETCH_LIVE_TOPICS');

export const [
  updateLiveLessonProgress,
  updateLiveLessonProgressSuccess,
  updateLiveLessonProgressFailure,
] = createCommonActions('UPDATE_LIVE_LESSON_PROGRESS');

export const openLiveModal = createAction('OPEN_LIVE_MODAL');
export const closeLiveModal = createAction('CLOSE_LIVE_MODAL');

export const [
  saveLiveLesson,
  saveLiveLessonSuccess,
  saveLiveLessonFailure,
] = createCommonActions('SAVE_LIVE_LESSON');

export const [
  unSaveLiveLesson,
  unSaveLiveLessonSuccess,
  unSaveLiveLessonFailure,
] = createCommonActions('UNSAVE_LIVE_LESSON');

export const [
  fetchScheduleUpcomingLesson,
  fetchScheduleUpcomingLessonSuccess,
  fetchScheduleUpcomingLessonFailure,
] = createCommonActions('FETCH_SCHEDULE_UPCOMING_LESSON');
export const [
  removeUpcomingScheduleLesson,
  removeUpcomingScheduleLessonSuccess,
  removeUpcomingScheduleLessonFailure,
] = createCommonActions('REMOVE_SCHEDULE_LESSON');

export const [
  fetchSchedulePastLesson,
  fetchSchedulePastLessonSuccess,
  fetchSchedulePastLessonFailure,
] = createCommonActions('FETCH_SCHEDULE_PAST_LESSON');

export const [
  fetchSubscribedTopic,
  fetchSubscribedTopicSuccess,
  fetchSubscribedTopicFailure,
] = createCommonActions('FETCH_SUBSCRIBED_TOPICS');

export const [
  fetchSubscribedPreOrderedTopic,
  fetchSubscribedPreOrderedTopicSuccess,
  fetchSubscribedPreOrderedTopicFailure,
] = createCommonActions('FETCH_SUBSCRIBED_PRE_ORDERED_TOPICS');

export const [
  fetchSubscribedOngoingTopic,
  fetchSubscribedOngoingTopicSuccess,
  fetchSubscribedOngoingTopicFailure,
] = createCommonActions('FETCH_SUBSCRIBED_ONGOING_TOPICS');

export const [
  fetchSubscribedCompletedTopic,
  fetchSubscribedCompletedTopicSuccess,
  fetchSubscribedCompletedTopicFailure,
] = createCommonActions('FETCH_SUBSCRIBED_COMPLETED_TOPICS');

export const [
  postLiveLessonRating,
  postLiveLessonRatingSuccess,
  postLiveLessonRatingFailure,
] = createCommonActions('POST_LIVE_LESSON_RATING');

export const [
  postLiveTopicRating,
  postLiveTopicRatingSuccess,
  postLiveTopicRatingFailure,
] = createCommonActions('POST_LIVE_TOPIC_RATING');

export const [
  fetchRcBanners,
  fetchRcBannersSuccess,
  fetchRcBannersFailure,
] = createCommonActions('FETCH_RC_BANNERS');
