import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { getUserInfo } from 'reduxModules/user/selector';
import { apiSubmitTackingVideoInfo } from 'services/apis';
import {
  submitTackingVideoInfo,
  submitTackingVideoInfoFailure,
  submitTackingVideoInfoSuccess,
} from './action';

export function* submitTackingVideoInfoFlow({ fetchAPI }, { payload }) {
  try {
    const userInfo = yield select(getUserInfo);

    const {
      event,
      progressDuration,
      targetType,
      targetId,
      episodeId,
      isEpisodeEnrolled,
      isBackground,
      isAutoPlay,
      timezone,
    } = payload;

    const isAuth = !!userInfo;

    const userId = isAuth ? Number(userInfo.userId) : undefined;

    yield call(apiSubmitTackingVideoInfo, fetchAPI, {
      event,
      progressDuration,
      targetType,
      targetId,
      episodeId,
      isEpisodeEnrolled,
      isBackground,
      isAutoPlay,
      userId,
      timezone,
    });

    yield put(submitTackingVideoInfoSuccess());
  } catch (error) {
    yield put(submitTackingVideoInfoFailure(error));
  }
}

export default function* tackingSaga(helpers) {
  yield all([
    takeLatest(
      submitTackingVideoInfo.type,
      submitTackingVideoInfoFlow,
      helpers,
    ),
  ]);
}
