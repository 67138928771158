export const buildQuery = (param, value) => {
  const urlParams = new URLSearchParams(window.location.search);
  urlParams.set(param, value);
  window.history.replaceState(
    null,
    null,
    `${window.location.pathname}?${urlParams}`,
  );
};

export const buildPreservedQuery = preservedParams => {
  const currentUrlParams = new URLSearchParams(window.location.search);
  const queries = {};
  preservedParams.forEach(value => {
    if (currentUrlParams.has(value)) {
      queries[value] = currentUrlParams.get(value);
    }
  });

  const newUrlParams = new URLSearchParams();
  Object.entries(queries).forEach(([key, value]) => {
    newUrlParams.set(key, value);
  });

  return newUrlParams;
};

export const checkQueryExist = param => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.has(param);
};

export const getQuery = param => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param);
};

export const getQueryiesObject = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const queries = {};
  urlParams.forEach((value, key) => {
    queries[key] = value;
  });
  return queries;
};

export const clearQuery = queries => {
  const urlParams = new URLSearchParams(window.location.search);
  queries.forEach(query => {
    urlParams.delete(query);
  });

  const searchString = urlParams ? `?${urlParams.toString()}` : '';
  window.history.replaceState(
    null,
    null,
    `${window.location.pathname}${searchString}`,
  );
};

export const clearAllQuery = () => {
  window.history.replaceState(null, null, window.location.pathname);
};
