import { all, takeLatest } from 'redux-saga/effects';

import { sendGTMEvent } from './action';

export function sendGTMEventFlow(_, action) {
  const { eventName, data = {} } = action.payload;
  try {
    const event = {
      event: eventName,
      ...data,
    };

    const dataLayer = window.dataLayer || [];
    // Before sending new event, we need to make
    // sure that the fields sent are clear
    // as dataLayer appends the data
    // to the previous data
    // please look at https://www.bounteous.com/insights/2016/03/21/removing-values-datalayer-data-layer-best-practices-pt-4/

    dataLayer.push(function resetDataLayer() {
      this.reset();
    });

    dataLayer.push(event);
  } catch {
    // do nothing
  }
}

function* googleTagManagerSaga(helpers) {
  yield all([takeLatest(sendGTMEvent.type, sendGTMEventFlow, helpers)]);
}

export default googleTagManagerSaga;
