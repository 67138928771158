import { createCommonActions } from 'utils/redux';

export const [
  fetchSchoolTypeList,
  fetchSchoolTypeListSuccess,
  fetchSchoolTypeListFailure,
] = createCommonActions('FETCH_SCHOOL_TYPE_LIST');

export const [
  fetchSubjectList,
  fetchSubjectListSuccess,
  fetchSubjectListFailure,
] = createCommonActions('FETCH_SUBJECT_LIST');

export const [
  updateUserAttributeData,
  updateUserAttributeDataSuccess,
  updateUserAttributeDataFailure,
] = createCommonActions('UPDATE_USER_ATTRIBUTE_DATA');

export const [
  sendPhoneVerificationCode,
  sendPhoneVerificationCodeSuccess,
  sendPhoneVerificationCodeFailure,
  sendPhoneVerificationCodeClear,
] = createCommonActions('SEND_PHONE_VERIFICATION_CODE');

export const [
  verifyPhone,
  verifyPhoneSuccess,
  verifyPhoneFailure,
  verifyPhoneClear,
] = createCommonActions('VERIFY_PHONE');

export const [
  resentPhoneVerificationCode,
  resentPhoneVerificationCodeSuccess,
  resentPhoneVerificationCodeFailure,
  resentPhoneVerificationCodeClear,
] = createCommonActions('RESEND_PHONE_VERIFICATION_CODE');
