// eslint-disable-next-line import/prefer-default-export
export const getInstructorProfile = state =>
  state.instructorProfile.instructorProfile;
export const getInstructor = state =>
  state.instructorProfile.instructorProfile.instructor;
export const getInstructorContentList = state =>
  state.instructorProfile.contentList;

export const getInstructorList = state =>
  state.instructorProfile.instructorList.instructorList;

export const getInstructorListPageSetting = state => {
  const {
    page,
    sizePerPage,
    totalPages,
  } = state.instructorProfile.instructorList.pageSetting;

  return { page, sizePerPage, totalPages, hasMorePage: page < totalPages };
};
