import { combineReducers } from 'redux';
import {
  fetchMyLearningUpNextSuccess,
  fetchMyLearningDataSuccess,
  fetchMyLearningContinueWatching,
  fetchMyLearningContinueWatchingSuccess,
  fetchMyLearningRecommendLessonSuccess,
} from './action';

const INIT_UP_NEXT_DATA = {
  courseLesson: null,
  liveLesson: null,
};

const upNextData = (state = INIT_UP_NEXT_DATA, action) => {
  switch (action.type) {
    case fetchMyLearningUpNextSuccess.type: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};

const INIT_MY_LEARNING_DATA = {
  continueWatchingLessons: [],
};
const myLearningData = (state = INIT_MY_LEARNING_DATA, action) => {
  switch (action.type) {
    case fetchMyLearningDataSuccess.type:
      return {
        ...state,
        continueWatchingLessons: action.payload.continueWatchingLessons,
      };

    default:
      return state;
  }
};

const INIT_CONTINUE_WATCHING_DATA = {
  continueWatchingLessons: [],
  pageSetting: {
    page: 1,
    sizePerPage: 8,
    totalPages: 0,
  },
};
const continueWatching = (
  state = INIT_CONTINUE_WATCHING_DATA,
  { type, payload },
) => {
  switch (type) {
    case fetchMyLearningContinueWatching.type:
      return {
        ...state,
        pageSetting: {
          ...state.pageSetting,
          ...(!!payload?.page && { page: payload.page }),
        },
      };
    case fetchMyLearningContinueWatchingSuccess.type:
      return {
        ...state,
        continueWatchingLessons: [
          ...state.continueWatchingLessons,
          ...payload.continueWatchingLessons,
        ],
        pageSetting: {
          ...state.pageSetting,
          ...(!!payload?.totalPages && { totalPages: payload.totalPages }),
          ...(!!payload?.page && { page: payload.page }),
        },
      };

    default:
      return state;
  }
};

const INIT_RECOMMEND_LESSONS = {
  freeLiveTopics: [],
  freeCourses: [],
};

const recommendLessons = (state = INIT_RECOMMEND_LESSONS, action) => {
  switch (action.type) {
    case fetchMyLearningRecommendLessonSuccess.type:
      return {
        ...action.payload,
      };

    default:
      return state;
  }
};

export default combineReducers({
  upNextData,
  myLearningData,
  continueWatching,
  recommendLessons,
});
