export const getLiveGradeLevels = state =>
  state.live.liveFilterInfo.gradeLevels;
// export const getLiveSubjects = state => state.live.liveFilterInfo.subjects;
export const getLiveFilterInfo = state => state.live.liveFilterInfo;
export const getLivePromotionModal = state => state.live.livePromotionModal;

// Home Page
export const getLiveHomeLesson = state => state.live.liveHomeLesson;

export const getLivePromotion = state => state.live.livePromotion;

export const getLivePromotionMedia = state =>
  getLivePromotion(state).livePromotionMedia;

export const getUserLiveSubscription = state =>
  getLivePromotion(state).userLiveSubscription;

export const getUserLiveAccessType = state =>
  getUserLiveSubscription(state).accessType;

export const getUserLiveSubscriptionPaymentType = state =>
  getUserLiveSubscription(state)?.paymentMethod?.methodType;

// Live Upcoming  Page
export const getLiveUpcomingLessons = state => state.live.liveUpcomingLessons;

export const getLiveUpcomingLessonsPageSetting = state => {
  const { page, sizePerPage, totalPages } = state.live.liveUpcomingLessons;

  return { page, sizePerPage, totalPages, hasMorePage: page < totalPages };
};

// Live Topic Page
export const getLiveTopic = state => state.live.liveTopic;
export const getLiveTopicInfo = state => getLiveTopic(state).liveTopicInfo;
export const getLiveTopicSyllabuses = state => getLiveTopic(state).syllabuses;

export const getLiveSubjectInfo = state => getLiveTopic(state).liveSubject;

export const getLiveScheduledLessons = state =>
  getLiveTopic(state).scheduledLessons;
export const getLiveFreeLessonList = state =>
  getLiveTopic(state).freeLessonList;
export const getLiveFinishedLessons = state =>
  getLiveTopic(state).finishedLessons;
export const getLiveFAQs = state => getLiveTopic(state).faqs;

// Live topic list page
export const getLiveTopics = state => state.live.liveTopics.liveTopics;
export const getLiveTopicsPageSetting = state => {
  const { page, sizePerPage, totalPages } = state.live.liveTopics.pageSetting;

  return { page, sizePerPage, totalPages, hasMorePage: page < totalPages };
};

// Live user schedule page

export const getLiveUserScheduleUpcomingLessons = state =>
  state.live.liveUserSchedule.upcoming;
export const getLiveUserSchedulePastLessons = state =>
  state.live.liveUserSchedule.past;

export const getLiveUserSubscribedTopics = state =>
  state.live.liveUserSubscribe.all.topics;

export const getLiveUserSubscribedPreOrderedTopics = state =>
  state.live.liveUserSubscribe.preOrdered.topics;

export const getLiveUserSubscribedOngoingTopics = state =>
  state.live.liveUserSubscribe.ongoing.topics;
export const getLiveUserSubscribedCompletedTopics = state =>
  state.live.liveUserSubscribe.completed.topics;

export const getLiveUserScheduleUpcomingPageSetting = state => {
  const {
    page,
    sizePerPage,
    totalPages,
  } = state.live.liveUserSchedule.upcoming;
  return { page, sizePerPage, totalPages, hasMorePage: page < totalPages };
};

export const getLiveUserSchedulePastPageSetting = state => {
  const { page, sizePerPage, totalPages } = state.live.liveUserSchedule.past;
  return { page, sizePerPage, totalPages, hasMorePage: page < totalPages };
};

export const getLiveUserSubscribedPageSetting = state => {
  const {
    page,
    sizePerPage,
    totalPages,
  } = state.live.liveUserSubscribe.all.pageSetting;
  return { page, sizePerPage, totalPages, hasMorePage: page < totalPages };
};

export const getLiveUserSubscribedPreOrderedPageSetting = state => {
  const {
    page,
    sizePerPage,
    totalPages,
  } = state.live.liveUserSubscribe.preOrdered.pageSetting;
  return { page, sizePerPage, totalPages, hasMorePage: page < totalPages };
};

export const getLiveUserSubscribedOngoingPageSetting = state => {
  const {
    page,
    sizePerPage,
    totalPages,
  } = state.live.liveUserSubscribe.ongoing.pageSetting;
  return { page, sizePerPage, totalPages, hasMorePage: page < totalPages };
};

export const getLiveUserSubscribedCompletedPageSetting = state => {
  const {
    page,
    sizePerPage,
    totalPages,
  } = state.live.liveUserSubscribe.completed.pageSetting;
  return { page, sizePerPage, totalPages, hasMorePage: page < totalPages };
};

export const getRcBannerList = state => state.live.liveTopics.bannerList;
