import { createCommonActions, createAction } from 'utils/redux';

export const [
  fetchBannerList,
  fetchBannerListSuccess,
  fetchBannerListFailure,
] = createCommonActions('FETCH_HOME_REVAMP_BANNER_LIST');

export const [
  fetchGradeLevelFilterList,
  fetchGradeLevelFilterListSuccess,
  fetchGradeLevelFilterListFailure,
] = createCommonActions('FETCH_GRADE_LEVEL_FILTER_LIST');

export const setFilterInfo = createAction('SET_FILTER_INFO');

export const clearFilterInfo = createAction('CLEAR_FILTER_INFO');

export const clearRegularClassLessonList = createAction(
  'CLEAR_HOME_REVAMP_REGULAR_CLASS_LESSON_LIST',
);

export const [
  fetchRegularClassLessonList,
  fetchRegularClassLessonListSuccess,
  fetchRegularClassLessonListFailure,
] = createCommonActions('FETCH_HOME_REVAMP_REGULAR_CLASS_LESSON_LIST');

export const [
  fetchCourseList,
  fetchCourseListSuccess,
  fetchCourseListFailure,
] = createCommonActions('FETCH_HOME_REVAMP_COURSE_LIST');

export const [
  fetchGroupedCourseList,
  fetchGroupedCourseListSuccess,
  fetchGroupedCourseListFailure,
] = createCommonActions('FETCH_HOME_REVAMP_GROUPED_COURSE_LIST');

export const [
  fetchAcademyList,
  fetchAcademyListSuccess,
  fetchAcademyListFailure,
] = createCommonActions('FETCH_HOME_REVAMP_ACADEMY_LIST');
