import { createCommonActions } from 'utils/redux';

export const [
  fetchMyLearningData,
  fetchMyLearningDataSuccess,
  fetchMyLearningDataFailure,
] = createCommonActions('FETCH_MY_LEARNING_DATA');

export const [
  fetchMyLearningUpNext,
  fetchMyLearningUpNextSuccess,
  fetchMyLearningUpNextFailure,
] = createCommonActions('FETCH_MY_LEARNING_UP_NEXT');

export const [
  fetchMyLearningContinueWatching,
  fetchMyLearningContinueWatchingSuccess,
  fetchMyLearningContinueWatchingFailure,
] = createCommonActions('FETCH_MY_LEARNING_CONTINUE_WATCHING');

export const [
  fetchMyLearningRecommendLesson,
  fetchMyLearningRecommendLessonSuccess,
  fetchMyLearningRecommendLessonFailure,
] = createCommonActions('FETCH_MY_LEARNING_RECOMMEND_LESSON');
