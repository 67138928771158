import { createCommonActions, createAction } from 'utils/redux';

export const [
  fetchCheckoutCollectionList,
  fetchCheckoutCollectionListSuccess,
  fetchCheckoutCollectionListFailure,
] = createCommonActions('FETCH_CHECKOUT_COLLECTION_LIST');

export const [
  fetchLiveTopicMainCheckoutCollection,
  fetchLiveTopicMainCheckoutCollectionSuccess,
  fetchLiveTopicMainCheckoutCollectionFailure,
] = createCommonActions('FETCH_LIVE_TOPIC_MAIN_CHECKOUT_COLLECTION');

export const fetchCourseMainCheckoutCollectionSuccess = createAction(
  'FETCH_COURSE_MAIN_CHECKOUT_COLLECTION_SUCCESS',
);

export const [
  fetchCheckoutCollectionWithContent,
  fetchCheckoutCollectionWithContentSuccess,
  fetchCheckoutCollectionWithContentFailure,
] = createCommonActions('FETCH_CHECKOUT_COLLECTION_WITH_CONTENT');

export const [
  fetchBundleCheckoutCollectionList,
  fetchBundleCheckoutCollectionListSuccess,
  fetchBundleCheckoutCollectionListFailure,
] = createCommonActions('FETCH_BUNDLE_CHECKOUT_COLLECTION_LIST');
