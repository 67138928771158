import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { REGULAR_CLASS } from 'constants/contentTypes';
import {
  apiFetchBannerList,
  apiFetchLiveTopics,
  apiFetchInstructorList,
  apiFetchCourseList,
  apiFetchAcademyHomePageInfo,
  apiFetchAcademyList,
} from 'services/apis';
import { getAppRegion } from 'reduxModules/app/selector';

import { transformRegularClassLessonList } from './transform';
import { transformInstructorList } from '../instructorProfile/transform';
import { normalizeCourseMenuList } from '../course/transform';

import {
  fetchBannerList,
  fetchBannerListSuccess,
  fetchBannerListFailure,
  fetchRegularClassLessonList,
  fetchRegularClassLessonListSuccess,
  fetchRegularClassLessonListFailure,
  fetchRegularClassInstructorList,
  fetchRegularClassInstructorListSuccess,
  fetchRegularClassInstructorListFailure,
  fetchCourseList,
  fetchCourseListSuccess,
  fetchCourseListFailure,
  fetchAcademyList,
  fetchAcademyListSuccess,
  fetchAcademyListFailure,
} from './action';

export function* fetchBannerListFlow({ fetchAPI }) {
  const region = yield select(getAppRegion);
  try {
    const { banners } = yield call(apiFetchBannerList, fetchAPI, {
      region,
      limit: 10,
    });

    yield put(fetchBannerListSuccess(banners));
  } catch {
    yield put(fetchBannerListFailure());
  }
}

export function* fetchRegularClassLessonListFlow({ fetchAPI }) {
  const region = yield select(getAppRegion);
  try {
    const { liveTopics: lessonList } = yield call(
      apiFetchLiveTopics,
      fetchAPI,
      {
        region,
        sizePerPage: 4,
      },
    );

    yield put(
      fetchRegularClassLessonListSuccess(
        transformRegularClassLessonList(lessonList),
      ),
    );
  } catch {
    yield put(fetchRegularClassLessonListFailure());
  }
}

export function* fetchRegularClassInstructorListFlow({ fetchAPI }) {
  try {
    const { instructors } = yield call(apiFetchInstructorList, fetchAPI, {
      contentType: REGULAR_CLASS,
      page: 1,
      sizePerPage: 5,
    });

    yield put(
      fetchRegularClassInstructorListSuccess(
        transformInstructorList(instructors),
      ),
    );
  } catch {
    yield put(fetchRegularClassInstructorListFailure());
  }
}

export function* fetchCourseListFlow({ fetchAPI }) {
  try {
    const { courses } = yield call(apiFetchCourseList, fetchAPI, {
      currentPage: 1,
      pageSize: 20,
    });

    yield put(fetchCourseListSuccess(normalizeCourseMenuList(courses)));
  } catch {
    yield put(fetchCourseListFailure());
  }
}

export function* fetchAcademyListFlow({ fetchAPI }) {
  const region = yield select(getAppRegion);
  try {
    const { id: homePageId } = yield call(
      apiFetchAcademyHomePageInfo,
      fetchAPI,
      {
        region,
      },
    );

    const postList = yield call(apiFetchAcademyList, fetchAPI, {
      homePageId,
    });

    yield put(fetchAcademyListSuccess(postList));
  } catch {
    yield put(fetchAcademyListFailure());
  }
}

export default function*(helpers) {
  yield all([
    takeLatest(fetchBannerList.type, fetchBannerListFlow, helpers),
    takeLatest(
      fetchRegularClassLessonList.type,
      fetchRegularClassLessonListFlow,
      helpers,
    ),
    takeLatest(
      fetchRegularClassInstructorList.type,
      fetchRegularClassInstructorListFlow,
      helpers,
    ),
    takeLatest(fetchCourseList.type, fetchCourseListFlow, helpers),
    takeLatest(fetchAcademyList.type, fetchAcademyListFlow, helpers),
  ]);
}
