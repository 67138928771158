import { combineReducers } from 'redux';

import { fetchSchoolTypeListSuccess, fetchSubjectListSuccess } from './action';

export const schoolTypeList = (state = [], { type, payload }) => {
  switch (type) {
    case fetchSchoolTypeListSuccess.type:
      return payload;

    default:
      return state;
  }
};

export const subjectList = (state = [], { type, payload }) => {
  switch (type) {
    case fetchSubjectListSuccess.type:
      return payload;

    default:
      return state;
  }
};

export default combineReducers({
  schoolTypeList,
  subjectList,
});
