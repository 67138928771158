import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

import rootReducer from 'reduxModules/reducers';
import rootSaga from 'reduxModules/sagas';
import navigationMiddleware from './middlewares/navigation';

import { name, version } from '../../package.json';
import createLogger from './logger';

export default function configureStore(initialState, helpers) {
  const sagaMiddleware = createSagaMiddleware();
  const middleware = [sagaMiddleware, navigationMiddleware(helpers.history)];

  let enhancer;

  if (__DEV__) {
    middleware.push(createLogger());

    // https://github.com/zalmoxisus/redux-devtools-extension#14-using-in-production
    const composeEnhancers = composeWithDevTools({
      // Options: https://github.com/zalmoxisus/redux-devtools-extension/blob/master/docs/API/Arguments.md#options
      name: `${name}@${version}`,
    });

    // https://redux.js.org/docs/api/applyMiddleware.html
    enhancer = composeEnhancers(applyMiddleware(...middleware));
  } else {
    enhancer = applyMiddleware(...middleware);
  }

  // https://redux.js.org/docs/api/createStore.html
  const store = createStore(rootReducer, initialState, enhancer);

  // Hot reload reducers (requires Webpack or Browserify HMR to be enabled)
  if (__DEV__ && module.hot) {
    module.hot.accept('reduxModules/reducers', () =>
      // eslint-disable-next-line global-require
      store.replaceReducer(require('reduxModules/reducers').default),
    );
  }
  sagaMiddleware.run(rootSaga, helpers);
  store.runSagas = (saga, ...args) =>
    sagaMiddleware.run(saga, helpers, ...args);
  return store;
}
