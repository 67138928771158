import { useEffect, useState } from 'react';

import {
  WIDE_DESKTOP_WIDTH,
  DESKTOP_WIDTH,
  TABLET_WIDTH,
} from 'styles/variables.scss';

import { deviceDetect } from 'react-device-detect';
import { createMatchMedia } from 'utils/common';

const WIDE_DESKTOP_QUERY = `(min-width: ${WIDE_DESKTOP_WIDTH}px)`;
const DESKTOP_QUERY = `(min-width: ${DESKTOP_WIDTH}px) and (max-width: ${WIDE_DESKTOP_WIDTH -
  1}px)`;
const TABLET_QUERY = `(min-width: ${TABLET_WIDTH}px) and (max-width: ${DESKTOP_WIDTH -
  1}px)`;
const MOBILE_QUERY = `(max-width: ${TABLET_WIDTH - 1}px)`;

const getInitialMatchResult = userAgent => {
  const {
    isBrowser = false,
    isMobile = false,
    isTablet = false,
  } = deviceDetect(userAgent);

  return {
    isWideDesktop: isBrowser,
    isDesktop: isBrowser,
    isTablet,
    isMobile,
  };
};

export default function useScreenWidth(userAgent) {
  const [matchResult, setMatchResult] = useState(
    getInitialMatchResult(userAgent || window.navigator.userAgent),
  );

  useEffect(() => {
    const wideDesktop = createMatchMedia(WIDE_DESKTOP_QUERY);
    const desktop = createMatchMedia(DESKTOP_QUERY);
    const tablet = createMatchMedia(TABLET_QUERY);
    const mobile = createMatchMedia(MOBILE_QUERY);

    setMatchResult({
      isWideDesktop: wideDesktop.matches,
      isDesktop: desktop.matches,
      isTablet: tablet.matches,
      isMobile: mobile.matches,
    });

    const handleChange = e => {
      setMatchResult(prev => ({
        ...prev,
        ...(e.media === WIDE_DESKTOP_QUERY && { isWideDesktop: e.matches }),
        ...(e.media === DESKTOP_QUERY && { isDesktop: e.matches }),
        ...(e.media === TABLET_QUERY && { isTablet: e.matches }),
        ...(e.media === MOBILE_QUERY && { isMobile: e.matches }),
      }));
    };
    wideDesktop.addChangeEventListener(handleChange);
    desktop.addChangeEventListener(handleChange);
    tablet.addChangeEventListener(handleChange);
    mobile.addChangeEventListener(handleChange);

    return () => {
      wideDesktop.removeChangeEventListener(handleChange);
      desktop.removeChangeEventListener(handleChange);
      tablet.removeChangeEventListener(handleChange);
      mobile.removeChangeEventListener(handleChange);
    };
  }, []);

  return matchResult;
}
