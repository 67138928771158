import { call, put, takeLatest } from 'redux-saga/effects';

import { apiFetchSiteInfo } from 'services/apis';
import {
  fetchSiteInfo,
  fetchSiteInfoSuccess,
  fetchSiteInfoFailure,
} from './action';

export function* fetchSiteInfoFlow({ fetchAPI }) {
  try {
    const {
      faq,
      offering,
      multipleOfferingMode,
      regularClassCampaignLinks,
      courseCampaignLinks,
      activityLinks,
      showInstructorBtn,
    } = yield call(apiFetchSiteInfo, fetchAPI);

    const { webFooter: zendeskFAQLink } = faq;

    yield put(
      fetchSiteInfoSuccess({
        zendeskFAQLink,
        offerings: offering,
        multipleOfferingMode,
        showInstructorBtn,
        campaignLinks: {
          regularClass: regularClassCampaignLinks,
          course: courseCampaignLinks,
          activity: activityLinks,
        },
      }),
    );
  } catch {
    yield put(fetchSiteInfoFailure());
  }
}

export default function* siteInfoSaga(helpers) {
  yield takeLatest(fetchSiteInfo.type, fetchSiteInfoFlow, helpers);
}
