import dayjs from 'dayjs';
import {
  stringToDayJSObject,
  formatTimeRange,
  stringToDateObject,
} from 'utils/time';
import { groupBy } from 'utils/common';

import getEpisodeState from 'routes/live/utils/getEpisodeState';

import {
  FINISHED,
  FREE,
  LIVE,
  UPCOMING,
} from 'routes/live/constants/liveStatus';
import {
  ABOUT,
  SCHEDULE,
  MATERIALS,
  INSTRUCTOR,
  QUESTIONS,
  REVIEW,
  SUMMARY,
  KEY_POINT,
} from 'routes/live/constants/topicTabs';
import { VIDEO_NOT_UPLOADED } from 'routes/live/constants/liveEpisodeState';
import { ENROLLED, ENROLL_PENDING } from 'constants/enrollStatus';
import { getSubjectString } from 'reduxModules/instructorProfile/utils';

export const transformSubjects = subjectList =>
  subjectList.map(({ id, description }) => ({ id, name: description }));

export const transformGradeLevels = gradeLevels => {
  return gradeLevels.map(({ schoolGradeLevel, ...gradeLevel }) => {
    return { ...gradeLevel, name: schoolGradeLevel };
  });
};

export const getFirstSubjectHasPayment = subjectList => {
  const checkPlanHasPayment = plan => plan?.paymentMethods?.length > 0;
  return subjectList.find(({ plan }) => checkPlanHasPayment(plan)) || {};
};

export const transformPromotion = promotionData => {
  const {
    userLiveSubscription,
    media: [livePromotionMedia = {}] = [],
  } = promotionData;

  const { type, url: mediaUrl, mobileUrl: mediaMobileUrl } = livePromotionMedia;

  return {
    userLiveSubscription,
    livePromotionMedia: {
      mediaIsVideo: type === 'video',
      mediaUrl,
      mediaMobileUrl,
    },
  };
};

export const transformedInstructors = instructors =>
  instructors.map(instructor => {
    const { subjects, instructorHashtags } = instructor;
    const hashtagString = instructorHashtags.join('•');

    return {
      subjectString: getSubjectString(subjects),
      hashtagString,
      ...instructor,
    };
  });

export const transformLesson = ({
  chatRoomType,
  chatRoomUrl,
  endAt,
  id,
  instructors = [],
  isRateable,
  isSaved,
  isTopicEnrolled,
  learningProgress,
  livePictureUrl,
  liveSubjectId,
  liveSubjectName,
  liveTopicHashtag,
  liveTopicId,
  liveTopicName,
  materials,
  name,
  offlineVideoDuration,
  previewImageUrl,
  progressDuration,
  isSemester,
  semesterExpiredDate,
  startAt,
  status,
  url,
  videoId,
  videoSource,
  watchEnabled,
  watchExpiredAt,
}) => {
  const now = dayjs(new Date());
  const expireDate = isSemester ? semesterExpiredDate : watchExpiredAt;
  const expiredDays = stringToDayJSObject(expireDate).diff(now, 'day');
  const expiredHours = stringToDayJSObject(expireDate).diff(now, 'hour');

  const remainingDuration = offlineVideoDuration - progressDuration;

  const isLive = status === LIVE;

  const {
    date: startDate,
    isTomorrow: startTomorrow,
    isToday: startToday,
  } = stringToDateObject(startAt);
  const timeRange = formatTimeRange(startAt, endAt);

  const isFinished = status === FINISHED;
  const isUpcoming = status === UPCOMING;

  const state = getEpisodeState({
    isTopicEnrolled,
    isLive,
    isFinished,
    isUpcoming,
    watchEnabled,
    videoSource,
    videoId,
    chatRoomUrl,
    chatRoomType,
    videoUrl: url,
  });

  return {
    chatRoomType,
    chatRoomUrl,
    expiredDays,
    expiredHours,
    id,
    instructors: transformedInstructors(instructors),
    instructorIdList: instructors
      .map(({ id: instructorId }) => instructorId)
      .join(),
    instructorNameList: instructors
      .map(({ name: instructorName }) => instructorName)
      .join(),
    isCompleted: learningProgress === 1,
    isFinished,
    isFree: status === FREE,
    isLive,
    isRateable,
    // used to check if user has checked the lesson at client side.
    isRated: false,
    isSaved,
    isDisabled: state === VIDEO_NOT_UPLOADED,
    learningProgress,
    livePictureUrl,
    liveSubjectId,
    liveSubjectName,
    liveTopicHashtag,
    liveTopicId,
    liveTopicName,
    materials,
    name,
    previewImageUrl,
    progressDuration,
    remainingDurationMinutes: Math.ceil(remainingDuration / 60),
    startAt,
    status,
    state,
    time: { startDate, startToday, startTomorrow, timeRange, isLive },
    url,
    videoDuration: offlineVideoDuration,
    videoId,
    videoSource,
    watchEnabled,
    watchExpiredAt,
  };
};

export const transformLessons = ({ liveLessons }, { maxLength } = {}) => {
  if (!maxLength) return liveLessons.map(transformLesson);

  const slicedLessons = liveLessons.slice(0, maxLength);
  return slicedLessons.map(transformLesson);
};

export const transformTopics = topics =>
  topics.map(topic => ({
    ...topic,
    instructors: transformedInstructors(topic.instructors),
    instructorNameList: topic.instructors
      .map(({ name: instructorName }) => instructorName)
      .join(),
    instructorIdList: topic.instructors
      .map(({ id: instructorId }) => instructorId)
      .join(),
  }));

export const transformLiveLessonByDate = ({
  lessonList,
  isTopicEnrolled,
  isSemester,
  semesterExpiredDate,
}) => {
  const liveLessonByDate = {};

  lessonList.forEach(lesson => {
    const startAt = stringToDayJSObject(lesson.startAt).toDate();

    const lastMidnight = new Date(
      startAt.getFullYear(),
      startAt.getMonth(),
      startAt.getDate(),
    ).toISOString();

    liveLessonByDate[lastMidnight] ??= [];
    liveLessonByDate[lastMidnight].push(
      transformLesson({
        ...lesson,
        isSemester,
        semesterExpiredDate,
        isTopicEnrolled,
      }),
    );
  });

  return {
    liveLessonByDate,
    allDates: Object.keys(liveLessonByDate),
    allLiveLessons: lessonList.map(lesson =>
      transformLesson({
        ...lesson,
        isTopicEnrolled,
        isSemester,
        semesterExpiredDate,
      }),
    ),
  };
};

export const mergeLiveLessonByDate = (
  originalLessonByDate,
  newLessonByDate,
) => {
  const resultLessonByDate = { ...originalLessonByDate };

  Object.entries(newLessonByDate).forEach(([lessonDate, lessons]) => {
    resultLessonByDate[lessonDate] ??= [];
    resultLessonByDate[lessonDate] = resultLessonByDate[lessonDate].concat(
      lessons,
    );
  });

  return resultLessonByDate;
};

export const transformLiveTopicInfo = ({
  active,
  contentFeatures,
  description,
  metaDescription,
  detailInfo,
  enrollStatus,
  enrollStartAt,
  id,
  instructors,
  frequency,
  isRateable,
  isSemester,
  semesterExpiredDate,
  name,
  picture,
  pictureUrl,
  scheduleUrl,
  upcomingLessonStartAt,
  averageRate,
  ratingCount,
  ratings,
}) => {
  const getEnrollStartAtString = string => {
    const { date } = stringToDateObject(string);
    return date;
  };

  const getUpcomingLessonStartAtString = string => {
    const { dateTime } = stringToDateObject(string);
    return dateTime;
  };

  return {
    active,
    picture,
    contentFeatures,
    description,
    metaDescription,
    detailInfo,
    frequency,
    isSemester,
    semesterExpiredDate,
    enrollStartAt: getEnrollStartAtString(enrollStartAt),
    id,
    instructors: transformedInstructors(instructors),
    instructorNameList: instructors
      .map(({ name: instructorName }) => instructorName)
      .join(),
    instructorIdList: instructors
      .map(({ id: instructorId }) => instructorId)
      .join(),
    isRateable,
    isRated: false,
    name,
    pictureUrl,
    scheduleUrl,
    isEnrolled: enrollStatus === ENROLLED,
    isPreOrdered: enrollStatus === ENROLL_PENDING,
    isOngoing: !!upcomingLessonStartAt,
    upcomingLessonStartAt: getUpcomingLessonStartAtString(
      upcomingLessonStartAt,
    ),
    averageRate,
    ratingCount,
    ratings,
  };
};

export const transformLiveTopic = topic => {
  if (!topic) return undefined;
  const {
    liveSubject,
    liveTopic,
    scheduledLessons,
    allMaterials,
    freeLessons: freeLessonList,
    finishedLessons,
  } = topic;
  const { faqs = [], instructors = [], syllabuses, ratings } = liveTopic;
  const {
    LiveTopic: topicMaterials = [],
    LiveLesson: lessonMaterials = [],
  } = groupBy(allMaterials, 'ownerType');

  const lessonMaterialsGroupByName = groupBy(lessonMaterials, 'ownerName');

  const lessonMaterialsWithLessonName = Object.entries(
    lessonMaterialsGroupByName,
  ).map(([lessonName, materials]) => ({ lessonName, materials }));

  const tabKeyContentMapping = {
    [INSTRUCTOR]: instructors,
    [SCHEDULE]: scheduledLessons,
    [MATERIALS]: allMaterials,
    [REVIEW]: ratings,
  };

  const tabKeyList = Object.entries(tabKeyContentMapping).reduce(
    (result, [key, value]) => (value?.length > 0 ? [...result, key] : result),
    [ABOUT, QUESTIONS, SUMMARY, KEY_POINT],
  );

  const transformFaq = ({ id, title, description }) => ({
    title,
    content: description,
    key: id,
  });

  const transformedTopicInfo = transformLiveTopicInfo(liveTopic);
  return {
    liveSubject,
    liveTopicInfo: transformedTopicInfo,
    scheduledLessons: transformLiveLessonByDate({
      lessonList: scheduledLessons,
      isTopicEnrolled: transformedTopicInfo.isEnrolled,
      isSemester: transformedTopicInfo.isSemester,
      semesterExpiredDate: transformedTopicInfo.semesterExpiredDate,
    }),
    freeLessonList: freeLessonList.map(lesson =>
      transformLesson({
        ...lesson,
        isTopicEnrolled: transformedTopicInfo.isEnrolled,
        isSemester: transformedTopicInfo.isSemester,
        semesterExpiredDate: transformedTopicInfo.semesterExpiredDate,
      }),
    ),
    finishedLessons: finishedLessons.map(lesson =>
      transformLesson({
        ...lesson,
        isTopicEnrolled: transformedTopicInfo.isEnrolled,
        isSemester: transformedTopicInfo.isSemester,
        semesterExpiredDate: transformedTopicInfo.semesterExpiredDate,
      }),
    ),
    lessonMaterials: lessonMaterialsWithLessonName,
    topicMaterials,
    tabKeyList,
    syllabuses,
    faqs: faqs.map(transformFaq),
  };
};

/**
  @typedef {Object} Instructor
  @property {string} name
  @property {string} avatarUrl 
  @property {string} description 
*/

/**
 * @typedef {Object} SubscribeLesson
 * @property {number} id
 * @property {string} name
 * @property {Instructor[]} instructors
 * @property {string} description
 */

/**
 *
 * @param {SubscribeLesson} topics
 */
export const transformSubscribedTopics = topics =>
  topics.map(transformLiveTopicInfo);
