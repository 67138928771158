import { normalize, schema } from 'normalizr';
import {
  secondsToTimeObject,
  secondsToTimeString,
  stringToDateObject,
} from 'utils/time';
import { transformPlan } from 'services/transform/plan';
import { transformCheckoutCollection } from 'reduxModules/checkoutCollection/transform';
import { getSubjectString } from 'reduxModules/instructorProfile/utils';
import { DIGITAL } from 'constants/materialTypes';
import { COMPLETED } from 'constants/lessonWatchStatus';
import { FREE, ENROLLED, ENROLL_PENDING } from 'constants/enrollStatus';

export const getIsLessonCompleted = ({ learningProgress, status }) =>
  learningProgress === 1 || status === COMPLETED;

function transformHighlight(highlight) {
  const { iconUrl, content: title, description } = highlight;

  return {
    iconUrl,
    title,
    description,
  };
}

export function transformLesson({
  lesson,
  lessonIndex,
  userPurchased,
  isLearningPage,
}) {
  if (!lesson) return null;

  const {
    comingSoon = false,
    duration: durationTime,
    estimatedUploadDate,
    isRated = false,
    isTrailer = false,
    learningProgress,
    materials = [],
    preview = false,
    progressDuration,
    status,
    title,
    videoTimestamps = [],
    content: highlightList,
    detailInfo,
  } = lesson;

  const lessonOrder = lessonIndex + 1;
  const remainingDuration = durationTime - progressDuration;
  const isCompleted = getIsLessonCompleted(lesson) && !comingSoon;
  const isFree = preview || isTrailer;

  return {
    ...lesson,
    chapters: videoTimestamps.map(({ seconds, description }) => ({
      formattedTimestamp: secondsToTimeString(seconds),
      description,
    })),
    comingSoon,
    duration: durationTime ? secondsToTimeString(durationTime) : '',
    durationTime,
    estimatedUploadDate,
    isCompleted,
    isFree,
    isLocked: !isFree && !userPurchased,
    isPreview: preview,
    isRated,
    timestamps: videoTimestamps.map(({ seconds }) => seconds),
    isTrailer,
    learningProgress,
    lessonOrder,
    name: isTrailer ? title : `${lessonOrder}. ${title}`,
    materials:
      materials?.filter(({ materialTypes }) =>
        materialTypes.includes(DIGITAL),
      ) || [],
    progressDuration,
    remainingDurationMinute: Math.ceil(remainingDuration / 60),
    status,
    userPurchased,
    playInLearningPage: !isLearningPage && userPurchased && !isTrailer,
    highlightList: highlightList?.map(transformHighlight),
    detailInfo,
  };
}

function transformNotebook(notebook) {
  const {
    id,
    title,
    description: features,
    pictureUrl: coverUrl,
    connectionUrl: contactUrl,
  } = notebook;

  // if there's no title or any features, treat as null data
  if (!title || features.length === 0) return null;

  return {
    id,
    title,
    features,
    coverUrl,
    contactUrl,
  };
}

function transformTestimonial(testimonial) {
  const { username: name, star: rating, comment: content } = testimonial;

  return {
    name,
    rating,
    content,
  };
}

export function transformContentHashtags(hashtags) {
  const displayedContentHashtags = hashtags.slice(0, 3);
  return {
    hashtags: displayedContentHashtags.map((tag, index) => ({
      key: index,
      tag,
    })),
    tag: displayedContentHashtags.join(' ,'),
  };
}

export function transformCourseListItem(course) {
  const {
    id,
    title,
    lessonsAmount,
    picture,
    checkoutCollection,
    contentHashtags,
    content: highlightList,
    totalLessonsDuration: totalDurationInSeconds,
    enrollStatus,
    averageRate,
  } = course;

  const { price, displayPrice, displayCurrency } = transformCheckoutCollection(
    checkoutCollection,
  );

  const { largeUrl: pictureUrl } = picture;

  return {
    id,
    title,
    pictureUrl,
    picture,
    price,
    displayPrice,
    displayCurrency,
    lessonsAmount,
    highlightList: highlightList.map(transformHighlight),
    durationTotal: secondsToTimeObject(totalDurationInSeconds),
    totalDurationInSeconds,
    isFree: enrollStatus === FREE,
    isPurchased: enrollStatus === ENROLLED,
    ...transformContentHashtags(contentHashtags),
    averageRate,
  };
}

function transformFAQ(faq, faqIndex) {
  return {
    ...faq,
    key: faqIndex,
  };
}

const transformedInstructors = instructors =>
  instructors.map(instructor => {
    const { subjects, instructorHashtags } = instructor;
    const hashtagString = instructorHashtags.join('•');

    return {
      subjectString: getSubjectString(subjects),
      hashtagString,
      ...instructor,
    };
  });

const INITIAL_ANNOUNCEMENT_LIST = {
  announcements: [],
  currentPage: 0,
  totalPages: 0,
};

function getLessonsByPage({
  lessons,
  trailerLessonInfo,
  userPurchased,
  isLearningPage,
}) {
  const transformedLessons = lessons.map((lesson, lessonIndex) =>
    transformLesson({
      lesson,
      lessonIndex,
      userPurchased,
      isLearningPage,
    }),
  );

  const transformedTrailerLesson = transformLesson({
    lesson: trailerLessonInfo,
    lessonIndex: 0,
    userPurchased,
    isLearningPage,
  });

  const previewLessonList = transformedLessons.filter(
    lesson => lesson.isPreview,
  );

  return {
    previewLessonList,
    trailerLesson: transformedTrailerLesson,
    lessonList: transformedLessons,
    freeLessonList: transformedLessons.filter(lesson => lesson.isFree),
    preparedLessonList: transformedLessons.filter(lesson => !lesson.comingSoon),
    purchaseLessonList: transformedLessons.filter(lesson => !lesson.isFree),
  };
}

export function transformCourse(course) {
  if (!course) return undefined;

  const {
    checkoutCollection,
    content: highlightList,
    contentHashtags,
    description,
    detailInfo,
    enrollStatus,
    enrollStartAt,
    faq: faqs,
    feedbacks: testimonials,
    hasMorePlans: hasAddOn,
    id,
    instructors,
    leadGroup,
    learningProgress = 0,
    lessons,
    lessonsAmount,
    materials,
    metaDescription,
    metaTitle,
    notebook,
    picture,
    relativeCourses: relatedCourses = [],
    title,
    videoDuration: trailerDuration,
    videoId: trailerId,
    videoSource: trailerSource,
    ratings,
    averageRate,
    ratingCount,
    // not from API
    announcementList = INITIAL_ANNOUNCEMENT_LIST,
    isLearningPage,
    trailerTitle,
  } = course;

  const { largeUrl: pictureUrl } = picture;

  const getLessonTotalDurationInSeconds = lessonList =>
    lessonList.reduce((acc, cur) => {
      return acc + cur.duration;
    }, 0);

  const getEnrollStartAtString = string => {
    const { date } = stringToDateObject(string);
    return date;
  };

  const lessonTotalDurationInSeconds = getLessonTotalDurationInSeconds(lessons);

  const trailerLessonInfo = trailerId && {
    isTrailer: true,
    videoId: trailerId,
    videoSource: trailerSource,
    title: trailerTitle,
    duration: trailerDuration,
  };

  const userPurchased = enrollStatus === ENROLLED;

  const {
    lessonList,
    previewLessonList,
    freeLessonList,
    preparedLessonList,
    trailerLesson,
    purchaseLessonList,
  } = getLessonsByPage({
    lessons,
    trailerLessonInfo,
    userPurchased,
    isLearningPage,
  });

  const lessonWithMaterials = lessonList.filter(
    ({ materials: lessonMaterials }) => lessonMaterials.length > 0,
  );

  const nextLessonForPurchasedUser =
    lessonList.find(
      ({ isCompleted, isTrailer }) => !isCompleted && !isTrailer,
    ) || lessonList[lessonList.length - 1];

  const completedLessons = lessonList.filter(lesson => lesson.isCompleted);

  return {
    announcementList,
    checkoutCollection: {
      ...transformCheckoutCollection(checkoutCollection),
      hasAddOn,
    },
    completedLessonsLength: completedLessons.length,
    description,
    detailInfo,
    enrollStartAt: getEnrollStartAtString(enrollStartAt) || '',
    faqs: faqs.map(transformFAQ),
    freeLessonList,
    trailerLesson,
    purchaseLessonList,
    preparedLessonList,
    hasPreviewLesson: previewLessonList.length !== 0,
    highlightList: highlightList.map(transformHighlight),
    id,
    instructors: transformedInstructors(instructors),
    isPreOrdered: enrollStatus === ENROLL_PENDING,
    leadGroup,
    learningProgress,
    lessonMeta: {
      durationTotal: secondsToTimeObject(lessonTotalDurationInSeconds),
      totalDurationInSeconds: lessonTotalDurationInSeconds,
    },
    lessonList,
    lessonsAmount,
    lessonWithMaterials,
    materials,
    metaDescription,
    metaTitle,
    nextLessonForPurchasedUser: !isLearningPage
      ? nextLessonForPurchasedUser
      : {},
    notebook: notebook && transformNotebook(notebook),
    pictureUrl,
    picture,
    relatedCourses: relatedCourses.map(transformCourseListItem),
    testimonials: testimonials.map(transformTestimonial),
    title,
    userPurchased,
    ...transformContentHashtags(contentHashtags),
    ratings,
    averageRate,
    ratingCount,
  };
}

function transformCourseGroup(courseGroup) {
  const { id: key, name: title } = courseGroup;

  return {
    key,
    title,
  };
}

const courseSchema = new schema.Entity(
  'course',
  {},
  {
    processStrategy: transformCourse,
  },
);

const courseGroupSchema = new schema.Entity(
  'groups',
  {},
  { processStrategy: transformCourseGroup },
);

const courseListSchema = new schema.Entity(
  'courses',
  {},
  {
    processStrategy: transformCourseListItem,
  },
);

export function normalizeCourseGroupList(courseGroups) {
  const normalized = normalize(courseGroups, [courseGroupSchema]);

  return {
    byGroupId: normalized.entities.groups,
    allGroupIds: normalized.result,
  };
}

export function normalizeCourseMenuList(courses) {
  const normalized = normalize(courses, [courseListSchema]);

  return {
    byId: normalized.entities.courses,
    allIds: normalized.result,
  };
}

export function normalizeCourse(course) {
  const normalized = normalize(course, courseSchema);

  return {
    byId: normalized.entities.course,
    allIds: [normalized.result],
  };
}

export function transformCoursePromotion(rawData) {
  const {
    isCoursePurchased = false,
    isSpecialPassPurchased = false,
    course,
    specialPassPlan: unlimitedPlan,
    media: [firstMedia],
  } = rawData;

  const userPurchased = isCoursePurchased || isSpecialPassPurchased;
  const { type, url, mobileUrl } = firstMedia || {};

  return {
    course: transformCourse(course),
    unlimitedPlan: transformPlan(unlimitedPlan),
    mediaIsVideo: type === 'video',
    mediaURL: url,
    mediaMobileURL: mobileUrl,
    userPurchased,
    hasUnlimitedPlan: !!unlimitedPlan,
    hasCourse: !!course,
    showUnlimitedPlanPromotion: !!unlimitedPlan && !isSpecialPassPurchased,
  };
}

export function transformLearningCourse({
  id,
  title,
  learningProgress,
  picture,
  lastReadLesson,
  lessonsAmount,
  instructors,
}) {
  const { title: lastReadLessonTitle = '', id: lastReadLessonId } =
    lastReadLesson || {};

  const { largeUrl: pictureUrl } = picture;

  return {
    title,
    id, // for BaseSwiper
    courseId: id,
    pictureUrl,
    picture,
    hasLastReadLesson: !!lastReadLesson,
    lastReadLessonTitle,
    lastReadLessonId,
    learningProgress,
    lessonsAmount,
    instructors,
  };
}

export function transformCourseForGroup(groupId, courses, hasMore) {
  const normalizeCourses = normalizeCourseMenuList(courses);

  const { byId, allIds } = normalizeCourses;

  return {
    [groupId]: {
      list: allIds.map(courseId => {
        return byId[courseId];
      }),
      hasMore,
    },
  };
}
