export const getCourseById = id => state => state.course.byId[id];
export const getCourseMenuList = state =>
  state.course.allIds.map(id => getCourseById(id)(state));

export const getCourseGroupById = id => state => state.course.byGroupId[id];
export const getCourseGroupList = state =>
  state.course.allGroupIds.map(id => getCourseGroupById(id)(state));

export const getCoursePromotion = state => state.course.promotion;

export const getCourseGroupInfo = state => state.course.courseGroupInfo;
export const getTabCourseList = state => state.course.tabList;

export const getLearningCourse = state => state.course.learningCourse.courses;
export const getLearningCoursePageSetting = state => {
  const {
    page,
    sizePerPage,
    totalPages,
  } = state.course.learningCourse.pageSetting;
  return { page, sizePerPage, totalPages, hasMorePage: page < totalPages };
};

export const getUserPurchasedCourse = state =>
  state.course.userPurchasedCourse.courses;
export const getUserPurchasedCoursePageSetting = state => {
  const {
    page,
    sizePerPage,
    totalPages,
  } = state.course.userPurchasedCourse.pageSetting;
  return {
    page,
    sizePerPage,
    totalPages,
    hasMorePage: page < totalPages,
  };
};

export const getCourseBannerList = state => state.course.bannerList;

export const getCourseHomeFilterInfo = state => state.course.homeFilterInfo;
