import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import toObject from 'dayjs/plugin/toObject';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import isToday from 'dayjs/plugin/isToday';

dayjs.extend(toObject);
dayjs.extend(isTomorrow);
dayjs.extend(isToday);
dayjs.extend(customParseFormat);

function formatTimeDigit(number) {
  return String.prototype.padStart.call(number, 2, '0');
}

/**
 * Convert seconds to time object contains hours, minutes, and seconds property.
 * @param {number} seconds
 */
export function secondsToTimeObject(seconds) {
  const time = { hours: 0, minutes: 0, seconds: 0 };
  time.hours = Math.floor(seconds / 3600);
  time.minutes = Math.floor((seconds % 3600) / 60);
  time.seconds = Math.floor((seconds % 3600) % 60);
  return time;
}

/**
 * Format seconds to time string based on time object
 * @param {number} seconds
 * @example
 * secondsToTimeString(2) // 00:02
 * secondsToTimeString(70) // 01:10
 * secondsToTimeString(3674) // 01:01:14
 */
export function secondsToTimeString(seconds) {
  const timeObject = secondsToTimeObject(seconds);

  if (timeObject.hours === 0) delete timeObject.hours;

  return Object.values(timeObject)
    .map(number => formatTimeDigit(number))
    .join(':');
}

export function stringToDayJSObject(string) {
  // use custom format extension to solve the date format problem in Safari.
  return dayjs(string, 'YYYY-MM-DD HH:mm:ss ZZ');
}
/**
 * Transform string to object with various properties
 * @param {string} string
 * @returns {Object}
 * @example
 * ```js
 * stringToDateObject('2021-03-10 23:15:00 +0800');
 * // => date: '10 Mar'
 * // => time: '3:15'
 * // => timePeriod: 'PM'
 * // => timeWithPeriod: '3:15 PM'
 * // => dateTime: '10 Mar 3:15 PM'
 * // => isTomorrow: false
 * // => isToday: false
 * ```
 */
export function stringToDateObject(string) {
  if (!string) return {};

  const day = stringToDayJSObject(string);

  const { minutes } = day.toObject();

  const date = day.format('D MMM');
  const time = minutes === 0 ? day.format('h') : day.format('h:mm');
  const timePeriod = day.format('A'); // AM/PM

  const timeWithPeriod = `${time} ${timePeriod}`;

  return {
    date,
    time,
    timePeriod,
    timeWithPeriod,
    dateTime: `${date} ${timeWithPeriod}`,
    isTomorrow: day.isTomorrow(),
    isToday: day.isToday(),
  };
}

/**
 *
 * @param {string} start
 * @param {string} end
 * @returns {string}
 * @example
 * ```js
 * formatTimeRange('2021-03-10 23:15:00 +0800','2021-03-10 23:15:00 +0800');
 * // => 7:15 AM - 3:15 PM
 * formatTimeRange('2021-03-10 23:15:00 +0800','2021-03-11 1:15:00 +0800');
 * // => 3:15 - 5:15 PM
 * ```
 */
export function formatTimeRange(start, end) {
  if (!start || !end) return undefined;

  const {
    time: startTime,
    timePeriod: startTimePeriod,
    timeWithPeriod: startTimeWithPeriod,
  } = stringToDateObject(start);

  const {
    timePeriod: endTimePeriod,
    timeWithPeriod: endTimeWithPeriod,
  } = stringToDateObject(end);

  const isSamePeriod = startTimePeriod === endTimePeriod;

  if (isSamePeriod) return `${startTime} - ${endTimeWithPeriod}`;

  return `${startTimeWithPeriod} - ${endTimeWithPeriod}`;
}

/**
 *
 * @returns {string}
 * @example Asia/Taipei or Asia/Karachi
 */
export function getTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}
