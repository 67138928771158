import { combineReducers } from 'redux';

import {
  fetchBannerListSuccess,
  fetchRegularClassLessonListSuccess,
  fetchGradeLevelFilterListSuccess,
  fetchGroupedCourseListSuccess,
  fetchCourseListSuccess,
  fetchAcademyListSuccess,
  clearRegularClassLessonList,
  setFilterInfo,
  clearFilterInfo,
} from './action';

const bannerList = (state = [], { type, payload }) => {
  switch (type) {
    case fetchBannerListSuccess.type: {
      return payload;
    }

    default:
      return state;
  }
};

const INITIAL_FILTER_INFO = {
  gradeLevels: [],
  courseGroups: [],
  selectedGradeLevel: { id: 0, name: '' },
  selectedSubject: { id: 0, name: '' },
  selectedCourseGroup: { id: 0, name: '' },
};

const filterInfo = (state = INITIAL_FILTER_INFO, { type, payload }) => {
  switch (type) {
    case fetchGradeLevelFilterListSuccess.type: {
      return { ...state, ...payload };
    }
    case setFilterInfo.type: {
      return { ...state, ...payload };
    }
    case clearFilterInfo.type: {
      return { ...INITIAL_FILTER_INFO };
    }
    default:
      return state;
  }
};

const INITIAL_REGULAR_CLASS = {
  lessonList: [],
  totalPages: 0,
};

const regularClass = (state = INITIAL_REGULAR_CLASS, { type, payload }) => {
  switch (type) {
    case fetchRegularClassLessonListSuccess.type: {
      return { ...payload };
    }
    case clearRegularClassLessonList.type: {
      return { ...INITIAL_REGULAR_CLASS };
    }
    default:
      return state;
  }
};

const INITIAL_COURSE = {
  courseList: [],
  totalPages: 0,
};

const course = (state = INITIAL_COURSE, { type, payload }) => {
  switch (type) {
    case fetchCourseListSuccess.type: {
      return { ...payload };
    }
    case fetchGroupedCourseListSuccess.type: {
      return { ...payload };
    }
    default:
      return state;
  }
};

const INITIAL_ACADEMY = {
  postList: [],
};

const academy = (state = INITIAL_ACADEMY, { type, payload }) => {
  switch (type) {
    case fetchAcademyListSuccess.type: {
      return { postList: payload };
    }
    default:
      return state;
  }
};

export default combineReducers({
  bannerList,
  filterInfo,
  regularClass,
  course,
  academy,
});
