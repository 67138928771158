import {
  fetchRcBannersSuccess,
  fetchLiveTopics,
  fetchLiveTopicsSuccess,
  fetchLiveTopicsClear,
} from '../action';

const INITIAL_LIVE_TOPICS = {
  bannerList: [],
  liveTopics: [],
  pageSetting: {
    page: 1,
    sizePerPage: 6,
    totalPages: 0,
  },
};

export default (state = INITIAL_LIVE_TOPICS, { type, payload }) => {
  switch (type) {
    case fetchLiveTopics.type: {
      return {
        ...state,
        pageSetting: {
          ...state.pageSetting,
          ...(payload?.page && { page: payload.page }),
          ...(payload?.sizePerPage && { sizePerPage: payload.sizePerPage }),
        },
      };
    }

    case fetchLiveTopicsSuccess.type: {
      return {
        ...state,
        liveTopics: [...state.liveTopics, ...payload.liveTopics],
        pageSetting: {
          ...state.pageSetting,
          page: payload.page,
          totalPages: payload.totalPages,
        },
      };
    }

    case fetchLiveTopicsClear.type: {
      return { ...INITIAL_LIVE_TOPICS, bannerList: [...state.bannerList] };
    }

    case fetchRcBannersSuccess.type: {
      return {
        ...state,
        bannerList: payload,
      };
    }

    default:
      return state;
  }
};
