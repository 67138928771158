import { createCommonActions } from 'utils/redux';

export const [
  fetchInstructorProfile,
  fetchInstructorProfileSuccess,
  fetchInstructorProfileFailure,
] = createCommonActions('FETCH_INSTRUCTOR_PROFILE');

export const [
  fetchInstructorList,
  fetchInstructorListSuccess,
  fetchInstructorListFailure,
  fetchInstructorListClear,
] = createCommonActions('FETCH_INSTRUCTOR_LIST');

export const [
  fetchContentListByInstructor,
  fetchContentListByInstructorSuccess,
  fetchContentListByInstructorFailure,
] = createCommonActions('FETCH_CONTENT_LIST_BY_INSTRUCTOR');
