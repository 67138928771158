import { combineReducers } from 'redux';

import {
  fetchCourseSuccess,
  fetchCourseAnnouncementsSuccess,
  fetchLearningCourse,
  fetchLearningCourseSuccess,
  fetchCourseListWithGroupSuccess,
  fetchCoursePromotionSuccess,
  fetchGroupCourseListSuccess,
  postCourseRatingSuccess,
  updateCourseProgressSuccess,
  fetchUserPurchasedCourse,
  fetchUserPurchasedCourseSuccess,
  fetchCourseBanners,
  fetchCourseBannersSuccess,
  fetchCourseBannersFailure,
  fetchCourseGradeLevelsSuccess,
  fetchCourseGradeLevels,
  fetchCourseGradeLevelsFailure,
  updateCourseSelectedGradeLevel,
  fetchGroupCourseListForHomeSuccess,
  fetchGroupCourseListForHomeFailure,
  updateCourseHomeCurrentPage,
  clearGroupCourseListForHome,
  fetchCourseListWithGroup,
} from './action';

import { getIsLessonCompleted } from './transform';

/**
 * @typedef {Object} Plan
 * @property {number} id first payment method id
 * @property {string} currency
 * @property {number} price
 * @property {boolean} hasAddOn
 */

/**
 * @typedef {number} Id
 */

/**
 * @typedef {Object} Course
 * @property {number} id
 * @property {string} title
 * @property {string} description
 * @property {Array} instructors
 * @property {string} trailerUrl
 * @property {Array} lessons
 * @property {Array} freeLessonList
 * @property {Object} lessonMeta
 * @property {Array} features
 * @property {Array} faqs
 * @property {Plan} plan
 */

/** @type {Object.<Id, Course>} */
const INITIAL_STATE_BY_ID = {};

export const byId = (state = INITIAL_STATE_BY_ID, { type, payload = {} }) => {
  switch (type) {
    case fetchCourseSuccess.type: {
      const courses = payload.byId || {};
      return Object.values(courses).reduce(
        (prevState, course) => ({
          ...prevState,
          [course.id]: {
            ...prevState[course.id],
            ...course,
          },
        }),
        state,
      );
    }

    case updateCourseProgressSuccess.type: {
      const { courseId, lessonId, lesson: updateLessonInfo } = payload;
      const { learningProgress } = updateLessonInfo.userCourse;
      const isCompleted = getIsLessonCompleted(updateLessonInfo);
      const updatedLessons = state[courseId].lessons.map(lesson => {
        if (lesson.id === lessonId) {
          return {
            ...lesson,
            learningProgress: 0,
            isCompleted,
          };
        }
        return lesson;
      });

      return {
        ...state,
        [courseId]: {
          ...state[courseId],
          learningProgress,
          lessons: updatedLessons,
        },
      };
    }

    case fetchCourseAnnouncementsSuccess.type: {
      const { courseId, data } = payload;
      const updatedAnnouncements = [
        ...state[courseId].announcementList.announcements,
        ...data.announcements,
      ];
      return {
        ...state,
        [courseId]: {
          ...state[courseId],
          announcementList: {
            ...data,
            announcements: updatedAnnouncements,
          },
        },
      };
    }

    case postCourseRatingSuccess.type: {
      const { courseId, lessonId } = payload;
      const { lessons } = state[courseId];
      const updateLessons = [...lessons];
      const currentLessonIndex = updateLessons.findIndex(
        ({ id }) => id === lessonId,
      );
      const currentLesson = {
        ...updateLessons[currentLessonIndex],
        isRated: true,
      };
      updateLessons.splice(currentLessonIndex, 1, currentLesson);

      return {
        ...state,
        [courseId]: {
          ...state[courseId],
          lessons: updateLessons,
        },
      };
    }

    default:
      return state;
  }
};

export const allIds = (state = [], { type, payload }) => {
  switch (type) {
    case fetchCourseSuccess.type:
      return Array.from(new Set([...state, ...payload.allIds]));
    default:
      return state;
  }
};

/**
 * @typedef {Object} CourseGroup
 * @property {number} key
 * @property {string} title
 */

/** @type {Object.<Id, CourseGroup>} */
const INITIAL_STATE_BY_GROUP_ID = {};

export const byGroupId = (
  state = INITIAL_STATE_BY_GROUP_ID,
  { type, payload },
) => {
  switch (type) {
    case fetchCourseListWithGroupSuccess.type: {
      const courseGroups = payload.byGroupId || {};
      return Object.values(courseGroups).reduce(
        (prevState, courseGroup) => ({
          ...prevState,
          [courseGroup.key]: {
            ...prevState[courseGroup.key],
            ...courseGroup,
          },
        }),
        state,
      );
    }
    default:
      return state;
  }
};

export const allGroupIds = (state = [], { type, payload }) => {
  switch (type) {
    case fetchCourseListWithGroupSuccess.type:
      return payload.allGroupIds;
    case fetchCourseListWithGroup.type:
      return [];
    default:
      return state;
  }
};

const INITIAL_COURSE_GROUP_INFO = {
  id: null,
  title: '',
  description: '',
  currentPage: 0,
  totalPages: 0,
  list: [],
  hasMore: false,
};

export const courseGroupInfo = (
  state = INITIAL_COURSE_GROUP_INFO,
  { type, payload },
) => {
  switch (type) {
    case fetchGroupCourseListSuccess.type: {
      const { courses, id, currentPage, totalPages, ...etc } = payload;

      const nextPage = courses[id].list;

      const target = state.list;

      target[currentPage - 1] = [...nextPage];

      return {
        ...state,
        ...etc,
        id,
        currentPage,
        totalPages,
        list: [...target],
        hasMore: currentPage < totalPages,
      };
    }
    default:
      return state;
  }
};

const INITIAL_TAB_LIST_PAYLOAD = {
  id: 0,
  courses: {},
};
export const tabList = (
  state = {},
  { type, payload = INITIAL_TAB_LIST_PAYLOAD },
) => {
  switch (type) {
    case fetchGroupCourseListForHomeSuccess.type:
      return {
        ...state,
        ...payload.courses,
      };
    case clearGroupCourseListForHome.type:
      return {};
    // eslint-disable-next-line no-fallthrough
    case fetchGroupCourseListForHomeFailure.type:
    default:
      return state;
  }
};

/**
 * @typedef {Object} Video
 * @property {string} videoId
 * @property {string} otp
 * @property {string} playbackInfo
 */

/** @type {{video: Video, course: Course, unlimitedPlan: Plan, userPurchased: boolean }} */
const INITIAL_STATE_PROMOTION = {};
export const promotion = (
  state = INITIAL_STATE_PROMOTION,
  { type, payload },
) => {
  switch (type) {
    case fetchCoursePromotionSuccess.type:
      return payload;

    default:
      return state;
  }
};

const INITIAL_STATE_LEARNING_COURSE = {
  courses: [],
  pageSetting: {
    page: 1,
    sizePerPage: 8,
    totalPages: 0,
  },
};

const learningCourse = (
  state = INITIAL_STATE_LEARNING_COURSE,
  { type, payload },
) => {
  switch (type) {
    case fetchLearningCourse.type:
      return {
        ...state,
        pageSetting: {
          ...state.pageSetting,
          ...(!!payload?.page && { page: payload.page }),
        },
      };

    case fetchLearningCourseSuccess.type:
      return {
        ...state,
        courses: payload.courses,
        pageSetting: {
          ...state.pageSetting,
          ...(!!payload?.totalPages && { totalPages: payload.totalPages }),
          ...(!!payload?.page && { page: payload.page }),
        },
      };
    default:
      return state;
  }
};

const INITIAL_STATE_USER_PURCHASED_COURSE = {
  courses: [],
  pageSetting: {
    page: 1,
    sizePerPage: 8,
    totalPages: 0,
  },
};

const userPurchasedCourse = (
  state = INITIAL_STATE_USER_PURCHASED_COURSE,
  { type, payload },
) => {
  switch (type) {
    case fetchUserPurchasedCourse.type:
      return {
        ...state,
        pageSetting: {
          ...state.pageSetting,
          ...(!!payload?.page && { page: payload.page }),
        },
      };

    case fetchUserPurchasedCourseSuccess.type:
      return {
        ...state,
        courses: [...state.courses, ...payload.courses],
        pageSetting: {
          ...state.pageSetting,
          ...(!!payload?.totalPages && { totalPages: payload.totalPages }),
          ...(!!payload?.page && { page: payload.page }),
        },
      };
    default:
      return state;
  }
};

const INITIAL_STATE_BANNER_LIST = [];

const bannerList = (state = INITIAL_STATE_BANNER_LIST, { type, payload }) => {
  switch (type) {
    case fetchCourseBannersSuccess.type:
      return [...payload];
    case fetchCourseBanners.type:
    case fetchCourseBannersFailure.type:
    default:
      return state;
  }
};

const INITIAL_STATE_HOME_FILTER_INFO = {
  gradeLevels: [],
  selectedGradeLevel: 0,
  selectedSubject: 0,
  currentPage: 0,
};

const homeFilterInfo = (
  state = INITIAL_STATE_HOME_FILTER_INFO,
  { type, payload },
) => {
  switch (type) {
    case fetchCourseGradeLevelsSuccess.type:
      return {
        ...state,
        gradeLevels: [...payload],
      };
    case updateCourseSelectedGradeLevel.type:
      return {
        ...state,
        ...payload,
      };
    case updateCourseHomeCurrentPage.type:
      return {
        ...state,
        currentPage: payload.page,
      };
    case fetchCourseGradeLevels.type:
    case fetchCourseGradeLevelsFailure:
    default:
      return state;
  }
};

export default combineReducers({
  byId,
  allIds,
  byGroupId,
  allGroupIds,
  courseGroupInfo,
  tabList,
  promotion,
  learningCourse,
  userPurchasedCourse,
  bannerList,
  homeFilterInfo,
});
