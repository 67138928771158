import {
  fetchLiveTopicSuccess,
  postLiveLessonRatingSuccess,
  postLiveTopicRatingSuccess,
} from '../action';
/**
 *
 * @typedef {Object} LiveSubject
 * @property {string} name
 * @property {boolean} isSubscribed
 *
 * @typedef {Object[]} Instructor
 * @property {string} name
 * @property {number} id
 * @property {string} description
 * @property {string} avatarUrl
 *
 * @typedef {Object} LiveTopic
 * @property {string} name
 * @property {string} description
 * @property {Instructor[]} instructors
 * @property {string} pictureUrl
 *
 * @typedef {Object[]} TopicLessons
 * @property {string} url the url to get into the live.
 * @property {string} name
 * @property {object} time
 * @property {string} time.timeRange
 * @property {string} time.startDate
 * @property {boolean} time.startTomorrow
 * @property {boolean} time.startToday
 * @property {boolean} time.isLive
 *
 * @typedef {Array} Materials
 * @property {string} title
 * @property {string} description
 * @property {string} pictureUrl
 *
 * @typedef {Array} Faqs
 * @property {number} id
 * @property {string} title
 * @property {string} description
 */

/**
 * @type{{
 *  liveSubject: LiveSubject,
 *  liveTopicInfo: LiveTopic,
 *  scheduledLessons: TopicLessons,
 *  freeLessonList: TopicLessons,
 *  lessonMaterials: Materials,
 *  topicMaterials: Materials,
 *  faqs:Faqs
 * }}
 */

export const INITIAL_LIVE_TOPIC = {
  liveSubject: {
    name: '',
  },
  liveTopicInfo: {
    name: '',
    description: '',
    instructors: [],
    pictureUrl: '',
    isEnrolled: false,
    isRated: false,
  },
  scheduledLessons: [],
  freeLessonList: [],
  finishedLessons: [],
  faqs: [],
};

export default (state = INITIAL_LIVE_TOPIC, { type, payload }) => {
  switch (type) {
    case fetchLiveTopicSuccess.type: {
      return { ...state, ...payload };
    }

    case postLiveLessonRatingSuccess.type: {
      const { lessonId } = payload;
      return {
        ...state,
        finishedLessons: state.finishedLessons.reduce((acc, lesson) => {
          const updatedLesson =
            lessonId === lesson.id ? { ...lesson, isRated: true } : lesson;

          acc.push(updatedLesson);
          return acc;
        }, []),
      };
    }

    case postLiveTopicRatingSuccess.type: {
      return {
        ...state,
        liveTopic: {
          ...state.liveTopic,
          isRated: true,
        },
      };
    }

    default:
      return state;
  }
};
