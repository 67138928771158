import { AU, HK, ID, JP, KR, MY, NZ, SG, TH, TW, VN } from 'constants/locales';

export const REGION_CURRENCY_LIST = {
  [AU]: 'AUD',
  [HK]: 'HKD',
  [TW]: 'TWD',
  [SG]: 'SGD',
  [MY]: 'MYR',
  [ID]: 'IDR',
  [KR]: 'KRW',
  [JP]: 'JPY',
  [TH]: 'THB',
  [NZ]: 'NZD',
  [VN]: 'VND',
};

export const DEFAULT_THOUSANDS_SEPARATOR = ',';

export const CURRENCY_WITH_EXCLUSIVE_THOUSANDS_SEPARATOR = {
  [REGION_CURRENCY_LIST[VN]]: '.',
};

export const CURRENCY_WITH_DECIMALS = [
  REGION_CURRENCY_LIST[AU],
  REGION_CURRENCY_LIST[SG],
  REGION_CURRENCY_LIST[MY],
  REGION_CURRENCY_LIST[NZ],
];

export const CURRENCY_SYMBOL_AT_END = [REGION_CURRENCY_LIST[VN]];
