import UniversalRouter from 'universal-router';
import generateURL from 'urlcat';

import {
  getUserIsAuthenticated,
  getUserRegion,
} from 'reduxModules/user/selector';
import { appLaunch } from 'reduxModules/auth/action';
import { fetchSiteInfo } from 'reduxModules/siteInfo/action';
import { fetchSiteInfoFlow } from 'reduxModules/siteInfo/saga';
import { getAppRegion } from 'reduxModules/app/selector';

import { SUPPORTED_LOCALE_AND_REGION_LIST } from 'constants/locales';
import { LOGIN } from 'constants/routingPath';

import routes from './routes';

export default new UniversalRouter(routes, {
  async resolveRoute(context, params) {
    const { authRequired, needUserRegionLocale } = context.route;
    const state = context.store.getState();

    await context.store
      .runSagas(fetchSiteInfoFlow, fetchSiteInfo())
      .toPromise();

    if (authRequired && !getUserIsAuthenticated(state)) {
      return { redirect: LOGIN };
    }

    if (getUserIsAuthenticated(state)) {
      context.store.dispatch(appLaunch());
    }

    if (
      needUserRegionLocale &&
      getUserRegion(state) &&
      getUserRegion(state) !== getAppRegion(state)
    ) {
      const userRegionDefaultLocale =
        SUPPORTED_LOCALE_AND_REGION_LIST[getUserRegion(state)];
      const [, , ...pathWithoutLocale] = context.pathname.split('/');

      return {
        redirect: generateURL(`/:locale/${pathWithoutLocale.join('/')}`, {
          locale: userRegionDefaultLocale,
        }),
      };
    }

    if (typeof context.route.load === 'function') {
      return context.route
        .load()
        .then(action => action.default(context, params));
    }
    if (typeof context.route.action === 'function') {
      return context.route.action(context, params);
    }
    return undefined;
  },
});
