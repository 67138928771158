import { splitLocale } from 'utils/common';

// use common.json namespace file path to extract existing language folders, the file path is like ""./en-hk/common"
const languages = require
  .context('../../build/i18n', true, /common$/)
  .keys()
  .reduce((acc, filePath) => {
    const [, language] = filePath.split('/');
    return acc.add(language);
  }, new Set());

export default function getLanguageFolder(locale) {
  if (languages.has(locale)) {
    return locale;
  }

  const { language } = splitLocale(locale);
  return language;
}
