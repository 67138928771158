import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { SocialWidget } from '@snapask/common-components';

import { sendGTMEvent } from 'reduxModules/googleTagManager/action';
import { toggleSocialWidget } from 'reduxModules/socialWidget/action';
import { getSocialWidget } from 'reduxModules/socialWidget/selector';
import { getAppRegion } from 'reduxModules/app/selector';
import useStyles from 'hooks/useStyles';
import { WIDGET_CLICK, WIDGET_CTA_CLICK } from 'constants/gtmEvent';

import styles from './SocialWidgetHandler.scss';

const SocialWidgetHandler = () => {
  useStyles(styles);
  const { t } = useTranslation(['common']);
  const dispatch = useDispatch();
  const region = useSelector(getAppRegion);

  const { isSocialWidgetOpen, linkType, socialWidgetProps } = useSelector(
    getSocialWidget,
  );

  const handleChangeActive = () => {
    dispatch(toggleSocialWidget());

    if (!isSocialWidgetOpen)
      dispatch(
        sendGTMEvent({
          eventName: WIDGET_CLICK,
          data: {
            region,
          },
        }),
      );
  };

  const handleClickContactUsButton = () => {
    dispatch(
      sendGTMEvent({
        eventName: WIDGET_CTA_CLICK,
        data: {
          region,
        },
      }),
    );
  };

  return (
    <div className={cx(styles.root)}>
      <SocialWidget
        t={t}
        linkType={linkType}
        isActive={isSocialWidgetOpen}
        onChangeActive={handleChangeActive}
        onClickContactUsButton={handleClickContactUsButton}
        {...socialWidgetProps}
      />
    </div>
  );
};

export default SocialWidgetHandler;
