import {
  DEFAULT_THOUSANDS_SEPARATOR,
  CURRENCY_WITH_EXCLUSIVE_THOUSANDS_SEPARATOR,
  CURRENCY_WITH_DECIMALS,
  CURRENCY_SYMBOL_AT_END,
} from 'constants/price';

function formatThousands(currency, number) {
  const exclusiveSeparator =
    CURRENCY_WITH_EXCLUSIVE_THOUSANDS_SEPARATOR[currency];

  return number
    .toString()
    .replace(
      /(\d)(?=(\d{3})+(?!\d))/g,
      `$1${exclusiveSeparator || DEFAULT_THOUSANDS_SEPARATOR}`,
    );
}

function formatDecimals(currency, number) {
  if (!CURRENCY_WITH_DECIMALS.includes(currency)) return number;
  return Number.parseFloat(number).toFixed(2);
}

export function formatPriceNumber(currency, number) {
  if (!currency || Number.isNaN(+number)) return undefined;
  return formatThousands(currency, formatDecimals(currency, number));
}

export function formatPriceWithCurrency(currency, currencySymbol, number) {
  const formattedNumber = formatPriceNumber(currency, number);

  if (!formattedNumber) return undefined;

  const priceArray = [currencySymbol, formattedNumber];

  const isSymbolAtEnd = CURRENCY_SYMBOL_AT_END.includes(currency);

  if (isSymbolAtEnd) priceArray.reverse();

  return priceArray.join('');
}

export function getDiscount(originalPrice, discountPrice) {
  const discount = originalPrice - discountPrice;
  if (!discount)
    return {
      percentage: 0,
      rate: 0,
    };
  const percentageDiscount = Math.round((discount * 100) / originalPrice);

  // 中文的折扣習慣使用「折扣後價格為原價的10分之幾」來表達，如 7 折，意思則為原價的 7/10
  const rate = (discountPrice / originalPrice) * 10;

  return {
    percentage: percentageDiscount,
    rate: Number.isInteger(rate) || rate < 1 ? rate : Math.round(rate * 10),
  };
}

export function getPriceObject(priceList) {
  const filteredPriceList = priceList.filter(({ price }) => Boolean(price));
  if (filteredPriceList.length === 0) return {};

  const lowestPrice = filteredPriceList[0];
  const highestPrice = filteredPriceList[filteredPriceList.length - 1];

  const discount = {
    ...getDiscount(highestPrice.price, lowestPrice.price),
    type: lowestPrice.type,
  };

  return {
    sellingPrice: lowestPrice.price,
    originalPrice: highestPrice.price,
    discount,
  };
}

export function getDisplayPriceObject(
  priceList,
  currency,
  displayCurrency,
  lessonCount,
) {
  const { sellingPrice, originalPrice, discount } = getPriceObject(priceList);

  const hasDiscount = discount && discount.percentage !== 0;

  return {
    // TODO: with displayPriceUnit billing cycle
    sellingPrice: formatPriceWithCurrency(
      currency,
      displayCurrency,
      sellingPrice,
    ),
    originalPrice: formatPriceWithCurrency(
      currency,
      displayCurrency,
      originalPrice,
    ),
    ...(lessonCount && {
      singleLessonPrice: formatPriceWithCurrency(
        currency,
        displayCurrency,
        Math.round(sellingPrice / lessonCount),
      ),
    }),
    ...(hasDiscount && { discount }),
  };
}
