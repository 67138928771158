import {
  submitTackingVideoInfo,
  submitTackingVideoInfoSuccess,
  submitTackingVideoInfoFailure,
} from './action';

const INITIAL_STATE = {};

const reducer = (state = INITIAL_STATE, { type }) => {
  switch (type) {
    case submitTackingVideoInfo.type:
    case submitTackingVideoInfoSuccess.type:
    case submitTackingVideoInfoFailure.type:
    default:
      return state;
  }
};

export default reducer;
