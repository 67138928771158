// Regular Class Events
export const LIVE_MEETING_VIEW = 'LiveMeetingView';
export const REGULAR_CLASS_LIST_ENTER = 'REGULAR_CLASS_LIST_ENTER';
export const REGULAR_CLASS_GRADE_LEVEL_CLICK =
  'REGULAR_CLASS_GRADE_LEVEL_CLICK';
export const REGULAR_CLASS_BANNER_CLICK = 'REGULAR_CLASS_BANNER_CLICK';
export const REGULAR_CLASS_HOME_SCROLL_DEPTH =
  'REGULAR_CLASS_HOME_SCROLL_DEPTH';
export const REGULAR_CLASS_UPCOMING_EPISODE_ENTER =
  'REGULAR_CLASS_UPCOMING_EPISODE_ENTER';
export const REGULAR_CLASS_ALL_CLASS_ENTER = 'REGULAR_CLASS_ALL_CLASS_ENTER';
export const REGULAR_CLASS_SUBJECT_CLICK = 'REGULAR_CLASS_SUBJECT_CLICK';
export const REGULAR_CLASS_EPISODE_CLICK = 'REGULAR_CLASS_EPISODE_CLICK';
export const REGULAR_CLASS_EPISODE_PLAY = 'REGULAR_CLASS_EPISODE_PLAY';
export const REGULAR_CLASS_EPISODE_END = 'REGULAR_CLASS_EPISODE_END';
export const REGULAR_CLASS_TOPIC_CLICK = 'REGULAR_CLASS_TOPIC_CLICK';
export const REGULAR_CLASS_MY_SCHEDULE_ENTER =
  'REGULAR_CLASS_MY_SCHEDULE_ENTER';
export const REGULAR_CLASS_MY_CLASS_ENTER = 'REGULAR_CLASS_MY_CLASS_ENTER';
export const PURCHASE_AVAILABLE_LESSON_CLICK =
  'PURCHASE_AVAILABLE_LESSON_CLICK';
export const REGULAR_CLASS_FREE_TRIAL_CLICK = 'REGULAR_CLASS_FREE_TRIAL_CLICK';
// topic tab events
export const REGULAR_CLASS_ABOUT_TAB = 'REGULAR_CLASS_ABOUT_TAB';
export const REGULAR_CLASS_MATERIAL_TAB = 'REGULAR_CLASS_MATERIAL_TAB';
export const REGULAR_CLASS_INSTRUCTOR_TAB = 'REGULAR_CLASS_INSTRUCTOR_TAB';
export const REGULAR_CLASS_QUESTIONS_TAB = 'REGULAR_CLASS_QUESTIONS_TAB';

export const REGULAR_CLASS_SAVE_EPISODE = 'REGULAR_CLASS_SAVE_EPISODE';
export const REGULAR_CLASS_MATERIAL_CLICK = 'REGULAR_CLASS_MATERIAL_CLICK';
export const REGULAR_CLASS_PURCHASE_ENTER = 'REGULAR_CLASS_PURCHASE_ENTER';
export const REGULAR_CLASS_SYLLABUS_LESSON_CLICK =
  'REGULAR_CLASS_SYLLABUS_LESSON_CLICK';

export const REGULAR_CLASS_RATING_EPISODE_CLICK =
  'REGULAR_CLASS_RATING_EPISODE_CLICK';
export const REGULAR_CLASS_RATING_TOPIC_CLICK =
  'REGULAR_CLASS_RATING_TOPIC_CLICK';
export const REGULAR_CLASS_RATING_SUBMIT_CLICK =
  'REGULAR_CLASS_RATING_SUBMIT_CLICK';
export const REGULAR_CLASS_PURCHASE_CLICK = 'REGULAR_CLASS_PURCHASE_CLICK';
export const REGULAR_CLASS_REVIEW_SECTION_CLICK =
  'REGULAR_CLASS_REVIEW_SECTION_CLICK';
export const REGULAR_CLASS_ALL_RATING_CLICK = 'REGULAR_CLASS_ALL_RATING_CLICK';

// Course Events
export const COURSE_LIST_ENTER = 'COURSE_LIST_ENTER';
export const COURSE_LIST_CLICK = 'COURSE_LIST_CLICK';
export const COURSE_RECOMMENDATION_CLICK = 'COURSE_RECOMMENDATION_CLICK';
export const COURSE_DETAIL_ENTER = 'COURSE_DETAIL_ENTER';
export const COURSE_LEARNING_ENTER = 'COURSE_LEARNING_ENTER';
export const COURSE_DETAIL_OVERVIEW_VIEW = 'COURSE_DETAIL_OVERVIEW_VIEW';
export const COURSE_DETAIL_MATERIAL_VIEW = 'COURSE_DETAIL_MATERIAL_VIEW';
export const COURSE_DETAIL_DETAIL_VIEW = 'COURSE_DETAIL_DETAIL_VIEW';
export const COURSE_REVIEW_SECTION_CLICK = 'COURSE_REVIEW_SECTION_CLICK';
export const COURSE_DETAIL_INSTRUCTOR_VIEW = 'COURSE_DETAIL_INSTRUCTOR_VIEW';
export const COURSE_DETAIL_FAQ_VIEW = 'COURSE_DETAIL_FAQ_VIEW';
export const COURSE_LESSONPAGE_ENTER = 'COURSE_LESSONPAGE_ENTER';
export const COURSE_LESSONPAGE_KEYPOINTS_VIEW =
  'COURSE_LESSONPAGE_KEYPOINTS_VIEW';
export const COURSE_LESSONPAGE_MATERIAL_VIEW =
  'COURSE_LESSONPAGE_MATERIAL_VIEW';
export const COURSE_LESSONPAGE_SUMMARY_VIEW = 'COURSE_LESSONPAGE_SUMMARY_VIEW';
export const COURSE_LESSONPAGE_INSTRUCTOR_VIEW =
  'COURSE_LESSONPAGE_INSTRUCTOR_VIEW';
export const COURSE_LESSONPAGE_FAQ_VIEW = 'COURSE_LESSONPAGE_FAQ_VIEW';
export const COURSE_DETAIL_LESSON_FILTER_SELECT =
  'COURSE_DETAIL_LESSON_FILTER_SELECT';
export const COURSE_PURCHASE_CLICK = 'COURSE_PURCHASE_CLICK';
export const COURSE_PROMOTE_CLICK = 'COURSE_PROMOTE_CLICK';
export const COURSE_PROMOTE_PLAY = 'COURSE_PROMOTE_PLAY';
export const COURSE_PROMOTE_END = 'COURSE_PROMOTE_END';
export const COURSE_LESSON_CLICK = 'COURSE_LESSON_CLICK';
export const COURSE_LESSONPAGE_LESSON_CLICK = 'COURSE_LESSONPAGE_LESSON_CLICK';
export const COURSE_LESSON_PLAY = 'COURSE_LESSON_PLAY';
export const COURSE_LESSON_END = 'COURSE_LESSON_END';
export const COURSE_TIMESTAMP_CHAPTER_CLICK = 'COURSE_TIMESTAMP_CHAPTER_CLICK';
export const COURSE_TIMESTAMP_CLICK = 'COURSE_TIMESTAMP_CLICK';
export const COURSE_MATERIAL_CLICK = 'COURSE_MATERIAL_CLICK';
export const COURSE_LESSONPAGE_MATERIAL_CLICK =
  'COURSE_LESSONPAGE_MATERIAL_CLICK';
export const COURSE_BANNER_CLICK = 'COURSE_BANNER_CLICK';
export const COURSE_TAB_CLICK = 'COURSE_TAB_CLICK';
export const COURSE_DETAIL_ASK = 'COURSE_DETAIL_ASK';
export const COURSE_PLAYER_ANNOUNCEMENT_VIEW =
  'COURSE_PLAYER_ANNOUNCEMENT_VIEW';
export const COURSE_PLAYER_RATING_ENTER = 'COURSE_PLAYER_RATING_ENTER';
export const COURSE_PLAYER_RATING_SEND = 'COURSE_PLAYER_RATING_SEND';
export const COURSE_PAYWALL_CLICK = 'COURSE_PAYWALL_CLICK';
export const COURSE_PAYWALL_CONTACT = 'COURSE_PAYWALL_CONTACT';
export const COURSE_FREE_TRIAL_CLICK = 'COURSE_FREE_TRIAL_CLICK';
export const COURSE_ALL_RATING_CLICK = 'COURSE_ALL_RATING_CLICK';
export const COURSE_RATING_LESSON_CLICK = 'COURSE_RATING_LESSON_CLICK';

// Instructor Profile Events
export const INSTRUCTOR_COURSE_CLICK = 'INSTRUCTOR_COURSE_CLICK';
export const INSTRUCTOR_ALL_RC_VIEW = 'INSTRUCTOR_ALL_RC_VIEW';
export const INSTRUCTOR_ALL_COURSE_VIEW = 'INSTRUCTOR_ALL_COURSE_VIEW';
export const COURSE_INSTRUCTOR_CLICK = 'COURSE_INSTRUCTOR_CLICK';
export const REGULAR_CLASS_INSTRUCTOR_CLICK = 'REGULAR_CLASS_INSTRUCTOR_CLICK';
export const NAV_COURSE_ALL_INSTRUCTOR_CLICK =
  'NAV_COURSE_ALL_INSTRUCTOR_CLICK';
export const NAV_RC_ALL_INSTRUCTOR_CLICK = 'NAV_RC_ALL_INSTRUCTOR_CLICK';

// My Learning Page Event
export const MY_LEARNING_JUMP_BACK_CLICK = 'MY_LEARNING_JUMP_BACK_CLICK';
export const MY_LEARNING_CONTINUE_WATCHING_CLICK =
  'MY_LEARNING_CONTINUE_WATCHING_CLICK';
export const MY_LEARNING_RECOMMENDED_RC_LESSON_CLICK =
  'MY_LEARNING_RECOMMENDED_RC_LESSON_CLICK';
export const MY_LEARNING_RECOMMENDED_COURSE_LESSON_CLICK =
  'MY_LEARNING_RECOMMENDED_COURSE_LESSON_CLICK';
export const MY_LEARNING_FREE_TRIAL_CLICK = 'MY_LEARNING_FREE_TRIAL_CLICK';
// Landing Page Events
export const HOME_BANNER_VIEW = 'HOME_BANNER_VIEW';
export const HOME_RC_VIEW = 'HOME_RC_VIEW';
export const HOME_COURSE_VIEW = 'HOME_COURSE_VIEW';
export const HOME_QA_VIEW = 'HOME_QA_VIEW';
export const HOME_ACADEMY_VIEW = 'HOME_ACADEMY_VIEW';
export const HOME_BANNER_CLICK = 'HOME_BANNER_CLICK';
export const HOME_COURSESLIDE_SLIDE_CLICK = 'HOME_COURSESLIDE_SLIDE_CLICK';
export const HOME_COURSESLIDE_CTA_CLICK = 'HOME_COURSESLIDE_CTA_CLICK';
export const HOME_COURSEFEATURE_COURSE_CLICK =
  'HOME_COURSEFEATURE_COURSE_CLICK';
export const HOME_COURSEFEATURE_CTA_CLICK = 'HOME_COURSEFEATURE_CTA_CLICK';
export const HOME_QA_CTA_CLICK = 'HOME_QA_CTA_CLICK';
export const HOME_ACADEMY_CLICK = 'HOME_ACADEMY_CLICK';
export const HOME_RC_ALL_INSTRUCTOR_CLICK = 'HOME_RC_ALL_INSTRUCTOR_CLICK';
export const HOME_REGULAR_CLASS_LIST_ENTER = 'HOME_REGULAR_CLASS_LIST_ENTER';
export const HOME_REGISTER_CLICK = 'HOME_REGISTER_CLICK';
export const HOME_DOWNLOAD_CLICK = 'HOME_DOWNLOAD_CLICK';
export const HOME_GRADE_LEVEL_FILTER_CLICK = 'HOME_GRADE_LEVEL_FILTER_CLICK';
export const HOME_SUBJECT_CLICK = 'HOME_SUBJECT_CLICK';
export const HOME_SEE_MORE_RC_CLICK = 'HOME_SEE_MORE_RC_CLICK';
export const HOME_SEE_MORE_COURSES_CLICK = 'HOME_SEE_MORE_COURSES_CLICK';
export const HOME_COURSE_GROUP_CLICK = 'HOME_COURSE_GROUP_CLICK';
export const HOME_CONTACT_US_CLICK = 'HOME_CONTACT_US_CLICK';
export const HOME_SCROLL_DEPTH = 'HOME_SCROLL_DEPTH';

// Payment Option Events
export const PURCHASE_PAYMENT_OPTION_ITEM_CLICK =
  'PURCHASE_PAYMENT_OPTION_ITEM_CLICK';
export const PURCHASE_PAYMENT_OPTION_BUNDLE_PAGE_ENTER =
  'PURCHASE_PAYMENT_OPTION_BUNDLE_PAGE_ENTER';
export const PURCHASE_PAYMENT_OPTION_PREPACK_PAGE_ENTER =
  'PURCHASE_PAYMENT_OPTION_PREPACK_PAGE_ENTER';

// onboarding event
export const ONBOARDING_ROLE_PAGE_ENTER = 'ONBOARDING_ROLE_PAGE_ENTER';
export const ONBOARDING_ROLE_STUDENT_CLICK = 'ONBOARDING_ROLE_STUDENT_CLICK';
export const ONBOARDING_ROLE_PARENT_CLICK = 'ONBOARDING_ROLE_PARENT_CLICK';
export const ONBOARDING_RANKING_CLICK = 'ONBOARDING_RANKING_CLICK';
export const ONBOARDING_GRADE_CLICK = 'ONBOARDING_GRADE_CLICK';
export const ONBOARDING_SUBJECT_CLICK = 'ONBOARDING_SUBJECT_CLICK';
export const ONBOARDING_SETUP_PHONE_CLICK = 'ONBOARDING_SETUP_PHONE_CLICK';
export const ONBOARDING_PHONE_VERIFY_CLICK = 'ONBOARDING_PHONE_VERIFY_CLICK';
export const ONBOARDING_SETUP_PHONE_SKIP_CLICK =
  'ONBOARDING_SETUP_PHONE_SKIP_CLICK';
export const EVENT_COMPLETED_ONBOARDING = 'EVENT_COMPLETED_ONBOARDING';

// social widget event
export const WIDGET_CLICK = 'WIDGET_CLICK';
export const WIDGET_CTA_CLICK = 'WIDGET_CTA_CLICK';
