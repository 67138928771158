/* eslint-disable import/prefer-default-export */
export const transformContent = rawData => {
  const {
    checkoutCollection,
    frequency,
    lessonCount,
    totalLessonsDurationTime,
    id,
    name,
    thumbnailUrl,
    type,
  } = rawData;

  return {
    frequency,
    displayPrice: checkoutCollection?.displayPrice?.sellingPrice,
    id,
    name,
    lessonCount,
    totalLessonsDurationTime,
    price: checkoutCollection?.price?.sellingPrice,
    thumbnailUrl,
    type,
  };
};
