import LANGUAGES from './languages';
import { DEFAULT_NAMESPACE } from './namespaces';
import config from '../config';
import { NAME_LOCALE } from '../constants/cookieName';

export default {
  // document: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
  fallbackLng: LANGUAGES.DEFAULT,
  load: 'currentOnly',
  lowerCaseLng: true,
  ns: [DEFAULT_NAMESPACE],
  defaultNS: DEFAULT_NAMESPACE,

  // document: https://www.i18next.com/translation-function/interpolation
  interpolation: {
    escapeValue: false,
  },

  /**
   * document server: https://github.com/i18next/i18next-express-middleware#detector-options
   * document client: https://github.com/i18next/i18next-browser-languageDetector#detector-options
   */
  detection: {
    order: ['path', 'cookie'],
    lookupCookie: NAME_LOCALE,
    lookupFromPathIndex: 0, // url format is like /en-hk/course, the url will be split by '/' and lookup locale at index 0

    ...(process.env.BROWSER
      ? {
          cookieDomain: config.AppDomain,
          caches: ['cookie'],
        }
      : {}),
  },

  // document: https://react.i18next.com/latest/i18next-instance
  react: {
    useSuspense: false,
  },
};
