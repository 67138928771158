import { transformPlan } from 'services/transform/plan';
import { getPriceObject, getDisplayPriceObject } from 'utils/price';
import { secondsToTimeObject } from 'utils/time';

import { INTRODUCTORY, PRESALE, BASE, COST } from 'constants/priceTypes';
import { GROUP_LIST_ORDERING } from 'constants/checkoutCollectionGroupTypes';
import {
  BUNDLES,
  PREPACK,
  SUPPORTED_PAYMENT_OPTION_TYPE_LIST,
} from 'constants/checkoutCollectionTypes';

export function transformCheckoutCollection(rawData) {
  if (!rawData) return {};
  const {
    bannerImageUrl,
    collectionType,
    description,
    id,
    isPresaleExpired,
    plan,
    presaleExpiredAt,
    presalePrice,
    requiredOptionsCount,
    title,
    lessons,
    isSemester,
    totalLessonsDuration,
    expireDate,
    reviewDays,
  } = rawData;

  const transformedPlan = transformPlan(plan);

  const {
    currency,
    displayCurrency,
    introductoryPrice,
    preDiscountPrice,
    price: basePrice,
    renewable,
  } = transformedPlan;

  // list from lowest to highest price
  const priceList = [
    {
      type: INTRODUCTORY,
      price: renewable && introductoryPrice,
    },
    {
      type: PRESALE,
      price: !isPresaleExpired && presalePrice,
    },
    {
      type: BASE,
      price: basePrice,
    },
    {
      type: COST,
      price: preDiscountPrice,
    },
  ];

  return {
    bannerImageUrl,
    collectionType,
    description,
    displayCurrency,
    price: getPriceObject(priceList),
    displayPrice: getDisplayPriceObject(
      priceList,
      currency,
      displayCurrency,
      lessons?.length || 0,
    ),
    id,
    hasPresale: !isPresaleExpired,
    plan: transformedPlan,
    presaleExpiredAt,
    presalePrice,
    requiredOptionsCount,
    title,
    isSemester,
    totalLessonsDurationTime: totalLessonsDuration
      ? secondsToTimeObject(totalLessonsDuration)
      : 0,
    ...(lessons && { lessons }),
    ...(expireDate && { expireDate }),
    ...(reviewDays && { reviewDays }),
  };
}

export function transformCheckoutCollectionGroup(type, checkoutCollections) {
  switch (type) {
    case BUNDLES:
      return checkoutCollections.map(bundleGroup =>
        bundleGroup.map(transformCheckoutCollection),
      );

    case PREPACK:
      return checkoutCollections.map(transformCheckoutCollection);
    default:
      return transformCheckoutCollection(checkoutCollections);
  }
}

export function transformCheckoutCollectionGroupList(rawData) {
  return GROUP_LIST_ORDERING.reduce((groupListResult, groupType) => {
    const groupRawData = Array.isArray(rawData[groupType])
      ? { [groupType]: rawData[groupType] }
      : rawData[groupType];

    const isEmptyGroup =
      Object.values(groupRawData)
        .flat()
        .filter(Boolean).length === 0;

    return {
      ...groupListResult,
      ...(!isEmptyGroup && {
        [groupType]: Object.keys(groupRawData)
          .filter(optionType =>
            SUPPORTED_PAYMENT_OPTION_TYPE_LIST.includes(optionType),
          )
          .reduce(
            (result, optionType) => ({
              ...result,
              [optionType]: transformCheckoutCollectionGroup(
                optionType,
                groupRawData[optionType],
              ),
            }),
            {},
          ),
      }),
    };
  }, {});
}

export function transformBundleLiveTopic(rawData) {
  const { id, name, frequency, pictureUrl, checkoutCollection } = rawData;
  return {
    id,
    name,
    frequency,
    thumbnailUrl: pictureUrl,
    checkoutCollection: transformCheckoutCollection(checkoutCollection),
  };
}

export function transformBundleCourse(rawData) {
  const {
    id,
    title,
    picture: { largeUrl },
    lessons,
    totalLessonsDuration,
    checkoutCollection,
  } = rawData;
  return {
    id,
    totalLessonsDurationTime: totalLessonsDuration
      ? secondsToTimeObject(totalLessonsDuration)
      : 0,
    name: title,
    thumbnailUrl: largeUrl,
    lessonCount: lessons.length,
    checkoutCollection: transformCheckoutCollection(checkoutCollection),
  };
}

export function transformBundleGroupPass(rawData) {
  const { id, name, courseAmount, picture, checkoutCollection } = rawData;
  return {
    id,
    name,
    courseAmount,
    thumbnailUrl: picture,
    checkoutCollection: transformCheckoutCollection(checkoutCollection),
  };
}
