import { combineReducers } from 'redux';

import {
  fetchBannerListSuccess,
  fetchRegularClassLessonListSuccess,
  fetchRegularClassInstructorListSuccess,
  fetchCourseListSuccess,
  fetchAcademyListSuccess,
} from './action';

const bannerList = (state = [], { type, payload }) => {
  switch (type) {
    case fetchBannerListSuccess.type: {
      return payload;
    }

    default:
      return state;
  }
};

const INITIAL_REGULAR_CLASS = {
  lessonList: [],
  instructorList: [],
};
const regularClass = (state = INITIAL_REGULAR_CLASS, { type, payload }) => {
  switch (type) {
    case fetchRegularClassLessonListSuccess.type: {
      return { ...state, lessonList: payload };
    }
    case fetchRegularClassInstructorListSuccess.type: {
      return { ...state, instructorList: payload };
    }

    default:
      return state;
  }
};

const INITIAL_COURSE = {
  courseList: {
    byId: {},
    allIds: [],
  },
};
const course = (state = INITIAL_COURSE, { type, payload }) => {
  switch (type) {
    case fetchCourseListSuccess.type: {
      return { courseList: payload };
    }

    default:
      return state;
  }
};

const INITIAL_ACADEMY = {
  postList: [],
};
const academy = (state = INITIAL_ACADEMY, { type, payload }) => {
  switch (type) {
    case fetchAcademyListSuccess.type: {
      return { postList: payload };
    }

    default:
      return state;
  }
};

export default combineReducers({
  bannerList,
  regularClass,
  course,
  academy,
});
