import { createCommonActions } from 'utils/redux';

export const [
  fetchBannerList,
  fetchBannerListSuccess,
  fetchBannerListFailure,
] = createCommonActions('FETCH_BANNER_LIST');

export const [
  fetchRegularClassLessonList,
  fetchRegularClassLessonListSuccess,
  fetchRegularClassLessonListFailure,
] = createCommonActions('FETCH_REGULAR_CLASS_LESSON_LIST');

export const [
  fetchRegularClassInstructorList,
  fetchRegularClassInstructorListSuccess,
  fetchRegularClassInstructorListFailure,
] = createCommonActions('FETCH_REGULAR_CLASS_INSTRUCTOR_LIST');

export const [
  fetchCourseList,
  fetchCourseListSuccess,
  fetchCourseListFailure,
] = createCommonActions('FETCH_COURSE_LIST');

export const [
  fetchAcademyList,
  fetchAcademyListSuccess,
  fetchAcademyListFailure,
] = createCommonActions('FETCH_ACADEMY_LIST');
