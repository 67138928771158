/**
 * Since isBrowser in https://github.com/kriasoft/isomorphic-style-loader/blob/a990d947e79b47251f1af827adc81918494413fd/src/useStyles.js#L16-L18
 * "this" will be "undefined" under strict mode,  "insertCss" will never be triggered.
 * We need to hack the useStyle hook by ourselves, until the package fix this issue.
 * Solution reference: https://github.com/kriasoft/isomorphic-style-loader/issues/177
 */

import { useContext, useEffect } from 'react';
import StyleContext from 'isomorphic-style-loader/StyleContext';

const isBrowser = process.env.BROWSER;

function useStyles(...styles) {
  const { insertCss } = useContext(StyleContext);
  if (!insertCss)
    throw new Error(
      'Please provide "insertCss" function by StyleContext.Provider',
    );

  const runEffect = () => {
    const removeCss = insertCss(...styles);
    return () => {
      setTimeout(removeCss, 0);
    };
  };

  if (isBrowser) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(runEffect, []);
  } else {
    runEffect();
  }
}

export default useStyles;
