export const NAME_DEVICE_ID = 'deviceID';
export const NAME_TOKEN = 'token';
export const NAME_LOCALE = 'userLng';
// appsFlyer
export const APPSFLYERS_PID = 'appsFlyers_pid';
export const APPSFLYERS_C = 'appsFlyers_c';

// userData
export const NAME_USER_DATA = 'userData';
export const CAMPAIGN_ANSWERS = 'campaignAnswers';

// accountRequiresFieldList
export const HAS_SKIP_PHONE_INPUTTER = 'hasSkipPhoneInputter';
export const HAS_SKIP_UNCONFIRMED_PHONE_INPUTTER =
  'hasSkipUnconfirmedPhoneInputter';
