import { ACADEMY_SITE_ENDPOINT } from 'config';

import { generateURL } from 'utils/common';

import { getAppLocale } from 'reduxModules/app/selector';

export const generateOfficialSiteURL = state => {
  const locale = getAppLocale(state);
  return (path, params) => generateURL({ path, params, locale });
};

export const generateAcademyURL = state => {
  const locale = getAppLocale(state);
  return (path, params) =>
    generateURL({ path, baseUrl: ACADEMY_SITE_ENDPOINT, params, locale });
};
