import { all, call, put, takeLatest } from 'redux-saga/effects';

import {
  apiFetchCheckoutCollectionList,
  apiFetchLiveTopicCheckoutCollection,
  apiFetchCheckoutCollectionWithContent,
  apiFetchBundleCheckoutCollectionList,
} from 'services/apis';

import {
  fetchCheckoutCollectionList,
  fetchCheckoutCollectionListFailure,
  fetchCheckoutCollectionListSuccess,
  fetchLiveTopicMainCheckoutCollection,
  fetchLiveTopicMainCheckoutCollectionFailure,
  fetchLiveTopicMainCheckoutCollectionSuccess,
  fetchCheckoutCollectionWithContent,
  fetchCheckoutCollectionWithContentSuccess,
  fetchCheckoutCollectionWithContentFailure,
  fetchBundleCheckoutCollectionList,
  fetchBundleCheckoutCollectionListFailure,
  fetchBundleCheckoutCollectionListSuccess,
} from './action';
import {
  transformBundleCourse,
  transformBundleLiveTopic,
  transformBundleGroupPass,
  transformCheckoutCollection,
  transformCheckoutCollectionGroupList,
} from './transform';

export function* fetchLiveTopicMainCheckoutCollectionFlow(
  { fetchAPI },
  { payload: topicId },
) {
  try {
    const { checkoutCollection } = yield call(
      apiFetchLiveTopicCheckoutCollection,
      fetchAPI,
      {
        topicId,
      },
    );
    yield put(
      fetchLiveTopicMainCheckoutCollectionSuccess({
        topicId,
        checkoutCollection: transformCheckoutCollection(checkoutCollection),
      }),
    );
  } catch (error) {
    yield put(fetchLiveTopicMainCheckoutCollectionFailure());
  }
}

export function* fetchCheckoutCollectionListFlow({ fetchAPI }, { payload }) {
  try {
    const response = yield call(
      apiFetchCheckoutCollectionList,
      fetchAPI,
      payload,
    );
    yield put(
      fetchCheckoutCollectionListSuccess({
        ...payload,
        groupList: transformCheckoutCollectionGroupList(response),
      }),
    );
  } catch (error) {
    yield put(fetchCheckoutCollectionListFailure());
  }
}

export function* fetchCheckoutCollectionWithContentFlow(
  { fetchAPI },
  { payload: collectionId },
) {
  try {
    const { checkoutCollection, courses, liveTopics, groupPasses } = yield call(
      apiFetchCheckoutCollectionWithContent,
      fetchAPI,
      collectionId,
    );
    yield put(
      fetchCheckoutCollectionWithContentSuccess({
        collectionId,
        checkoutCollection: transformCheckoutCollection(checkoutCollection),
        courses: courses.map(transformBundleCourse),
        liveTopics: liveTopics.map(transformBundleLiveTopic),
        groupPasses: groupPasses.map(transformBundleGroupPass),
      }),
    );
  } catch (error) {
    yield put(fetchCheckoutCollectionWithContentFailure());
  }
}

export function* fetchBundleCheckoutCollectionListFlow(
  { fetchAPI },
  { payload: collectionId },
) {
  try {
    const { checkoutCollections } = yield call(
      apiFetchBundleCheckoutCollectionList,
      fetchAPI,
      collectionId,
    );
    yield put(
      fetchBundleCheckoutCollectionListSuccess({
        collectionId,
        checkoutCollections: checkoutCollections.map(
          transformCheckoutCollection,
        ),
      }),
    );
  } catch (error) {
    yield put(fetchBundleCheckoutCollectionListFailure());
  }
}

export default function* checkoutCollectionSaga(helpers) {
  yield all([
    takeLatest(
      fetchLiveTopicMainCheckoutCollection.type,
      fetchLiveTopicMainCheckoutCollectionFlow,
      helpers,
    ),
    takeLatest(
      fetchCheckoutCollectionList.type,
      fetchCheckoutCollectionListFlow,
      helpers,
    ),
    takeLatest(
      fetchCheckoutCollectionWithContent.type,
      fetchCheckoutCollectionWithContentFlow,
      helpers,
    ),
    takeLatest(
      fetchBundleCheckoutCollectionList.type,
      fetchBundleCheckoutCollectionListFlow,
      helpers,
    ),
  ]);
}
