// @TODO: change the naming of the env variables to be LIKE_THIS
exports.AppDomain = 'snapask.com';
exports.CookieTTLDays = 30;
exports.ApiURL = 'https://api.snapask.co';
exports.TRACKING_API_URL = 'https://log-event.snapask.com';

exports.SNAPASK_SITE_ENDPOINT = 'https://snapask.com';
exports.ACADEMY_SITE_ENDPOINT = 'https://academy.snapask.com';
exports.SNAPASK_QA_SITE_ENDPOINT = 'https://app.snapask.com';

exports.GOOGLE_TAG_MANAGER_ID = 'GTM-T2392L';
exports.GOOGLE_TAG_MANAGER_ENV =
  '&gtm_auth=LwWAlzszAmsPF4gucFSxsg&gtm_preview=env-1&gtm_cookies_win=x';

exports.ZENDESK_FAQ_SITE_ENDPOINT = 'https://support.snapask.com/hc';

exports.BRIGHTCOVE_ACCOUNT_ID = '6297938898001';
exports.BRIGHTCOVE_PLAYER_ID = 'HFiWLatSM';
