import { SUCCESS } from '@snapask/common-components/dist/constants/alertBannerType';
import { showAlertBanner, hideAlertBanner } from './action';

const INITIAL_STATE = {
  show: false,
  message: '',
  type: SUCCESS,
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case showAlertBanner.type:
      return {
        ...state,
        ...payload,
        show: true,
      };
    case hideAlertBanner.type:
      return {
        ...INITIAL_STATE,
      };

    default:
      return state;
  }
};

export default reducer;
