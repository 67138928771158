export const getMyLearningUpNextData = state => state.myLearning.upNextData;
export const getMyLearningContinueWatchingLessons = state =>
  state.myLearning.myLearningData.continueWatchingLessons;
// continue watching lessons for continue watching page.
export const getContinueWatchingLessons = state =>
  state.myLearning.continueWatching.continueWatchingLessons;

export const getContinueWatchingPageSetting = state => {
  const {
    page,
    sizePerPage,
    totalPages,
  } = state.myLearning.continueWatching.pageSetting;
  return { page, sizePerPage, totalPages, hasMorePage: page < totalPages };
};
export const getMyLearningRecommendLesson = state =>
  state.myLearning.recommendLessons;
