import { combineReducers } from 'redux';

import { createActionStatusReducer } from 'utils/redux';

import { appLaunch } from 'reduxModules/auth/action';
import {
  fetchLiveTopics,
  fetchLiveUpcoming,
  fetchHomeLessonList,
  fetchSchedulePastLesson,
  fetchScheduleUpcomingLesson,
  fetchSubscribedOngoingTopic,
  fetchSubscribedCompletedTopic,
  fetchSubscribedTopic,
  fetchSubscribedPreOrderedTopic,
} from 'reduxModules/live/action';
import {
  fetchCourseAnnouncements,
  fetchLearningCourse,
  fetchUserPurchasedCourse,
} from 'reduxModules/course/action';
import { fetchInstructorList } from 'reduxModules/instructorProfile/action';
import {
  fetchMyLearningData,
  fetchMyLearningContinueWatching,
} from 'reduxModules/myLearning/action';
import { fetchSiteInfo } from 'reduxModules/siteInfo/action';
import {
  fetchBannerList,
  fetchRegularClassLessonList,
  fetchRegularClassInstructorList,
  fetchCourseList,
  fetchAcademyList,
} from 'reduxModules/home/action';
import {
  fetchBannerList as fetchHomeBannerList,
  fetchRegularClassLessonList as fetchHomeRegularClassLessonList,
  fetchCourseList as fetchHomeCourseList,
  fetchAcademyList as fetchHomeAcademyList,
} from 'reduxModules/homeRevamp/action';
import {
  updateUserAttributeData,
  sendPhoneVerificationCode,
  verifyPhone,
  resentPhoneVerificationCode,
} from 'reduxModules/account/action';

export default combineReducers({
  [appLaunch.type]: createActionStatusReducer(appLaunch.type),
  [fetchLiveUpcoming.type]: createActionStatusReducer(fetchLiveUpcoming.type),
  [fetchHomeLessonList.type]: createActionStatusReducer(
    fetchHomeLessonList.type,
  ),
  [fetchLiveTopics.type]: createActionStatusReducer(fetchLiveTopics.type),
  [fetchSchedulePastLesson.type]: createActionStatusReducer(
    fetchSchedulePastLesson.type,
  ),
  [fetchScheduleUpcomingLesson.type]: createActionStatusReducer(
    fetchScheduleUpcomingLesson.type,
  ),
  [fetchSubscribedTopic.type]: createActionStatusReducer(
    fetchSubscribedTopic.type,
  ),
  [fetchSubscribedPreOrderedTopic.type]: createActionStatusReducer(
    fetchSubscribedPreOrderedTopic.type,
  ),
  [fetchSubscribedOngoingTopic.type]: createActionStatusReducer(
    fetchSubscribedOngoingTopic.type,
  ),
  [fetchSubscribedCompletedTopic.type]: createActionStatusReducer(
    fetchSubscribedCompletedTopic.type,
  ),
  [fetchCourseAnnouncements.type]: createActionStatusReducer(
    fetchCourseAnnouncements.type,
  ),
  [fetchLearningCourse.type]: createActionStatusReducer(
    fetchLearningCourse.type,
  ),
  [fetchUserPurchasedCourse.type]: createActionStatusReducer(
    fetchUserPurchasedCourse.type,
  ),
  [fetchInstructorList.type]: createActionStatusReducer(
    fetchInstructorList.type,
  ),
  [fetchMyLearningData.type]: createActionStatusReducer(
    fetchMyLearningData.type,
  ),
  [fetchMyLearningContinueWatching.type]: createActionStatusReducer(
    fetchMyLearningContinueWatching.type,
  ),
  [fetchSiteInfo.type]: createActionStatusReducer(fetchSiteInfo.type),
  [fetchBannerList.type]: createActionStatusReducer(fetchBannerList.type),
  [fetchRegularClassLessonList.type]: createActionStatusReducer(
    fetchRegularClassLessonList.type,
  ),
  [fetchRegularClassInstructorList.type]: createActionStatusReducer(
    fetchRegularClassInstructorList.type,
  ),
  [fetchCourseList.type]: createActionStatusReducer(fetchCourseList.type),
  [fetchAcademyList.type]: createActionStatusReducer(fetchAcademyList.type),
  [fetchHomeBannerList.type]: createActionStatusReducer(
    fetchHomeBannerList.type,
  ),
  [fetchHomeRegularClassLessonList.type]: createActionStatusReducer(
    fetchHomeRegularClassLessonList.type,
  ),
  [fetchHomeCourseList.type]: createActionStatusReducer(
    fetchHomeCourseList.type,
  ),
  [fetchHomeAcademyList.type]: createActionStatusReducer(
    fetchHomeAcademyList.type,
  ),
  [updateUserAttributeData.type]: createActionStatusReducer(
    updateUserAttributeData.type,
  ),
  [sendPhoneVerificationCode.type]: createActionStatusReducer(
    sendPhoneVerificationCode.type,
  ),
  [verifyPhone.type]: createActionStatusReducer(verifyPhone.type),
  [resentPhoneVerificationCode.type]: createActionStatusReducer(
    resentPhoneVerificationCode.type,
  ),
});
