import { fetchSiteInfoSuccess } from './action';

/**
 * @typedef {Object} CampaignLinks
 * @property {array} regularClass
 * @property {array} course
 * @property {array} activity
 *
 * */

/**
 * @typedef {Object} ShowInstructorBtn
 * @property {bool} liveTopic
 * @property {bool} course
 *
 * */

/**
 * @type {{
 * zendeskFAQLink: string
 * offerings: array,
 * multipleOfferingMode: boolean,
 * showInstructorBtn: ShowInstructorBtn,
 * campaignLinks: CampaignLinks
 * }}
 */

const initialState = {
  zendeskFAQLink: '',
  offerings: [],
  multipleOfferingMode: false,
  showInstructorBtn: {
    course: false,
    liveTopic: false,
  },
  campaignLinks: {
    regularClass: [],
    course: [],
    activity: [],
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case fetchSiteInfoSuccess.type:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};
