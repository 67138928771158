import {
  all,
  call,
  put,
  takeLatest,
  select,
  takeEvery,
} from 'redux-saga/effects';

import {
  apiFetchCourseList,
  apiFetchCourse,
  apiFetchCourseAnnouncements,
  apiFetchLearningCourse,
  apiFetchFreeCourseList,
  apiFetchCoursePromotion,
  apiUpdateCourseProgress,
  apiPostCourseRating,
  apiFetchUserPurchasedCourse,
  apiFetchBannerList,
  fetchContentTypeGradeLevels,
  apiFetchCourseGroupsCourseList,
} from 'services/apis';

import { FEATURED, FREE } from 'constants/courseTabKeys';
import {
  getCourseHomeFilterInfo,
  getLearningCoursePageSetting,
  getUserPurchasedCoursePageSetting,
} from 'reduxModules/course/selector';
import { fetchCourseMainCheckoutCollectionSuccess } from 'reduxModules/checkoutCollection/action';

import { ACTIVE } from 'constants/learningCourseStatus';
import {
  fetchCourse,
  fetchCourseSuccess,
  fetchCourseFailure,
  fetchCourseAnnouncements,
  fetchCourseAnnouncementsSuccess,
  fetchCourseAnnouncementsFailure,
  fetchCourseAnnouncementsClear,
  fetchLearningCourse,
  fetchLearningCourseSuccess,
  fetchLearningCourseFailure,
  fetchCourseListWithGroup,
  fetchCourseListWithGroupSuccess,
  fetchCourseListWithGroupFailure,
  fetchCourseFeaturedList,
  fetchCourseFeaturedListSuccess,
  fetchCourseFeaturedListFailure,
  fetchCourseFreeList,
  fetchCourseFreeListSuccess,
  fetchCourseFreeListFailure,
  fetchCoursePromotionSuccess,
  fetchCoursePromotionFailure,
  fetchCoursePromotion,
  fetchGroupCourseList,
  fetchGroupCourseListSuccess,
  fetchGroupCourseListFailure,
  postCourseRating,
  postCourseRatingSuccess,
  postCourseRatingFailure,
  updateCourseProgress,
  updateCourseProgressSuccess,
  updateCourseProgressFailure,
  fetchUserPurchasedCourse,
  fetchUserPurchasedCourseSuccess,
  fetchUserPurchasedCourseFailure,
  fetchCourseBannersSuccess,
  fetchCourseBannersFailure,
  fetchCourseBanners,
  fetchCourseGradeLevelsSuccess,
  fetchCourseGradeLevelsFailure,
  fetchGroupCourseListForHome,
  fetchGroupCourseListForHomeSuccess,
  fetchGroupCourseListForHomeFailure,
  fetchCourseGradeLevels,
} from './action';

import {
  normalizeCourse,
  normalizeCourseGroupList,
  normalizeCourseMenuList,
  transformCourse,
  transformCourseForGroup,
  transformCoursePromotion,
  transformLearningCourse,
} from './transform';
import { getAppRegion } from 'reduxModules/app/selector';

export function* fetchCoursePromotionFlow({ fetchAPI }) {
  try {
    const response = yield call(apiFetchCoursePromotion, fetchAPI);
    yield put(fetchCoursePromotionSuccess(transformCoursePromotion(response)));
  } catch (error) {
    yield put(fetchCoursePromotionFailure(error));
  }
}

export function* fetchCourseListWithGroupFlow({ fetchAPI }, { payload }) {
  try {
    const { leadGroups } = yield call(apiFetchCourseList, fetchAPI, payload);
    yield put(
      fetchCourseListWithGroupSuccess({
        ...normalizeCourseGroupList(leadGroups),
      }),
    );
  } catch (error) {
    yield put(fetchCourseListWithGroupFailure(error));
  }
}

export function* fetchCourseFeaturedListFlow({ fetchAPI }, { payload }) {
  try {
    const { courses, currentPage, totalPages } = yield call(
      apiFetchCourseList,
      fetchAPI,
      payload,
    );

    yield put(
      fetchCourseFeaturedListSuccess({
        tabKey: FEATURED,
        courses: normalizeCourseMenuList(courses),
        currentPage,
        totalPages,
      }),
    );
  } catch (error) {
    yield put(fetchCourseFeaturedListFailure(error));
  }
}

export function* fetchCourseFreeListFlow({ fetchAPI }, { payload }) {
  try {
    const {
      freeRecommendCourses: courses,
      currentPage,
      totalPages,
    } = yield call(apiFetchFreeCourseList, fetchAPI, payload);

    yield put(
      fetchCourseFreeListSuccess({
        tabKey: FREE,
        courses: normalizeCourseMenuList(courses),
        currentPage,
        totalPages,
      }),
    );
  } catch (error) {
    yield put(fetchCourseFreeListFailure(error));
  }
}

export function* fetchGroupCourseListFlow({ fetchAPI }, { payload }) {
  const PAGE_SIZE = 24;
  try {
    const { selectedGradeLevel, selectedSubject } = yield select(
      getCourseHomeFilterInfo,
    );

    const {
      courseGroup: { name, description },
      courses,
      currentPage,
      totalPages,
    } = yield call(apiFetchCourseGroupsCourseList, fetchAPI, {
      ...payload,
      gradeLevelId: selectedGradeLevel,
      subjectId: selectedSubject,
      pageSize: PAGE_SIZE,
    });

    yield put(
      fetchGroupCourseListSuccess({
        id: payload.groupId,
        courses: transformCourseForGroup(payload.groupId, courses),
        currentPage,
        totalPages,
        title: name,
        description: description ?? '',
      }),
    );
  } catch (error) {
    yield put(fetchGroupCourseListFailure(error));
  }
}

export function* fetchGroupCourseListForHomeFlow({ fetchAPI }, { payload }) {
  const CURRENT_PAGE = 1;
  const PAGE_SIZE = 8;
  try {
    const { selectedGradeLevel, selectedSubject } = yield select(
      getCourseHomeFilterInfo,
    );

    const fetchList = payload.groupIdList.map(groupId => {
      return call(apiFetchCourseGroupsCourseList, fetchAPI, {
        groupId,
        currentPage: CURRENT_PAGE,
        pageSize: PAGE_SIZE,
        gradeLevelId: selectedGradeLevel,
        subjectId: selectedSubject,
      });
    });

    const resultList = yield all(fetchList);

    const courses = resultList.reduce(
      // eslint-disable-next-line no-shadow
      (total, { courses, totalPages }, index) => {
        return {
          ...total,
          ...transformCourseForGroup(
            payload.groupIdList[index],
            courses,
            totalPages > 1,
          ),
        };
      },
      {},
    );

    yield put(
      fetchGroupCourseListForHomeSuccess({
        courses,
      }),
    );
  } catch (error) {
    yield put(fetchGroupCourseListForHomeFailure(error));
  }
}

export function* fetchCourseAnnouncementsFlow({ fetchAPI }, { payload }) {
  try {
    const { courseId } = payload;
    const announcementList = yield call(
      apiFetchCourseAnnouncements,
      fetchAPI,
      payload,
    );
    yield put(
      fetchCourseAnnouncementsSuccess({ courseId, data: announcementList }),
    );
  } catch (error) {
    yield put(fetchCourseAnnouncementsFailure(error));
  }
}

export function* fetchCourseFlow(
  { fetchAPI, i18n },
  { payload: { id, isLearningPage } },
) {
  try {
    yield i18n.loadNamespaces(['course']);
    const { course } = yield call(apiFetchCourse, fetchAPI, id);

    const courseData = normalizeCourse({
      trailerTitle: i18n.t('course:videoplayer.courseTrailer'),
      isLearningPage,
      ...course,
    });

    yield put(
      fetchCourseMainCheckoutCollectionSuccess({
        courseId: id,
        checkoutCollection: courseData.byId[id].checkoutCollection,
      }),
    );
    yield put(fetchCourseAnnouncementsClear());
    yield put(fetchCourseSuccess(courseData));
  } catch (error) {
    yield put(fetchCourseFailure(error));
  }
}

export function* fetchLearningCourseFlow(
  { fetchAPI },
  { payload: { status = ACTIVE } = {} },
) {
  const { sizePerPage, page } = yield select(getLearningCoursePageSetting);
  try {
    const { courses, totalPages } = yield call(
      apiFetchLearningCourse,
      fetchAPI,
      {
        status,
        page,
        sizePerPage,
      },
    );

    yield put(
      fetchLearningCourseSuccess({
        courses: courses.map(transformLearningCourse),
        totalPages,
      }),
    );
  } catch (error) {
    yield put(fetchLearningCourseFailure());
  }
}

export function* fetchUserPurchasedCourseFlow({ fetchAPI }) {
  const { sizePerPage, page } = yield select(getUserPurchasedCoursePageSetting);
  try {
    const { courses, currentPage, totalPages } = yield call(
      apiFetchUserPurchasedCourse,
      fetchAPI,
      {
        page,
        sizePerPage,
      },
    );

    yield put(
      fetchUserPurchasedCourseSuccess({
        courses: courses.map(transformCourse),
        page: currentPage,
        totalPages,
      }),
    );
  } catch (error) {
    yield put(fetchUserPurchasedCourseFailure());
  }
}

export function* updateCourseProgressFlow({ fetchAPI }, { payload }) {
  const { progressDuration, courseId, lessonId } = payload;

  try {
    const { lesson } = yield call(apiUpdateCourseProgress, fetchAPI, {
      progressDuration,
      courseId,
      lessonId,
    });

    yield put(
      updateCourseProgressSuccess({
        courseId,
        lessonId,
        lesson,
      }),
    );
  } catch (error) {
    yield put(updateCourseProgressFailure());
  }
}

export function* postCourseRatingFlow({ fetchAPI }, { payload }) {
  const { courseId, lessonId, stars, comment } = payload;

  try {
    yield call(apiPostCourseRating, fetchAPI, {
      courseId,
      lessonId,
      stars,
      comment,
    });

    yield put(postCourseRatingSuccess({ courseId, lessonId }));
  } catch (error) {
    yield put(postCourseRatingFailure());
  }
}

export function* fetchCourseBannersFlow({ fetchAPI }, { payload }) {
  const region = yield select(getAppRegion);
  const gradeLevelId =
    payload.gradeLevelId === 0 ? undefined : payload.gradeLevelId;

  const data = {
    region,
    sectionName: 'course',
    gradeLevelId,
  };

  try {
    const { banners: bannerList } = yield call(
      apiFetchBannerList,
      fetchAPI,
      data,
    );

    yield put(fetchCourseBannersSuccess(bannerList));
  } catch (error) {
    yield put(fetchCourseBannersFailure());
  }
}

export function* fetchCourseGradeLevelsFlow({ fetchAPI }) {
  try {
    const region = yield select(getAppRegion);
    const { gradeLevels } = yield call(fetchContentTypeGradeLevels, fetchAPI, {
      region,
      contentType: 'course',
    });

    yield put(fetchCourseGradeLevelsSuccess(gradeLevels));
  } catch (error) {
    yield put(fetchCourseGradeLevelsFailure());
  }
}

export default function* courseSaga(helpers) {
  yield all([
    takeLatest(
      fetchCourseFeaturedList.type,
      fetchCourseFeaturedListFlow,
      helpers,
    ),
    takeLatest(fetchCourseFreeList.type, fetchCourseFreeListFlow, helpers),
    takeLatest(fetchGroupCourseList.type, fetchGroupCourseListFlow, helpers),
    takeLatest(
      fetchCourseListWithGroup.type,
      fetchCourseListWithGroupFlow,
      helpers,
    ),
    takeLatest(fetchCourse.type, fetchCourseFlow, helpers),
    takeLatest(
      fetchCourseAnnouncements.type,
      fetchCourseAnnouncementsFlow,
      helpers,
    ),
    takeLatest(fetchLearningCourse.type, fetchLearningCourseFlow, helpers),
    takeLatest(
      fetchUserPurchasedCourse.type,
      fetchUserPurchasedCourseFlow,
      helpers,
    ),
    takeLatest(fetchCoursePromotion.type, fetchCoursePromotionFlow, helpers),
    takeLatest(updateCourseProgress.type, updateCourseProgressFlow, helpers),
    takeLatest(postCourseRating.type, postCourseRatingFlow, helpers),
    takeLatest(fetchCourseBanners.type, fetchCourseBannersFlow, helpers),
    takeEvery(
      fetchGroupCourseListForHome.type,
      fetchGroupCourseListForHomeFlow,
      helpers,
    ),
    takeLatest(
      fetchCourseGradeLevels.type,
      fetchCourseGradeLevelsFlow,
      helpers,
    ),
  ]);
}
