import { toCamel, toSnake } from 'convert-keys';

import { handleGlobalException } from 'reduxModules/auth/saga';
import { getUserRegion } from 'reduxModules/user/selector';
import { getAppLocale } from 'reduxModules/app/selector';

import { NAME_LOCALE, NAME_TOKEN } from './constants/cookieName';
import { splitLocale } from './utils/common';

export const bodyTypeFormData = 'formData';

export default function createFetchAPI(fetch, fetchOptions) {
  const urlWithParams = (url, params = {}) => {
    const transformedParams = Object.entries(params).reduce(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = value;
        }

        return acc;
      },
      {},
    );
    const searchParams = new URLSearchParams(transformedParams);

    if (!searchParams.toString()) return url;

    return `${url}?${searchParams}`;
  };

  /**
   * @param {string} url
   * @param {object} options
   * @param {string} options.method
   * @param {object} options.params URL parameters to be sent with the request
   * @param {object} options.headers
   * @param {object} options.body
   */
  return async (url, options = {}) => {
    const {
      baseUrl,
      context: { cookie, userAgent },
      store,
    } = fetchOptions;
    const {
      baseUrl: customizedBaseUrl,
      getResponse,
      bodyType,
      params,
      headers,
      body,
      bodyNoToSnake,
      ...fetchSettings
    } = options;

    const { [NAME_TOKEN]: userToken, [NAME_LOCALE]: locale } = cookie();

    const defaults = {
      method: 'GET',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    const reduxStore = store && store.getState();
    const userRegion = reduxStore && getUserRegion(reduxStore);
    const appLocale = locale || (reduxStore && getAppLocale(reduxStore));
    // merge queries
    // make sure lang in format: en-HK (regions should be CAPITAL)
    const capitalizedRegionLang = appLocale.replace(
      /(\w+)-(\w+)/,
      (match, p1, p2) => `${p1}-${p2.toUpperCase()}`,
    );

    const apiParams = {
      ...params,
      lang: capitalizedRegionLang,
    };

    const fetchUrl = `${customizedBaseUrl || baseUrl}${urlWithParams(
      url,
      apiParams,
    )}`;

    const getHeaders = () => {
      const mergedHeaders = {
        ...defaults.headers,
        'Region-Name': userRegion || splitLocale(appLocale).region,
        ...(userToken && {
          'Snapask-Authorization': `Bearer ${userToken}`,
        }),
        ...(userAgent && {
          'Snapask-User-Agent': userAgent,
        }),
        ...headers,
      };

      if (bodyType === bodyTypeFormData) {
        delete mergedHeaders['Content-Type'];
      }

      return mergedHeaders;
    };

    const getBody = data => {
      if (bodyType === bodyTypeFormData) {
        return data;
      }

      if (bodyNoToSnake) {
        return JSON.stringify(data);
      }

      return JSON.stringify(toSnake(data));
    };

    const settings = {
      ...defaults,
      ...fetchSettings,
      headers: getHeaders(),
    };

    if (body) {
      settings.body = getBody(body);
    }

    const response = await fetch(fetchUrl, settings);

    const responseBody = await response.text();
    const data = responseBody && toCamel(JSON.parse(responseBody));

    if (!response.ok) {
      store.runSagas(handleGlobalException, data);
      return Promise.reject(data);
    }

    return getResponse ? getResponse(data) : data.data;
  };
}
