import {
  REGULAR_CLASS_ABOUT_TAB,
  REGULAR_CLASS_MATERIAL_TAB,
  REGULAR_CLASS_INSTRUCTOR_TAB,
  REGULAR_CLASS_QUESTIONS_TAB,
  REGULAR_CLASS_REVIEW_SECTION_CLICK,
} from 'constants/gtmEvent';

export const MATERIALS = 'materials';
export const ABOUT = 'about';
export const SCHEDULE = 'schedule';
export const INSTRUCTOR = 'instructor';
export const QUESTIONS = 'questions';
export const KEY_POINT = 'keyPoint';
export const SUMMARY = 'summary';
export const REVIEW = 'review';

export const tabEventMapping = {
  [ABOUT]: REGULAR_CLASS_ABOUT_TAB,
  [MATERIALS]: REGULAR_CLASS_MATERIAL_TAB,
  [INSTRUCTOR]: REGULAR_CLASS_INSTRUCTOR_TAB,
  [REVIEW]: REGULAR_CLASS_REVIEW_SECTION_CLICK,
  [QUESTIONS]: REGULAR_CLASS_QUESTIONS_TAB,
};
