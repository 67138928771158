import { all, put, takeLatest, select, call } from 'redux-saga/effects';

import { getAppRegion } from 'reduxModules/app/selector';
import { apiFetchUpsellBanner } from 'services/apis';

import {
  fetchUpsellBanner,
  fetchUpsellBannerSuccess,
  fetchUpsellBannerFailure,
} from './action';

export function* fetchUpsellBannerFlow({ fetchAPI }, { payload }) {
  try {
    const { page } = payload;
    const region = yield select(getAppRegion);

    const upsellBanner = yield call(apiFetchUpsellBanner, fetchAPI, {
      page,
      regionName: region,
    });

    yield put(
      fetchUpsellBannerSuccess({
        page,
        upsellBanner,
      }),
    );
  } catch (e) {
    fetchUpsellBannerFailure();
  }
}

export default function* upsellBannerSaga(helpers) {
  yield all([
    takeLatest(fetchUpsellBanner.type, fetchUpsellBannerFlow, helpers),
  ]);
}
