import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { REGULAR_CLASS, COURSE } from 'constants/contentTypes';
import {
  apiFetchInstructorProfile,
  apiFetchInstructorList,
  apiFetchCourseListByInstructor,
  apiFetchLiveTopicListByInstructor,
} from 'services/apis';
import {
  fetchInstructorProfile,
  fetchInstructorProfileSuccess,
  fetchInstructorProfileFailure,
  fetchInstructorList,
  fetchInstructorListSuccess,
  fetchInstructorListFailure,
  fetchContentListByInstructor,
  fetchContentListByInstructorSuccess,
  fetchContentListByInstructorFailure,
} from './action';

import {
  transformInstructorProfile,
  transformInstructorList,
  transformCourseList,
  transformLiveTopicList,
} from './transform';

import { getInstructorListPageSetting } from './selector';

export function* fetchInstructorProfileFlow({ fetchAPI }, action) {
  const { id } = action.payload;
  try {
    const profileData = yield call(apiFetchInstructorProfile, fetchAPI, id);
    const transformedProfile = transformInstructorProfile(profileData);
    yield put(fetchInstructorProfileSuccess(transformedProfile));
  } catch {
    yield put(fetchInstructorProfileFailure());
  }
}

export function* fetchInstructorListFlow({ fetchAPI }, action) {
  const { type } = action.payload;
  const { page, sizePerPage } = yield select(getInstructorListPageSetting);

  try {
    // replace the 'regular-class' with 'live_topic' for the parameters that backend need.
    const contentType = type === 'regular-class' ? REGULAR_CLASS : COURSE;
    const { instructors, totalPages } = yield call(
      apiFetchInstructorList,
      fetchAPI,
      { contentType, page, sizePerPage },
    );

    yield put(
      fetchInstructorListSuccess({
        instructorList: transformInstructorList(instructors),
        totalPages,
      }),
    );
  } catch {
    yield put(fetchInstructorListFailure());
  }
}

export function* fetchContentListByInstructorFlow({ fetchAPI }, action) {
  const { id, contentType } = action.payload;

  const isCourseType = contentType === COURSE;
  try {
    const { courses, liveTopics } = isCourseType
      ? yield call(apiFetchCourseListByInstructor, fetchAPI, id)
      : yield call(apiFetchLiveTopicListByInstructor, fetchAPI, id);

    const transformedData = isCourseType
      ? transformCourseList(courses)
      : transformLiveTopicList(liveTopics);

    yield put(fetchContentListByInstructorSuccess(transformedData));
  } catch {
    yield put(fetchContentListByInstructorFailure());
  }
}

export default function*(helpers) {
  yield all([
    takeLatest(
      fetchInstructorProfile.type,
      fetchInstructorProfileFlow,
      helpers,
    ),
    takeLatest(fetchInstructorList.type, fetchInstructorListFlow, helpers),
    takeLatest(
      fetchContentListByInstructor.type,
      fetchContentListByInstructorFlow,
      helpers,
    ),
  ]);
}
