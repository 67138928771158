/* eslint-disable global-require */
import * as PATH_NAMES from 'constants/routingPath';

// The top-level (parent) route
const routes = {
  path: '/:locale([a-z]{2}-[a-z]{2})',

  // Keep in mind, routes are evaluated in order
  children: [
    {
      path: PATH_NAMES.HOME,
      load: () => import(/* webpackChunkName: 'home' */ './home/Home'),
    },
    {
      path: PATH_NAMES.ACCOUNT_SETUP,
      authRequired: true,
      load: () =>
        import(/* webpackChunkName: 'accountSetup' */ './account/Setup'),
    },
    // {
    //   path: PATH_NAMES.LIVE,
    //   load: () => import(/* webpackChunkName: 'liveHome' */ './live/LiveHome'),
    // },
    {
      path: PATH_NAMES.LIVE,
      load: () => import(/* webpackChunkName: 'rcHome' */ './live/RcHome'),
    },
    {
      path: PATH_NAMES.LIVE_UPCOMING,
      load: () =>
        import(/* webpackChunkName: 'liveUpcoming' */ './live/LiveUpcoming'),
    },
    {
      path: PATH_NAMES.LIVE_TOPICS,
      load: () =>
        import(/* webpackChunkName: 'liveTopics' */ './live/LiveTopicList'),
    },
    {
      path: PATH_NAMES.LIVE_TOPIC,
      load: () =>
        import(/* webpackChunkName: 'liveTopic' */ './live/LiveTopic'),
    },
    {
      path: PATH_NAMES.LIVE_TOPIC_LESSON,
      load: () =>
        import(
          /* webpackChunkName: 'LiveTopicLesson' */ './live/LiveTopicLesson'
        ),
    },
    {
      path: PATH_NAMES.LIVE_SYLLABUS,
      load: () =>
        import(
          /* webpackChunkName: 'LiveTopicSyllabus' */ './live/LiveTopicSyllabus'
        ),
    },
    {
      path: PATH_NAMES.LIVE_SCHEDULE,
      authRequired: true,
      load: () =>
        import(
          /* webpackChunkName: 'liveUserSchedule' */ './live/LiveUserSchedule'
        ),
    },
    {
      path: PATH_NAMES.LIVE_SUBSCRIBED,
      authRequired: true,
      load: () =>
        import(
          /* webpackChunkName: 'liveUserSubscribe' */ './live/LiveUserSubscribe'
        ),
    },
    {
      path: PATH_NAMES.COURSE_GROUP,
      load: () =>
        import(/* webpackChunkName: 'courseGroup' */ './course/CourseGroup'),
      needUserRegionLocale: true,
    },
    {
      path: PATH_NAMES.COURSE,
      load: () =>
        import(
          /* webpackChunkName: 'courseHome' */ './course/CourseHomeRevamp'
        ),
      needUserRegionLocale: true,
    },
    {
      path: PATH_NAMES.COURSE_INFORMATION,
      load: () => import(/* webpackChunkName: 'course' */ './course/Course'),
      needUserRegionLocale: true,
    },
    {
      path: PATH_NAMES.COURSE_INFORMATION_LESSON,
      load: () =>
        import(/* webpackChunkName: 'courseLesson' */ './course/CourseLesson'),
      needUserRegionLocale: true,
    },
    {
      path: PATH_NAMES.COURSE_SUBSCRIBED,
      load: () =>
        import(
          /* webpackChunkName: 'courseUserSubscribe' */ './course/CourseUserSubscribe'
        ),
      needUserRegionLocale: true,
    },
    {
      path: PATH_NAMES.COURSE_LEARNING,
      load: () =>
        import(
          /* webpackChunkName: 'courseLearning' */ './course/CourseLearning'
        ),
      authRequired: true,
      needUserRegionLocale: true,
    },
    {
      path: PATH_NAMES.COURSE_LEARNING_LESSON,
      load: () =>
        import(
          /* webpackChunkName: 'courseLearning' */ './course/CourseLearning'
        ),
      authRequired: true,
      needUserRegionLocale: true,
    },
    {
      path: PATH_NAMES.CONTENT_BUNDLE,
      load: () => import(/* webpackChunkName: 'bundle' */ './bundle'),
    },
    {
      path: PATH_NAMES.CONTENT_PREPACK,
      load: () => import(/* webpackChunkName: 'prepack' */ './prepack'),
    },
    {
      path: PATH_NAMES.INSTRUCTOR,
      load: () =>
        import(
          /* webpackChunkName: 'instructorProfile' */ './instructor-profile/InstructorProfile'
        ),
    },
    {
      path: PATH_NAMES.INSTRUCTOR_LIST,
      load: () =>
        import(
          /* webpackChunkName: 'instructorList' */ './instructor-profile/InstructorList'
        ),
    },
    {
      path: PATH_NAMES.INSTRUCTOR_CONTENT_LIST,
      load: () =>
        import(
          /* webpackChunkName: 'ContentListByInstructor' */ './instructor-profile/ContentListByInstructor'
        ),
    },
    {
      path: PATH_NAMES.MY_LEARNING,
      authRequired: true,
      load: () =>
        import(/* webpackChunkName: 'myLearning' */ './my-learning/MyLearning'),
    },
    {
      path: PATH_NAMES.MY_LEARNING_CONTINUE,
      load: () =>
        import(
          /* webpackChunkName: 'continueWatching' */ './my-learning/ContinueWatching'
        ),
    },
    {
      path: PATH_NAMES.COURSE_SUBSCRIBED,
      load: () =>
        import(
          /* webpackChunkName: 'courseUserSubscribe' */ './course/CourseUserSubscribe'
        ),
    },
    // Wildcard routes, e.g. { path: '(.*)', ... } (must go last)
    {
      path: '(.*)',
      load: () => import(/* webpackChunkName: 'not-found' */ './not-found'),
    },
  ],

  async action({ next }) {
    // Execute each child route until one of them return the result
    const route = await next();

    // Provide default values for title, description etc.
    route.title = route.title || 'Snapask';
    route.description = route.description || '';

    return route;
  },
};

// The error page is available by permanent url for development mode
if (__DEV__) {
  routes.children.unshift({
    path: '/error',
    action: require('./error').default,
  });
}

export default routes;
