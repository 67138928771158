import { all, call, put, takeLatest, select } from 'redux-saga/effects';

import {
  apiSubmitTicketToZendesk,
  apiUploadFileToZendesk,
} from 'services/apis';
import { showAlertBanner } from 'reduxModules/alertBanner/action';
import {
  SUCCESS,
  ERROR,
} from '@snapask/common-components/dist/constants/alertBannerType';

import { getUserInfo } from 'reduxModules/user/selector';
import {
  submitReport,
  submitReportSuccess,
  submitReportFailed,
  uploadFile,
  uploadFileSuccess,
  uploadFileFailed,
} from './action';
import { getReport } from './selector';

export function* submitReportFlow({ fetchAPI, i18n }, { payload }) {
  const { email, message } = payload;
  const info = yield select(getUserInfo);
  const { userId, username, role, region } = info || {};
  const { files } = yield select(getReport);

  try {
    const tokens = files.map(file => file.token);
    yield call(apiSubmitTicketToZendesk, fetchAPI, {
      name: username,
      email,
      role,
      region,
      userId,
      message,
      studentId: userId,
      projectName: 'DRAGON_FRUIT',
      tags: [],
      tokens,
      isReportToZendeskByAdmin: true,
    });
    yield put(submitReportSuccess());
    yield put(
      showAlertBanner({
        message: i18n.t('sdk:sdk.report.successMessage'),
        type: SUCCESS,
      }),
    );
  } catch (error) {
    yield put(submitReportFailed(error));
    yield put(
      showAlertBanner({
        message: error.errorMessage,
        type: ERROR,
      }),
    );
  }
}

export function* uploadFileFlow({ fetchAPI }, { payload: file } = {}) {
  try {
    const uploadedFile = yield call(apiUploadFileToZendesk, fetchAPI, file);
    const newFile = {
      token: uploadedFile.token,
      name: uploadedFile.attachment.fileName,
      size: uploadedFile.attachment.size,
    };
    yield put(uploadFileSuccess(newFile));
  } catch (error) {
    yield put(uploadFileFailed(error));
    yield put(
      showAlertBanner({
        message: error.errorMessage,
        type: ERROR,
      }),
    );
  }
}

export default function* reportSaga(helpers) {
  yield all([
    takeLatest(submitReport.type, submitReportFlow, helpers),
    takeLatest(uploadFile.type, uploadFileFlow, helpers),
  ]);
}
