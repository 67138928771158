import {
  push,
  replace,
  go,
  goBack,
  goForward,
} from 'reduxModules/navigation/action';

// eslint-disable-next-line consistent-return
export default history => () => next => action => {
  switch (action.type) {
    case push.type:
      history.push(action.payload);
      break;

    case replace.type:
      history.replace(action.payload);
      break;

    case go.type:
      history.go(action.payload);
      break;

    case goBack.type:
      history.goBack();
      break;

    case goForward.type:
      history.goForward();
      break;

    default:
      return next(action);
  }
};
