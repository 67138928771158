export const FEATURED = 'featured';
export const FREE = 'free';

export const COURSE_OVERVIEW = 'course_overview';
export const COURSE_MATERIAL = 'course_material';
export const COURSE_REVIEW = 'course_review';
export const COURSE_DETAIL = 'course_detail';
export const COURSE_INSTRUCTOR = 'course_instructor';
export const COURSE_FAQ = 'course_faq';

export const LESSON_KEYPOINTS = 'lesson_keypoints';
export const LESSON_SUMMARY = 'lesson_summary';
export const LESSON_MATERIAL = 'lesson_material';
export const LESSON_INSTRUCTOR = 'lesson_instructor';
export const LESSON_FAQ = 'lesson_faq';

export const LESSON = 'lesson';
export const ANNOUNCEMENT = 'announcement';
