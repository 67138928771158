import { combineReducers } from 'redux';

import {
  initialBundleCart,
  bundleCartAddItemSuccess,
  bundleCartRemoveItemSuccess,
} from './action';

/**
 * @typedef {Object} BundleCartContentItem
 * @property {number} id
 * @property {string} type Content Type
 * @property {string} name
 * @property {string} description
 * @property {string} thumbnailUrl
 * @property {number} price
 * @property {string} displayPrice
 */

/** @type {BundleCartContentItem[]} */
const INITIAL_STATE_ITEMS = [];

export const items = (state = INITIAL_STATE_ITEMS, { type, payload }) => {
  switch (type) {
    case bundleCartAddItemSuccess.type:
      return Array.from(new Set([...state, payload]));

    case bundleCartRemoveItemSuccess.type:
      return payload;

    case initialBundleCart.type:
      return INITIAL_STATE_ITEMS;

    default:
      return state;
  }
};

/** @type {{bundleId: number}}>} */
const INITIAL_STATE_INFO = {};

export const info = (
  state = INITIAL_STATE_INFO,
  { type, payload: bundleId },
) => {
  switch (type) {
    case initialBundleCart.type:
      return {
        bundleId,
      };

    default:
      return state;
  }
};

export default combineReducers({
  info,
  items,
});
