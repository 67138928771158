import config from 'config';

const { SNAPASK_SITE_ENDPOINT, TRACKING_API_URL } = config;

// ! NOTICE: please define the API endpoint start with /

export const apiAppLaunch = (fetchAPI, lang) =>
  fetchAPI('/api/v2/app_launch', {
    method: 'POST',
    body: { lang },
  });

export const apiFetchSiteInfo = fetchAPI =>
  fetchAPI('/api/v3/web/guest/site_info');

export const apiLogout = fetchAPI =>
  fetchAPI('/api/v2/logout', { method: 'POST' });

/* ---------------- home ----------------*/

export const apiFetchBannerList = (
  fetchAPI,
  {
    region,
    limit,
    // The same presets as the API
    sectionName = 'home',
    gradeLevelId,
  },
) =>
  fetchAPI(`/api/v4/regions/${region}/banners`, {
    params: {
      limit,
      section_name: sectionName,
      grade_level_id: gradeLevelId,
    },
  });

export const apiFetchHomeRevampBannerList = (fetchAPI, { region }) =>
  fetchAPI(`/api/v3/web/hero_sections`, {
    headers: {
      'Region-Name': region,
    },
  });

export const apiFetchAcademyHomePageInfo = (fetchAPI, { region }) =>
  fetchAPI(`/contents/api/browsing/v1/pages/home_${region}`);

export const apiFetchAcademyList = (fetchAPI, { homePageId }) =>
  fetchAPI(`/contents/api/browsing/v1/specific_posts`, {
    params: {
      query_type: 'latest',
      page_id: homePageId,
    },
  });

/* ---------------- account setup ----------------*/

export const apiFetchSchoolTypeList = fetchAPI =>
  fetchAPI('/api/v2/school_types');

export const apiFetchSubjectList = (fetchAPI, { gradeLevelId }) =>
  fetchAPI('/api/v4/students/subject_categories', {
    params: {
      grade_level_id: gradeLevelId,
    },
  });

export const apiUpdateUserAttributeData = (fetchAPI, { userId, data }) =>
  fetchAPI(`/api/v2/users/${userId}/update_user_attributes`, {
    method: 'PATCH',
    body: data,
  });

export const apiSendPhoneVerificationCode = (
  fetchAPI,
  { countryCode, phoneNumber },
) =>
  fetchAPI(`/api/v3/users/phone/send_verification`, {
    method: 'POST',
    params: {
      country_code: countryCode,
      phone_number: phoneNumber,
    },
  });

export const apiVerifyPhone = (fetchAPI, { verificationCode, userId }) =>
  fetchAPI(`/api/v2/users/${userId}/verify_phone`, {
    method: 'POST',
    params: {
      phone_code: verificationCode,
    },
  });

export const apiResentPhoneVerificationCode = (fetchAPI, { userId }) =>
  fetchAPI(`/api/v2/users/${userId}/resend_phone_verification`, {
    method: 'POST',
  });

/* ---------------- payment ----------------*/
export const apiFetchLiveTopicCheckoutCollection = (fetchAPI, { topicId }) =>
  fetchAPI(`/api/v4/live_topics/${topicId}/checkout_collection`);

export const apiFetchCheckoutCollectionList = (
  fetchAPI,
  { contentType, contentId },
) =>
  fetchAPI('/api/v4/checkout_collections', {
    params: {
      content_type: contentType,
      content_id: contentId,
    },
  });

export const apiFetchCheckoutCollectionWithContent = (fetchAPI, collectionId) =>
  fetchAPI(`/api/v5/checkout_collections/${collectionId}`);

export const apiFetchBundleCheckoutCollectionList = (fetchAPI, collectionId) =>
  fetchAPI(`/api/v4/checkout_collections/${collectionId}/bundle`);

/* ---------------- course ----------------*/
export const apiFetchCourse = (fetchAPI, courseId) =>
  fetchAPI(`/api/v4/courses/${courseId}`);

export const apiFetchCourseAnnouncements = (
  fetchAPI,
  { courseId, currentPage, pageSize = 3 },
) =>
  fetchAPI(`/api/v3/courses/${courseId}/announcements`, {
    params: {
      page: currentPage,
      size_per_page: pageSize,
    },
  });

export const apiFetchCourseHomeLessons = (
  fetchAPI,
  { region, sizePerPage, page, subjectId, gradeLevelId },
) => {
  return fetchAPI(`/api/v3/regions/${region}/courses`, {
    params: {
      page,
      size_per_page: sizePerPage,
      ...(gradeLevelId && { grade_level_id: gradeLevelId }),
      ...(subjectId && { 'subject_ids[]': subjectId }),
    },
  });
};
export const apiFetchLearningCourse = (
  fetchAPI,
  { sizePerPage, page, status },
) =>
  fetchAPI(`/api/v3/students/courses/learning_progress`, {
    params: {
      page,
      size_per_page: sizePerPage,
      status,
    },
  });

export const apiFetchUserPurchasedCourse = (fetchAPI, { sizePerPage, page }) =>
  fetchAPI(`/api/v4/students/courses`, {
    params: {
      page,
      size_per_page: sizePerPage,
    },
  });

export const apiPostCourseRating = (
  fetchAPI,
  { courseId, lessonId, stars, comment },
) =>
  fetchAPI(`/api/v3/students/courses/${courseId}/rating`, {
    method: 'POST',
    params: {
      lesson_id: lessonId,
      stars,
      comment,
    },
  });

export const apiPostLiveLessonRating = (
  fetchAPI,
  { lessonId, stars, comment },
) =>
  fetchAPI(`/api/v3/students/live_lessons/${lessonId}/rating`, {
    method: 'POST',
    params: {
      stars,
      comment,
    },
  });

export const apiPostLiveTopicRating = (fetchAPI, { topicId, stars, comment }) =>
  fetchAPI(`/api/v3/students/live_topics/${topicId}/rating`, {
    method: 'POST',
    params: {
      stars,
      comment,
    },
  });

export const apiUpdateCourseProgress = (
  fetchAPI,
  { courseId, lessonId, progressDuration },
) =>
  fetchAPI(
    `/api/v3/students/courses/${courseId}/lesson_progresses/${lessonId}`,
    {
      method: 'PUT',
      params: {
        progress_duration: progressDuration,
      },
    },
  );

export const apiUpdateLiveLessonProgress = (
  fetchAPI,
  { lessonId, progressDuration },
) =>
  fetchAPI(`/api/v3/students/live_lessons/${lessonId}/lesson_progresses`, {
    method: 'PUT',
    params: {
      progress_duration: progressDuration,
    },
  });

export const apiFetchCourseList = (
  fetchAPI,
  { currentPage, pageSize, selectedGradeLevel, selectedSubject } = {},
) =>
  fetchAPI('/api/v3/courses', {
    params: {
      page: currentPage,
      size_per_page: pageSize,
      ...(selectedGradeLevel && { grade_level_id: selectedGradeLevel }),
      ...(selectedSubject && { 'subject_ids[]': selectedSubject }),
    },
  });

export const apiFetchFreeCourseList = (
  fetchAPI,
  { currentPage, pageSize } = {},
) =>
  fetchAPI('/api/v3/courses/free_recommend', {
    params: {
      page: currentPage,
      size_per_page: pageSize,
    },
  });

export const apiFetchCourseGroupsCourseList = (
  fetchAPI,
  { groupId, currentPage, pageSize, gradeLevelId, subjectId } = {},
) =>
  fetchAPI(`/api/v3/course_groups/${groupId}/courses`, {
    params: {
      page: currentPage,
      size_per_page: pageSize,
      ...(gradeLevelId && { 'grade_level_ids[]': gradeLevelId }),
      ...(subjectId && { 'subject_ids[]': subjectId }),
    },
  });

export const apiFetchGroupCourseList = (
  fetchAPI,
  { groupId, currentPage, pageSize, gradeLevelId, subjectId } = {},
) =>
  fetchAPI(`/api/v3/course_groups/${groupId}`, {
    params: {
      page: currentPage,
      size_per_page: pageSize,
      grade_level_id: gradeLevelId,
      ...(subjectId && { 'subject_ids[]': subjectId }),
    },
  });

export const apiFetchCoursePromotion = fetchAPI =>
  fetchAPI('/api/v3/web/course_promotion');

export const fetchContentTypeGradeLevels = (
  fetchAPI,
  { region, contentType },
) =>
  fetchAPI(`/api/v5/regions/${region}/grade_levels`, {
    params: { content_type: contentType },
  });

/* ---------------- live ----------------*/
export const apiFetchLiveGradeLevels = (fetchAPI, region) =>
  fetchAPI(`/api/v3/regions/${region}/live_grade_levels`);

export const apiFetchSubjects = (fetchAPI, { contentType, gradeLevelId }) =>
  fetchAPI(`/api/v4/subjects`, {
    params: {
      grade_level_id: gradeLevelId,
      content_type: contentType,
    },
  });

export const apiFetchPromotionInfo = fetchAPI =>
  fetchAPI('/api/v4/web/live_promotion');

export const apiFetchLiveHomeLessons = (fetchAPI, region, limit) =>
  fetchAPI(`/api/v3/regions/${region}/home/live_lessons`, {
    params: {
      limit,
    },
  });

export const apiFetchLiveTopics = (
  fetchAPI,
  { region, sizePerPage, gradeLevelId, subjectId, limit, page },
) =>
  fetchAPI(`/api/v3/regions/${region}/live_topics`, {
    params: {
      page,
      size_per_page: sizePerPage,
      limit,
      ...(gradeLevelId && { grade_level_id: gradeLevelId }),
      ...(subjectId && { 'subject_ids[]': subjectId }),
    },
  });

export const apiFetchLiveUpcoming = (
  fetchAPI,
  { region, gradeLevelId, subjectId, page, sizePerPage } = {},
) =>
  fetchAPI(`/api/v3/regions/${region}/live_lessons/upcoming`, {
    params: {
      grade_level_id: gradeLevelId,
      page,
      size_per_page: sizePerPage,
      ...(subjectId && { 'subject_ids[]': subjectId }),
    },
  });

export const apiFetchLiveTopic = (fetchAPI, { regionName, topicId }) =>
  fetchAPI(`/api/v4/live_topics/${topicId}`, {
    params: {
      region_name: regionName,
    },
  });

export const apiSaveLiveLesson = (fetchAPI, lessonId) =>
  fetchAPI(`/api/v3/students/live_lessons/${lessonId}/save`, {
    method: 'POST',
  });

export const apiUnSaveLiveLesson = (fetchAPI, lessonId) =>
  fetchAPI(`/api/v3/students/live_lessons/${lessonId}/remove`, {
    method: 'POST',
  });

export const apiFetchScheduleUpcomingLesson = (
  fetchAPI,
  { page, sizePerPage } = {},
) =>
  fetchAPI(`/api/v3/students/regular_classes/schedule/upcoming`, {
    method: 'GET',
    params: {
      page,
      size_per_page: sizePerPage,
    },
  });

export const apiFetchSchedulePastLesson = (
  fetchAPI,
  { page, sizePerPage } = {},
) =>
  fetchAPI(`/api/v3/students/regular_classes/schedule/past`, {
    method: 'GET',
    params: {
      page,
      size_per_page: sizePerPage,
    },
  });

export const apiFetchSubscribedTopic = (
  fetchAPI,
  { status, page, sizePerPage } = {},
) =>
  fetchAPI(`/api/v3/students/regular_classes`, {
    method: 'GET',
    params: {
      status,
      page,
      size_per_page: sizePerPage,
    },
  });

export const apiFetchUpsellBanner = (fetchAPI, { regionName, page }) =>
  fetchAPI(`/api/v3/web/web_marquee/${page}`, {
    params: {
      region_name: regionName,
    },
  });

export const apiFetchInstructorProfile = (fetchAPI, id) =>
  fetchAPI(`/api/v3/instructors/${id}`, {
    method: 'GET',
  });

export const apiFetchInstructorList = (
  fetchAPI,
  { contentType, page, sizePerPage },
) =>
  fetchAPI(`/api/v3/instructors`, {
    method: 'GET',
    params: { content_type: contentType, page, size_per_page: sizePerPage },
  });

export const apiFetchCourseListByInstructor = (fetchAPI, id) =>
  fetchAPI(`/api/v3/instructors/${id}/courses`, {
    method: 'GET',
  });

export const apiFetchLiveTopicListByInstructor = (fetchAPI, id) =>
  fetchAPI(`/api/v3/instructors/${id}/live_topics`, {
    method: 'GET',
  });

// my learning
export const apiFetchMyLearningUpNext = fetchAPI =>
  fetchAPI(`/api/v3/students/contents/up_next`, {
    method: 'GET',
  });

export const apiFetchMyLearningContinueWatching = (
  fetchAPI,
  { page, sizePerPage } = {},
) =>
  fetchAPI(`/api/v3/students/contents/continue_watching`, {
    params: {
      page,
      size_per_page: sizePerPage,
    },
  });

export const apiFetchRecommendCourseLesson = (fetchAPI, { limit } = {}) =>
  fetchAPI(`/api/v3/courses/free`, {
    params: {
      limit,
    },
  });

export const apiFetchRecommendLiveLesson = (fetchAPI, { limit } = {}) =>
  fetchAPI(`/api/v3/live_topics/free`, {
    params: {
      limit,
    },
  });

export const apiSubmitTicketToZendesk = (fetchAPI, data) =>
  fetchAPI(`/submitTicketToZendesk`, {
    method: 'POST',
    body: data,
    bodyNoToSnake: true,
    baseUrl: SNAPASK_SITE_ENDPOINT,
  });

export const apiUploadFileToZendesk = (fetchAPI, file) => {
  const data = new FormData();
  if (file) data.append('file', file);

  return fetchAPI(`/upload`, {
    method: 'POST',
    body: data,
    bodyType: 'formData',
    baseUrl: SNAPASK_SITE_ENDPOINT,
    getResponse: res => res.upload,
  });
};

// tacking system
export const apiSubmitTackingVideoInfo = (
  fetchAPI,
  {
    userId,
    episodeId,
    isAutoPlay,
    isBackground,
    targetType,
    targetId,
    isEpisodeEnrolled,
    progressDuration,
    ...payload
  },
) => {
  return fetchAPI(`/video`, {
    method: 'POST',
    baseUrl: TRACKING_API_URL,
    mode: 'no-cors',
    body: {
      platform: 'web',
      product: 'snapask',
      user_id: userId,
      episode_id: episodeId,
      is_auto_play: isAutoPlay,
      is_background: isBackground,
      target_type: targetType,
      target_id: targetId,
      is_episode_enrolled: isEpisodeEnrolled,
      progress_duration: progressDuration,
      ...payload,
    },
  });
};
