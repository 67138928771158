import { combineReducers } from 'redux';

import { REGULAR_CLASS, COURSE } from 'constants/contentTypes';

import {
  fetchCheckoutCollectionListSuccess,
  fetchLiveTopicMainCheckoutCollectionSuccess,
  fetchCheckoutCollectionWithContentSuccess,
  fetchBundleCheckoutCollectionListSuccess,
  fetchCourseMainCheckoutCollectionSuccess,
} from './action';

/**
 * @typedef {string} ContentType
 */

/**
 * @typedef {number} Id
 */

/**
 * @typedef {Object} CheckoutCollection
 * @property {string} bannerImageUrl
 * @property {string} bannerUrl
 * @property {string} contentExpiredAt
 * @property {string} contentStartAt
 * @property {string} collectionType
 * @property {number} id
 * @property {boolean} isPresaleExpired
 * @property {Object} plan
 * @property {string} presaleExpiredAt
 * @property {number} presalePrice
 * @property {string} presaleStartAt
 * @property {string} title
 * @property {string} description
 */

/**
 * @typedef {Object} CheckoutCollectionGroupByType
 * @property {CheckoutCollection} single
 * @property {Array.<CheckoutCollection>} bundle
 */

/**
 * @typedef {Object} CheckoutCollectionGroup
 * @property {CheckoutCollectionGroupByType} consumable
 * @property {CheckoutCollectionGroupByType} renewable
 * @property {Array.<CheckoutCollection>} prepack
 * @property {CheckoutCollectionGroupByType} nonRenewable
 */

/**
 * @typedef {Object} Content
 * @property {Array.<Course>} courses
 * @property {Array.<LiveTopic>} liveTopics
 */

/** @type {Object.<ContentType, CheckoutCollectionGroup>} */
const INITIAL_STATE_GROUP_LIST = {};

export const groupList = (
  state = INITIAL_STATE_GROUP_LIST,
  { type, payload },
) => {
  switch (type) {
    case fetchCheckoutCollectionListSuccess.type:
      return {
        ...state,
        [payload.contentType]: {
          ...state[payload.contentType],
          [payload.contentId]: payload.groupList,
        },
      };
    default:
      return state;
  }
};

/**
 * @typedef {Object.<ContentId, CheckoutCollection>} CheckoutCollectionById
 */

/** @type {Object.<ContentType, CheckoutCollectionById>} */
const INITIAL_STATE_MAIN_CHECKOUT_COLLECTION = {};

export const main = (
  state = INITIAL_STATE_MAIN_CHECKOUT_COLLECTION,
  { type, payload },
) => {
  switch (type) {
    case fetchLiveTopicMainCheckoutCollectionSuccess.type:
      return {
        ...state,
        [REGULAR_CLASS]: {
          ...state[REGULAR_CLASS],
          [payload.topicId]: payload.checkoutCollection,
        },
      };
    case fetchCourseMainCheckoutCollectionSuccess.type:
      return {
        ...state,
        [COURSE]: {
          ...state[COURSE],
          [payload.courseId]: payload.checkoutCollection,
        },
      };
    default:
      return state;
  }
};

/** @type {Object.<Id, CheckoutCollection>} */
const INITIAL_STATE_BY_ID = {};

export const byId = (state = INITIAL_STATE_BY_ID, { type, payload }) => {
  switch (type) {
    case fetchCheckoutCollectionWithContentSuccess.type:
      return {
        ...state,
        [payload.collectionId]: payload.checkoutCollection,
      };

    default:
      return state;
  }
};

/** @type {Object.<Id, Content>} */
const INITIAL_STATE_CONTENT_LIST = {};

export const contentList = (
  state = INITIAL_STATE_CONTENT_LIST,
  { type, payload },
) => {
  switch (type) {
    case fetchCheckoutCollectionWithContentSuccess.type:
      return {
        ...state,
        [payload.collectionId]: {
          courses: payload.courses,
          liveTopics: payload.liveTopics,
          groupPasses: payload.groupPasses,
        },
      };

    default:
      return state;
  }
};

export const bundleOptionList = (state = {}, { type, payload }) => {
  switch (type) {
    case fetchBundleCheckoutCollectionListSuccess.type:
      return {
        ...state,
        [payload.collectionId]: payload.checkoutCollections,
      };

    default:
      return state;
  }
};

export default combineReducers({
  main,
  groupList,
  byId,
  contentList,
  bundleOptionList,
});
