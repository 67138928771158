import { combineReducers } from 'redux';
import {
  fetchInstructorList,
  fetchInstructorProfileSuccess,
  fetchInstructorListSuccess,
  fetchContentListByInstructorSuccess,
  fetchInstructorListClear,
} from './action';

const INITIAL_INSTRUCTOR_PROFILE = {
  about: [],
  courses: [],
  instructor: { name: '', subjects: [], liveTopicCount: 0, subjectCount: 0 },
  liveTopics: [],
};

const instructorProfile = (
  state = INITIAL_INSTRUCTOR_PROFILE,
  { type, payload },
) => {
  switch (type) {
    case fetchInstructorProfileSuccess.type: {
      return { ...state, ...payload };
    }

    default:
      return state;
  }
};

const INITIAL_INSTRUCTOR_LIST = {
  instructorList: [],
  pageSetting: {
    page: 1,
    sizePerPage: 12,
    totalPages: 0,
  },
};
const instructorList = (state = INITIAL_INSTRUCTOR_LIST, { type, payload }) => {
  switch (type) {
    case fetchInstructorList.type: {
      const currentPage = payload.pageSetting?.page;
      return {
        ...state,
        pageSetting: {
          ...state.pageSetting,
          ...(currentPage && { page: currentPage }),
        },
      };
    }
    case fetchInstructorListSuccess.type: {
      return {
        ...state,
        instructorList: [...state.instructorList, ...payload.instructorList],
        pageSetting: {
          ...state.pageSetting,
          totalPages: payload.totalPages,
        },
      };
    }
    case fetchInstructorListClear.type: {
      return {
        ...INITIAL_INSTRUCTOR_LIST,
      };
    }

    default:
      return state;
  }
};

const INITIAL_CONTENT_LIST = [];
const contentList = (state = INITIAL_CONTENT_LIST, { type, payload }) => {
  switch (type) {
    case fetchContentListByInstructorSuccess.type: {
      return [...payload];
    }

    default:
      return state;
  }
};

export default combineReducers({
  instructorProfile,
  instructorList,
  contentList,
});
