import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import {
  apiFetchHomeRevampBannerList,
  fetchContentTypeGradeLevels,
  apiFetchLiveTopics,
  apiFetchCourseHomeLessons,
  apiFetchCourseGroupsCourseList,
  apiFetchAcademyHomePageInfo,
  apiFetchAcademyList,
} from 'services/apis';
import { getAppRegion } from 'reduxModules/app/selector';
import { getFilterInfo } from './selector';

import { transformRegularClassLessonList } from './transform';
import { transformCourseListItem } from '../course/transform';

import {
  fetchBannerList,
  fetchBannerListSuccess,
  fetchBannerListFailure,
  fetchGradeLevelFilterList,
  fetchGradeLevelFilterListSuccess,
  fetchGradeLevelFilterListFailure,
  fetchRegularClassLessonList,
  fetchRegularClassLessonListSuccess,
  fetchRegularClassLessonListFailure,
  fetchCourseList,
  fetchCourseListSuccess,
  fetchCourseListFailure,
  fetchGroupedCourseList,
  fetchGroupedCourseListSuccess,
  fetchGroupedCourseListFailure,
  fetchAcademyList,
  fetchAcademyListSuccess,
  fetchAcademyListFailure,
  setFilterInfo,
} from './action';

export function* fetchBannerListFlow({ fetchAPI }) {
  const region = yield select(getAppRegion);
  try {
    const { heroSections } = yield call(
      apiFetchHomeRevampBannerList,
      fetchAPI,
      {
        region,
      },
    );

    yield put(fetchBannerListSuccess(heroSections));
  } catch {
    yield put(fetchBannerListFailure());
  }
}

export function* fetchGradeLevelFilterListFlow({ fetchAPI }) {
  const region = yield select(getAppRegion);

  try {
    const { gradeLevels } = yield call(fetchContentTypeGradeLevels, fetchAPI, {
      region,
    });
    yield put(fetchGradeLevelFilterListSuccess({ gradeLevels }));
  } catch {
    yield put(fetchGradeLevelFilterListFailure());
  }
}

// usaully tempGradeLevelId and tempSubjectId are from server side request,
// the setFilterInfo needs to get filter options from API,
// we don't want to waste the time to wait for it thus use the id directly,
// and do setFilterInfo in the client site
export function* fetchRegularClassLessonListFlow({ fetchAPI }, { payload }) {
  const { tempGradeLevelId, tempSubjectId } = payload || {};
  const region = yield select(getAppRegion);
  const {
    selectedSubject: { id: filterSubjectId },
    selectedGradeLevel: { id: filterGradeLevelId },
  } = yield select(getFilterInfo);

  const gradeLevelId = tempGradeLevelId || filterGradeLevelId;
  const subjectId = tempSubjectId || filterSubjectId;

  try {
    const { liveTopics: lessonList, totalPages } = yield call(
      apiFetchLiveTopics,
      fetchAPI,
      {
        page: 1,
        sizePerPage: 4,
        region,
        subjectId,
        gradeLevelId,
      },
    );

    yield put(
      fetchRegularClassLessonListSuccess({
        lessonList: transformRegularClassLessonList(lessonList),
        totalPages,
      }),
    );
  } catch {
    yield put(fetchRegularClassLessonListFailure());
  }
}

export function* fetchCourseListFlow({ fetchAPI }, { payload }) {
  const { tempGradeLevelId, tempSubjectId } = payload || {};
  const region = yield select(getAppRegion);
  const {
    selectedSubject: { id: filterSubjectId },
    selectedGradeLevel: { id: filterGradeLevelId },
  } = yield select(getFilterInfo);

  const gradeLevelId = tempGradeLevelId || filterGradeLevelId;
  const subjectId = tempSubjectId || filterSubjectId;

  try {
    const { courses, leadGroups, totalPages } = yield call(
      apiFetchCourseHomeLessons,
      fetchAPI,
      {
        page: 1,
        sizePerPage: 8,
        region,
        subjectId,
        gradeLevelId,
      },
    );

    yield put(
      fetchCourseListSuccess({
        courseList: courses.map(transformCourseListItem),
        totalPages,
      }),
    );
    yield put(setFilterInfo({ courseGroups: leadGroups }));
  } catch {
    yield put(fetchCourseListFailure());
  }
}

export function* fetchGroupedCourseListFlow({ fetchAPI }, { payload }) {
  const { tempGradeLevelId, tempSubjectId, tempGroupId } = payload || {};
  const {
    selectedSubject: { id: filterSubjectId },
    selectedGradeLevel: { id: filterGradeLevelId },
    selectedCourseGroup: { id: filterGroupId },
  } = yield select(getFilterInfo);

  const gradeLevelId = tempGradeLevelId || filterGradeLevelId;
  const subjectId = tempSubjectId || filterSubjectId;
  const groupId = tempGroupId || filterGroupId;

  try {
    const { courses, totalPages } = yield call(
      apiFetchCourseGroupsCourseList,
      fetchAPI,
      {
        currentPage: 1,
        pageSize: 8,
        groupId,
        subjectId,
        gradeLevelId,
      },
    );

    yield put(
      fetchGroupedCourseListSuccess({
        courseList: courses.map(transformCourseListItem),
        totalPages,
      }),
    );
  } catch {
    yield put(fetchGroupedCourseListFailure());
  }
}

export function* fetchAcademyListFlow({ fetchAPI }) {
  const region = yield select(getAppRegion);
  try {
    const { id: homePageId } = yield call(
      apiFetchAcademyHomePageInfo,
      fetchAPI,
      {
        region,
      },
    );

    const postList = yield call(apiFetchAcademyList, fetchAPI, {
      homePageId,
    });

    yield put(fetchAcademyListSuccess(postList));
  } catch {
    yield put(fetchAcademyListFailure());
  }
}

export default function*(helpers) {
  yield all([
    takeLatest(fetchBannerList.type, fetchBannerListFlow, helpers),
    takeLatest(
      fetchGradeLevelFilterList.type,
      fetchGradeLevelFilterListFlow,
      helpers,
    ),
    takeLatest(
      fetchRegularClassLessonList.type,
      fetchRegularClassLessonListFlow,
      helpers,
    ),
    takeLatest(fetchCourseList.type, fetchCourseListFlow, helpers),
    takeLatest(
      fetchGroupedCourseList.type,
      fetchGroupedCourseListFlow,
      helpers,
    ),
    takeLatest(fetchAcademyList.type, fetchAcademyListFlow, helpers),
  ]);
}
