import { combineReducers } from 'redux';
import { MY_ONGOING_CLASS, MY_ENDED_CLASS } from 'constants/liveEventSections';
import {
  fetchLiveFilterInfoSuccess,
  fetchPromotionInfoSuccess,
  fetchHomeLessonListSuccess,
  fetchLiveUpcoming,
  fetchLiveUpcomingSuccess,
  fetchLiveUpcomingClear,
  setLiveFilterInfo,
  openLiveModal,
  closeLiveModal,
  fetchScheduleUpcomingLesson,
  fetchScheduleUpcomingLessonSuccess,
  fetchSchedulePastLesson,
  fetchSchedulePastLessonSuccess,
  fetchSubscribedPreOrderedTopic,
  fetchSubscribedPreOrderedTopicSuccess,
  fetchSubscribedOngoingTopic,
  fetchSubscribedOngoingTopicSuccess,
  fetchSubscribedCompletedTopic,
  fetchSubscribedCompletedTopicSuccess,
  removeUpcomingScheduleLessonSuccess,
  postLiveTopicRatingSuccess,
  fetchSubscribedTopicSuccess,
} from '../action';

import liveTopic from './liveTopic';
import liveTopics from './liveTopics';

import { mergeLiveLessonByDate } from '../transform';

/**
 * @typedef {Object} SelectedFilterOption
 *  @property {string} name
 *  @property {number} id
 */

/**
 * @type {array} gradeLevels
 * @type {array} subjects
 * @type {SelectedFilterOption}  selectedGradeLevel
 * @type {SelectedFilterOption}  selectedLiveSubject
 */

const INITIAL_FILTER_INFO = {
  gradeLevels: [],
  subjects: [],
  selectedGradeLevel: 0,
  selectedSubject: 0,
};

export const liveFilterInfo = (
  state = INITIAL_FILTER_INFO,
  { type, payload },
) => {
  switch (type) {
    case fetchLiveFilterInfoSuccess.type:
      return {
        ...state,
        ...payload,
      };
    case setLiveFilterInfo.type:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
};

/**
 * @typedef {Object} LivePromotionMedia
 * @property {string} type
 * @property {string} url
 *
 * @typedef {Object} UserLiveSubscription
 * @property {string} id
 * @property {string} accessType
 * @property {array} paymentMethod
 *
 * */

/**
 * @type{{
 *  livePromotionMedia: LivePromotionMedia,
 *  userLiveSubscription: UserLiveSubscription,
 * }}
 */
const INITIAL_PROMOTION = {
  livePromotionMedia: {},
  userLiveSubscription: {},
};

export const livePromotion = (state = INITIAL_PROMOTION, { type, payload }) => {
  switch (type) {
    case fetchPromotionInfoSuccess.type: {
      return { ...state, ...payload };
    }

    default:
      return state;
  }
};

const INITIAL_MODAL = {
  modalName: '',
};

export const livePromotionModal = (
  state = INITIAL_MODAL,
  { type, payload },
) => {
  switch (type) {
    case openLiveModal.type: {
      return { ...state, ...payload };
    }
    case closeLiveModal.type: {
      return INITIAL_MODAL;
    }
    default:
      return state;
  }
};

const INITIAL_LIVE_HOME_LESSON = {
  homeLessons: [],
  homeTopics: [],
};
export const liveHomeLesson = (
  state = INITIAL_LIVE_HOME_LESSON,
  { type, payload },
) => {
  switch (type) {
    case fetchHomeLessonListSuccess.type: {
      return { ...state, ...payload };
    }

    default:
      return state;
  }
};

/**
 * @typedef ISODate
 * @type {string}
 */

/**
 * live lessons group by ISO local time strings
 * @typedef LiveLessonByDate
 * @type {Object.<ISODate, Object>}
 */

/**
 * @type {{
 *   liveLessonByDate: LiveLessonByDate
 *   allDates: ISODate[]
 * }}
 */

const INIT_UPCOMING_LESSONS = {
  liveLessonByDate: {},
  allDates: [],
  totalPages: 0,
  page: 1,
  sizePerPage: 15,
};
export const liveUpcomingLessons = (
  state = INIT_UPCOMING_LESSONS,
  { type, payload },
) => {
  switch (type) {
    case fetchLiveUpcoming.type: {
      return {
        ...state,
        ...(payload?.page && { page: payload.page }),
      };
    }
    case fetchLiveUpcomingSuccess.type: {
      const upcomingLessons = state.liveLessonByDate;
      const upcomingDates = state.allDates;
      const newUpcomingLessons = payload.liveLessons.liveLessonByDate;
      const newAllDates = payload.liveLessons.allDates;
      return {
        ...state,
        totalPages: payload.totalPages,
        allDates: Array.from(new Set([...upcomingDates, ...newAllDates])),
        liveLessonByDate: mergeLiveLessonByDate(
          upcomingLessons,
          newUpcomingLessons,
        ),
      };
    }

    case fetchLiveUpcomingClear.type: {
      return INIT_UPCOMING_LESSONS;
    }

    default:
      return state;
  }
};

/**
 * @typedef {Object} DateLessons
 * @property {Object.<ISODate, Object>} allDates
 * @property {ISODate[]} liveLessonByDate
 */

/**
 * @type {{
 *  past: DateLessons
 *  upcoming: DateLessons
 *  page: number
 *  sizePerPage: number
 *  totalPages: number
 * }}
 */

const INITIAL_LIVE_USER_SCHEDULE = {
  upcoming: {
    liveLessonByDate: {},
    allDates: [],
    allLiveLessons: [],
    totalPages: 0,
    page: 1,
    sizePerPage: 15,
  },
  past: {
    liveLessonByDate: {},
    allDates: [],
    allLiveLessons: [],
    totalPages: 0,
    page: 1,
    sizePerPage: 15,
  },
};

export const liveUserSchedule = (
  state = INITIAL_LIVE_USER_SCHEDULE,
  { type, payload },
) => {
  const createScheduleSuccessReducer = name => {
    const upcomingLessons = state[name].liveLessonByDate;
    const upcomingDates = state[name].allDates;
    const newUpcomingLessons = payload.liveLessons.liveLessonByDate;
    const newAllDates = payload.liveLessons.allDates;
    const newAllLiveLessons = payload.liveLessons.allLiveLessons;

    return {
      ...state,
      [name]: {
        ...state[name],
        allDates: Array.from(new Set([...upcomingDates, ...newAllDates])),
        liveLessonByDate: mergeLiveLessonByDate(
          upcomingLessons,
          newUpcomingLessons,
        ),
        allLiveLessons: newAllLiveLessons,
        totalPages: payload.totalPages,
      },
    };
  };

  switch (type) {
    case fetchScheduleUpcomingLesson.type: {
      return {
        ...state,
        upcoming: {
          ...state.upcoming,
          ...(payload?.page && { page: payload.page }),
          ...(payload?.sizePerPage && { sizePerPage: payload.sizePerPage }),
        },
      };
    }

    case fetchSchedulePastLesson.type: {
      return {
        ...state,
        past: {
          ...state.past,
          ...(payload?.page && { page: payload.page }),
          ...(payload?.sizePerPage && { sizePerPage: payload.sizePerPage }),
        },
      };
    }

    case fetchScheduleUpcomingLessonSuccess.type: {
      return createScheduleSuccessReducer('upcoming');
    }

    case fetchSchedulePastLessonSuccess.type: {
      return createScheduleSuccessReducer('past');
    }

    case removeUpcomingScheduleLessonSuccess.type: {
      const upcomingLessons = state.upcoming.liveLessonByDate;
      const upcomingDates = state.upcoming.allDates;
      const targetDate = payload.date;
      const targetId = payload.id;

      const newUpcomingLessons = {
        ...upcomingLessons,
        [targetDate]: upcomingLessons[targetDate].filter(
          lesson => lesson.id !== targetId,
        ),
      };
      const newUpcomingDate = upcomingDates.filter(
        date => newUpcomingLessons[date].length !== 0,
      );

      return {
        ...state,
        upcoming: {
          ...state.upcoming,
          allDates: newUpcomingDate,
          liveLessonByDate: newUpcomingLessons,
        },
      };
    }
    default:
      return state;
  }
};

/**
  @typedef {Object} PageSetting
  @property {number} page
  @property {number} sizePerPage
  @property {number} totalPages 
*/

/**
  @typedef {Object} Instructor
  @property {string} name
  @property {string} avatarUrl 
  @property {string} description 
*/

/**
  @typedef {Object} UserSubscribeTopics
  @property {number} id
  @property {string} name
  @property {Instructor[]} instructors
  @property {?string} upcomingLessonStartAt
 */

/**   
  @type {{
   ongoing.topics: UserSubscribeTopics[]
   ongoing.pageSetting: PageSetting 
   completed.topics: UserSubscribeTopics[]
   completed.pageSetting: PageSetting
  }}  
 */

export const INITIAL_LIVE_USER_SUBSCRIBE = {
  all: {
    pageSetting: {
      page: 1,
      sizePerPage: 6,
      totalPages: 0,
    },
    topics: [],
  },
  preOrdered: {
    pageSetting: {
      page: 1,
      sizePerPage: 6,
      totalPages: 0,
    },
    topics: [],
  },
  ongoing: {
    pageSetting: {
      page: 1,
      sizePerPage: 6,
      totalPages: 0,
    },
    topics: [],
  },
  completed: {
    pageSetting: {
      page: 1,
      sizePerPage: 6,
      totalPages: 0,
    },
    topics: [],
  },
};

export const liveUserSubscribe = (
  state = INITIAL_LIVE_USER_SUBSCRIBE,
  { type, payload },
) => {
  switch (type) {
    case fetchSubscribedPreOrderedTopic.type: {
      return {
        ...state,
        preOrdered: {
          ...state.preOrdered,
          pageSetting: {
            ...state.preOrdered.pageSetting,
            ...(payload?.page && {
              page: payload.page,
            }),
            ...(payload?.sizePerPage && {
              sizePerPage: payload.sizePerPage,
            }),
          },
        },
      };
    }
    case fetchSubscribedOngoingTopic.type: {
      return {
        ...state,
        ongoing: {
          ...state.ongoing,
          pageSetting: {
            ...state.ongoing.pageSetting,
            ...(payload?.page && {
              page: payload.page,
            }),
            ...(payload?.sizePerPage && {
              sizePerPage: payload.sizePerPage,
            }),
          },
        },
      };
    }

    case fetchSubscribedCompletedTopic.type:
      return {
        ...state,
        completed: {
          ...state.completed,
          pageSetting: {
            ...state.completed.pageSetting,
            ...(payload?.page && { page: payload.page }),
            ...(payload?.sizePerPage && { sizePerPage: payload.sizePerPage }),
          },
        },
      };

    case fetchSubscribedTopicSuccess.type:
      return {
        ...state,
        all: {
          ...state.all,
          topics: payload.topics,
          pageSetting: {
            ...state.all.pageSetting,
            totalPages: payload.totalPages,
          },
        },
      };

    case fetchSubscribedPreOrderedTopicSuccess.type: {
      return {
        ...state,
        preOrdered: {
          topics: [...state.preOrdered.topics, ...payload.liveTopics],
          pageSetting: {
            ...state.preOrdered.pageSetting,
            totalPages: payload.totalPages,
          },
        },
      };
    }
    case fetchSubscribedOngoingTopicSuccess.type: {
      return {
        ...state,
        ongoing: {
          topics: [...state.ongoing.topics, ...payload.liveTopics],
          pageSetting: {
            ...state.ongoing.pageSetting,
            totalPages: payload.totalPages,
          },
        },
      };
    }

    case fetchSubscribedCompletedTopicSuccess.type: {
      return {
        ...state,
        completed: {
          topics: [...state.completed.topics, ...payload.liveTopics],
          pageSetting: {
            ...state.completed.pageSetting,
            totalPages: payload.totalPages,
          },
        },
      };
    }

    case postLiveTopicRatingSuccess.type: {
      const { topicId, section } = payload;
      const sectionKeyMapping = {
        [MY_ONGOING_CLASS]: 'ongoing',
        [MY_ENDED_CLASS]: 'completed',
      };

      const subscribeSectionKey = sectionKeyMapping[section];

      if (!subscribeSectionKey) return state;

      return {
        ...state,
        [subscribeSectionKey]: {
          ...state[subscribeSectionKey],
          topics: state[subscribeSectionKey].topics.reduce((acc, topic) => {
            const updatedTopic =
              topicId === topic.id ? { ...topic, isRated: true } : topic;

            acc.push(updatedTopic);
            return acc;
          }, []),
        },
      };
    }
    default:
      return state;
  }
};

export default combineReducers({
  livePromotionModal,
  liveFilterInfo,
  livePromotion,
  liveHomeLesson,
  liveUpcomingLessons,
  liveTopic,
  liveTopics,
  liveUserSchedule,
  liveUserSubscribe,
});
