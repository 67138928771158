import { all, call, takeLatest, select, put } from 'redux-saga/effects';

import {
  apiFetchLiveGradeLevels,
  // apiFetchSubjects,
  apiFetchBannerList,
  apiFetchPromotionInfo,
  apiFetchLiveUpcoming,
  apiFetchLiveTopic,
  apiFetchLiveTopics,
  apiSaveLiveLesson,
  apiUnSaveLiveLesson,
  apiFetchScheduleUpcomingLesson,
  apiFetchSchedulePastLesson,
  apiUpdateLiveLessonProgress,
  apiPostLiveLessonRating,
  apiPostLiveTopicRating,
  apiFetchSubscribedTopic,
} from 'services/apis';
import { getAppRegion } from 'reduxModules/app/selector';
import {
  getLiveFilterInfo,
  getLiveTopicsPageSetting,
  getLiveUpcomingLessonsPageSetting,
  getLiveUserScheduleUpcomingPageSetting,
  getLiveUserSchedulePastPageSetting,
  getLiveUserSubscribedOngoingPageSetting,
  getLiveUserSubscribedCompletedPageSetting,
  getLiveUserSubscribedPageSetting,
  getLiveUserSubscribedPreOrderedPageSetting,
} from 'reduxModules/live/selector';

import {
  transformGradeLevels,
  // transformSubjects,
  transformLiveLessonByDate,
  transformPromotion,
  transformLiveTopic,
  transformTopics,
  transformSubscribedTopics,
} from './transform';

import {
  fetchLiveFilterInfo,
  fetchLiveFilterInfoSuccess,
  fetchLiveFilterInfoFailure,
  fetchPromotionInfo,
  fetchPromotionInfoSuccess,
  fetchPromotionInfoFailure,
  fetchHomeLessonList,
  fetchHomeLessonListSuccess,
  fetchHomeLessonListFailure,
  fetchLiveUpcoming,
  fetchLiveUpcomingSuccess,
  fetchLiveUpcomingFailure,
  fetchLiveTopic,
  fetchLiveTopicSuccess,
  fetchLiveTopicFailure,
  fetchLiveTopics,
  fetchLiveTopicsSuccess,
  fetchLiveTopicsFailure,
  fetchRcBanners,
  fetchRcBannersSuccess,
  fetchRcBannersFailure,
  saveLiveLesson,
  saveLiveLessonSuccess,
  saveLiveLessonFailure,
  unSaveLiveLesson,
  unSaveLiveLessonSuccess,
  unSaveLiveLessonFailure,
  fetchScheduleUpcomingLesson,
  fetchScheduleUpcomingLessonSuccess,
  fetchScheduleUpcomingLessonFailure,
  fetchSchedulePastLesson,
  fetchSchedulePastLessonSuccess,
  fetchSchedulePastLessonFailure,
  fetchSubscribedPreOrderedTopic,
  fetchSubscribedPreOrderedTopicSuccess,
  fetchSubscribedPreOrderedTopicFailure,
  fetchSubscribedOngoingTopic,
  fetchSubscribedOngoingTopicSuccess,
  fetchSubscribedOngoingTopicFailure,
  fetchSubscribedCompletedTopic,
  fetchSubscribedCompletedTopicSuccess,
  fetchSubscribedCompletedTopicFailure,
  removeUpcomingScheduleLesson,
  removeUpcomingScheduleLessonSuccess,
  removeUpcomingScheduleLessonFailure,
  updateLiveLessonProgress,
  updateLiveLessonProgressSuccess,
  updateLiveLessonProgressFailure,
  postLiveLessonRating,
  postLiveLessonRatingSuccess,
  postLiveLessonRatingFailure,
  postLiveTopicRating,
  postLiveTopicRatingSuccess,
  postLiveTopicRatingFailure,
  fetchSubscribedTopicSuccess,
  fetchSubscribedTopicFailure,
  fetchSubscribedTopic,
} from './action';
import { COMPLETED, ONGOING, PREORDER } from 'constants/learningCourseStatus';

export function* fetchLiveFilterInfoFlow({ fetchAPI }) {
  try {
    const region = yield select(getAppRegion);
    const [
      liveGradeLevels,
      // { subjects }
    ] = yield all([
      call(apiFetchLiveGradeLevels, fetchAPI, region),
      // call(apiFetchSubjects, fetchAPI, {
      //   contentType: 'live_topic',
      //   gradeLevelId
      // }),
    ]);

    yield put(
      fetchLiveFilterInfoSuccess({
        gradeLevels: transformGradeLevels(liveGradeLevels),
        // subjects: transformSubjects(subjects),
      }),
    );
  } catch (err) {
    yield put(fetchLiveFilterInfoFailure());
  }
}

export function* fetchPromotionInfoFlow({ fetchAPI }) {
  try {
    const data = yield call(apiFetchPromotionInfo, fetchAPI);
    yield put(fetchPromotionInfoSuccess(transformPromotion(data)));
  } catch (error) {
    yield put(fetchPromotionInfoFailure());
  }
}

export function* fetchHomeLessonListFlow({ fetchAPI }) {
  const region = yield select(getAppRegion);
  const HOME_TOPIC_SIZE_PER_PAGE = 8;
  try {
    // TODO: simplify this code to single request
    const [{ liveTopics }] = yield all([
      // call(apiFetchLiveHomeLessons, fetchAPI, region),
      call(apiFetchLiveTopics, fetchAPI, {
        region,
        sizePerPage: HOME_TOPIC_SIZE_PER_PAGE,
      }),
    ]);
    yield put(
      fetchHomeLessonListSuccess({
        homeTopics: transformTopics(liveTopics),
      }),
    );
  } catch (err) {
    yield put(fetchHomeLessonListFailure(err));
  }
}

export function* fetchLiveUpcomingFlow({ fetchAPI }) {
  const { page, sizePerPage } = yield select(getLiveUpcomingLessonsPageSetting);
  try {
    const {
      selectedSubject: subjectId,
      selectedGradeLevel: gradeLevelId,
    } = yield select(getLiveFilterInfo);

    const region = yield select(getAppRegion);

    const { totalPages, liveLessons } = yield call(
      apiFetchLiveUpcoming,
      fetchAPI,
      {
        region,
        gradeLevelId,
        subjectId,
        page,
        sizePerPage,
      },
    );
    yield put(
      fetchLiveUpcomingSuccess({
        liveLessons: transformLiveLessonByDate({ lessonList: liveLessons }),
        totalPages,
      }),
    );
  } catch (err) {
    yield put(fetchLiveUpcomingFailure(err));
  }
}

export function* fetchLiveTopicFlow({ fetchAPI }, { payload: topicId }) {
  try {
    const region = yield select(getAppRegion);
    const topicData = yield call(apiFetchLiveTopic, fetchAPI, {
      regionName: region,
      topicId,
    });

    yield put(fetchLiveTopicSuccess(transformLiveTopic(topicData)));
  } catch (err) {
    yield put(fetchLiveTopicFailure());
  }
}

export function* fetchLiveTopicsFlow({ fetchAPI }, { payload }) {
  const { tempGradeLevelId, tempSubjectId } = payload || {};
  const { page, sizePerPage } = yield select(getLiveTopicsPageSetting);

  try {
    const {
      selectedSubject: filterSubjectId,
      selectedGradeLevel: filterGradeLevelId,
    } = yield select(getLiveFilterInfo);
    const region = yield select(getAppRegion);
    const data = { region, sizePerPage, page };
    const gradeLevelId = tempGradeLevelId || filterGradeLevelId;
    const subjectId = tempSubjectId || filterSubjectId;

    if (subjectId !== 0) {
      data.subjectId = subjectId;
    }
    if (gradeLevelId !== 0) {
      data.gradeLevelId = gradeLevelId;
    }

    const { liveTopics, currentPage, totalPages } = yield call(
      apiFetchLiveTopics,
      fetchAPI,
      data,
    );

    yield put(
      fetchLiveTopicsSuccess({
        liveTopics: transformTopics(liveTopics),
        page: currentPage,
        totalPages,
      }),
    );
  } catch (err) {
    yield put(fetchLiveTopicsFailure());
  }
}

export function* saveLiveLessonFlow({ fetchAPI }, { payload: lessonId }) {
  try {
    yield call(apiSaveLiveLesson, fetchAPI, lessonId);

    yield put(saveLiveLessonSuccess());
  } catch (err) {
    yield put(saveLiveLessonFailure());
  }
}

export function* unSaveLiveLessonFlow({ fetchAPI }, { payload: lessonId }) {
  try {
    yield call(apiUnSaveLiveLesson, fetchAPI, lessonId);

    yield put(unSaveLiveLessonSuccess());
  } catch (err) {
    yield put(unSaveLiveLessonFailure());
  }
}

export function* updateLiveLessonProgressFlow({ fetchAPI }, { payload }) {
  const { progressDuration, lessonId } = payload;

  try {
    yield call(apiUpdateLiveLessonProgress, fetchAPI, {
      lessonId,
      progressDuration,
    });

    yield put(updateLiveLessonProgressSuccess());
  } catch (err) {
    yield put(updateLiveLessonProgressFailure());
  }
}

export function* fetchScheduleUpcomingLessonFlow({ fetchAPI }) {
  try {
    const { page, sizePerPage } = yield select(
      getLiveUserScheduleUpcomingPageSetting,
    );

    const { liveLessons, totalPages } = yield call(
      apiFetchScheduleUpcomingLesson,
      fetchAPI,
      {
        page,
        sizePerPage,
      },
    );

    const transformedLessons = transformLiveLessonByDate({
      lessonList: liveLessons,
    });
    yield put(
      fetchScheduleUpcomingLessonSuccess({
        liveLessons: transformedLessons,
        totalPages,
      }),
    );
  } catch (err) {
    yield put(fetchScheduleUpcomingLessonFailure());
  }
}

export function* fetchSchedulePastLessonFlow({ fetchAPI }) {
  try {
    const { page, sizePerPage } = yield select(
      getLiveUserSchedulePastPageSetting,
    );
    const { liveLessons, totalPages } = yield call(
      apiFetchSchedulePastLesson,
      fetchAPI,
      { page, sizePerPage },
    );

    const transformedLessons = transformLiveLessonByDate({
      lessonList: liveLessons,
    });

    yield put(
      fetchSchedulePastLessonSuccess({
        liveLessons: transformedLessons,
        totalPages,
      }),
    );
  } catch (err) {
    yield put(fetchSchedulePastLessonFailure());
  }
}

export function* fetchSubscribedTopicFlow({ fetchAPI }) {
  try {
    const { page, sizePerPage } = yield select(
      getLiveUserSubscribedPageSetting,
    );

    const { liveTopics, totalPages } = yield call(
      apiFetchSubscribedTopic,
      fetchAPI,
      {
        page,
        sizePerPage,
      },
    );

    yield put(
      fetchSubscribedTopicSuccess({
        topics: transformSubscribedTopics(liveTopics),
        totalPages,
      }),
    );
  } catch (err) {
    yield put(fetchSubscribedTopicFailure());
  }
}

export function* fetchSubscribedPreOrderedTopicFlow({ fetchAPI }) {
  try {
    const { page, sizePerPage } = yield select(
      getLiveUserSubscribedPreOrderedPageSetting,
    );

    const { liveTopics, totalPages } = yield call(
      apiFetchSubscribedTopic,
      fetchAPI,
      {
        status: PREORDER,
        page,
        sizePerPage,
      },
    );

    yield put(
      fetchSubscribedPreOrderedTopicSuccess({
        liveTopics: transformSubscribedTopics(liveTopics),
        totalPages,
      }),
    );
  } catch (err) {
    yield put(fetchSubscribedPreOrderedTopicFailure());
  }
}

export function* fetchSubscribedOngoingTopicFlow({ fetchAPI }) {
  try {
    const { page, sizePerPage } = yield select(
      getLiveUserSubscribedOngoingPageSetting,
    );

    const { liveTopics, totalPages } = yield call(
      apiFetchSubscribedTopic,
      fetchAPI,
      {
        status: ONGOING,
        page,
        sizePerPage,
      },
    );

    yield put(
      fetchSubscribedOngoingTopicSuccess({
        liveTopics: transformSubscribedTopics(liveTopics),
        totalPages,
      }),
    );
  } catch (err) {
    yield put(fetchSubscribedOngoingTopicFailure());
  }
}

export function* fetchSubscribedCompletedTopicFlow({ fetchAPI }) {
  try {
    const { page, sizePerPage } = yield select(
      getLiveUserSubscribedCompletedPageSetting,
    );

    const { liveTopics, totalPages } = yield call(
      apiFetchSubscribedTopic,
      fetchAPI,
      {
        status: COMPLETED,
        page,
        sizePerPage,
      },
    );

    yield put(
      fetchSubscribedCompletedTopicSuccess({
        liveTopics: transformSubscribedTopics(liveTopics),
        totalPages,
      }),
    );
  } catch (err) {
    yield put(fetchSubscribedCompletedTopicFailure());
  }
}

export function* removeUpcomingScheduleLessonFlow(
  { fetchAPI },
  { payload: { id, date } },
) {
  try {
    yield call(apiUnSaveLiveLesson, fetchAPI, id);

    yield put(removeUpcomingScheduleLessonSuccess({ id, date }));
  } catch (err) {
    yield put(removeUpcomingScheduleLessonFailure());
  }
}

export function* postLiveLessonRatingFlow({ fetchAPI }, { payload }) {
  const { lessonId, stars, comment } = payload;
  try {
    yield call(apiPostLiveLessonRating, fetchAPI, {
      lessonId,
      stars,
      comment,
    });
    yield put(postLiveLessonRatingSuccess({ lessonId }));
  } catch (error) {
    yield put(postLiveLessonRatingFailure());
  }
}

export function* postLiveTopicRatingFlow({ fetchAPI }, { payload }) {
  const { topicId, stars, comment, section } = payload;

  try {
    yield call(apiPostLiveTopicRating, fetchAPI, {
      topicId,
      stars,
      comment,
    });
    yield put(postLiveTopicRatingSuccess({ topicId, section }));
  } catch (error) {
    yield put(postLiveTopicRatingFailure());
  }
}

export function* fetchRcBannersFlow({ fetchAPI }) {
  const region = yield select(getAppRegion);
  const { selectedGradeLevel: gradeLevelId } = yield select(getLiveFilterInfo);

  const data = {
    region,
    limit: 6,
    sectionName: 'regular_class',
  };

  if (gradeLevelId) {
    data.gradeLevelId = gradeLevelId;
  }

  try {
    const { banners: bannerList } = yield call(
      apiFetchBannerList,
      fetchAPI,
      data,
    );

    yield put(fetchRcBannersSuccess(bannerList));
  } catch (error) {
    yield put(fetchRcBannersFailure());
  }
}

export default function*(helpers) {
  yield all([
    takeLatest(fetchLiveFilterInfo.type, fetchLiveFilterInfoFlow, helpers),
    takeLatest(fetchPromotionInfo.type, fetchPromotionInfoFlow, helpers),
    takeLatest(fetchHomeLessonList.type, fetchHomeLessonListFlow, helpers),
    takeLatest(fetchLiveUpcoming.type, fetchLiveUpcomingFlow, helpers),
    takeLatest(fetchLiveTopic.type, fetchLiveTopicFlow, helpers),
    takeLatest(fetchLiveTopics.type, fetchLiveTopicsFlow, helpers),
    takeLatest(fetchRcBanners.type, fetchRcBannersFlow, helpers),
    takeLatest(saveLiveLesson.type, saveLiveLessonFlow, helpers),
    takeLatest(unSaveLiveLesson.type, unSaveLiveLessonFlow, helpers),
    takeLatest(
      updateLiveLessonProgress.type,
      updateLiveLessonProgressFlow,
      helpers,
    ),
    takeLatest(
      fetchScheduleUpcomingLesson.type,
      fetchScheduleUpcomingLessonFlow,
      helpers,
    ),
    takeLatest(
      fetchSchedulePastLesson.type,
      fetchSchedulePastLessonFlow,
      helpers,
    ),
    takeLatest(fetchSubscribedTopic.type, fetchSubscribedTopicFlow, helpers),
    takeLatest(
      fetchSubscribedPreOrderedTopic.type,
      fetchSubscribedPreOrderedTopicFlow,
      helpers,
    ),
    takeLatest(
      fetchSubscribedOngoingTopic.type,
      fetchSubscribedOngoingTopicFlow,
      helpers,
    ),
    takeLatest(
      fetchSubscribedCompletedTopic.type,
      fetchSubscribedCompletedTopicFlow,
      helpers,
    ),
    takeLatest(
      removeUpcomingScheduleLesson.type,
      removeUpcomingScheduleLessonFlow,
      helpers,
    ),
    takeLatest(postLiveLessonRating.type, postLiveLessonRatingFlow, helpers),
    takeLatest(postLiveTopicRating.type, postLiveTopicRatingFlow, helpers),
  ]);
}
