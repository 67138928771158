// @TODO: change the naming of the env variables to be LIKE_THIS
exports.AppDomain = 'localhost';
exports.CookieTTLDays = 30;
exports.ApiURL = 'https://alb-uat.snapask.co';
exports.TRACKING_API_URL = 'https://log-event-dev.snapask.com';

exports.SNAPASK_SITE_ENDPOINT = 'http://localhost:3000';
exports.ACADEMY_SITE_ENDPOINT = 'http://localhost:3001';
exports.SNAPASK_QA_SITE_ENDPOINT = 'http://localhost:3000';

exports.GOOGLE_TAG_MANAGER_ID = 'GTM-T2392L';
exports.GOOGLE_TAG_MANAGER_ENV =
  '&gtm_auth=W_CrcdjsoFLyiRvXhQLesQ&gtm_preview=env-70&gtm_cookies_win=x';

exports.ZENDESK_FAQ_SITE_ENDPOINT = 'https://support.snapask.com/hc';

exports.BRIGHTCOVE_ACCOUNT_ID = '6297938898001';
exports.BRIGHTCOVE_PLAYER_ID = 'HFiWLatSM';
