import { createAction } from 'utils/redux';

// eslint-disable-next-line import/prefer-default-export
export const toggleSocialWidget = createAction('TOGGLE_SOCIAL_WIDGET');

export const openSocialWidget = createAction('OPEN_SOCIAL_WIDGET');

export const openSocialWidgetFromFreeTrial = createAction(
  'OPEN_SOCIAL_WIDGET_FROM_FREE_TRIAL',
);

export const closeSocialWidget = createAction('CLOSE_SOCIAL_WIDGET');

export const setSocialWidgetProps = createAction('SET_SOCIAL_WIDGET_PROPS');
