import {
  toggleSocialWidget,
  openSocialWidget,
  closeSocialWidget,
  openSocialWidgetFromFreeTrial,
  setSocialWidgetProps,
} from './action';

const INITIAL_STATE = {
  isSocialWidgetOpen: false,
  linkType: 'default',
  socialWidgetProps: {},
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case openSocialWidgetFromFreeTrial.type:
      return {
        ...state,
        linkType: 'freeTrial',
        isSocialWidgetOpen: true,
      };
    case openSocialWidget.type:
      return {
        ...state,
        linkType: 'default',
        isSocialWidgetOpen: true,
      };
    case closeSocialWidget.type:
      return {
        ...state,
        isSocialWidgetOpen: false,
      };
    case toggleSocialWidget.type:
      return {
        ...state,
        linkType: 'default',
        isSocialWidgetOpen: !state.isSocialWidgetOpen,
      };
    case setSocialWidgetProps.type:
      return {
        ...state,
        socialWidgetProps: {
          ...state.socialWidgetProps,
          ...payload,
        },
      };
    default:
      return state;
  }
};

export default reducer;
