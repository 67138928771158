import { EMPTY, ERROR, READY, LOADING } from 'constants/loadingState';

/**
 * create an action creator with specified action type
 * @param {string} type action type
 */
export const createAction = type => {
  const actionCreator = payload => ({
    type,
    payload,
  });
  actionCreator.type = type;

  return actionCreator;
};

export const createCommonActions = type => [
  createAction(type),
  createAction(`${type}_SUCCESS`),
  createAction(`${type}_FAILURE`),
  createAction(`${type}_CLEAR`),
];

export const INITIAL_STATE_ACTION_STATUS = {
  /** @type {string} */
  error: undefined,
  state: EMPTY,
};

/**
 * @param {string} initType
 * @param {string} successType
 * @param {string} failureType
 */
export const createActionStatusReducer = (
  initType,
  successType = `${initType}_SUCCESS`,
  failureType = `${initType}_FAILURE`,
  clearType = `${initType}_CLEAR`,
) => (state = INITIAL_STATE_ACTION_STATUS, { type, payload }) => {
  switch (type) {
    case initType:
      return {
        error: undefined,
        state: LOADING,
      };
    case successType:
      return {
        error: undefined,
        state: READY,
      };
    case failureType:
      return {
        error: payload?.error,
        state: ERROR,
        ...payload,
      };
    case clearType:
      return INITIAL_STATE_ACTION_STATUS;

    default:
      return state;
  }
};
