import { EXTERNAL_VIDEO_SOURCES } from 'routes/live/constants/liveLessonVideoSource';
import {
  LOCKED,
  UPCOMING,
  VIDEO_REPLAY_SOON,
  EXTERNAL_SOURCE,
  VIDEO_PLAYER,
  VIDEO_NOT_UPLOADED,
  WRONG_REAL_LIVE,
} from 'routes/live/constants/liveEpisodeState';

/* NOTE  
  有 video_id 有真直播 => video
  有 video_id 沒真直播 => video
  沒 video_id 有真直播 => real live
  沒 video_id 沒真直播 => video 並顯示 something went wrong on header
*/
export default function getEpisodeState({
  isTopicEnrolled,
  watchEnabled,
  videoId,
  videoUrl,
  videoSource,
  chatRoomUrl,
  chatRoomType,
  isLive,
  isUpcoming,
  isFinished,
}) {
  if (!isTopicEnrolled && !watchEnabled) {
    return LOCKED;
  }

  const isExternalVideoSource =
    EXTERNAL_VIDEO_SOURCES.includes(videoSource) ||
    (EXTERNAL_VIDEO_SOURCES.includes(chatRoomType) && !videoId) ||
    (!videoUrl && chatRoomUrl);

  if (isFinished && watchEnabled && !videoUrl && !isExternalVideoSource)
    return VIDEO_REPLAY_SOON;

  if (
    (isTopicEnrolled && !watchEnabled) ||
    (isUpcoming && !videoId && !chatRoomUrl)
  ) {
    return UPCOMING;
  }

  if (isExternalVideoSource && !chatRoomUrl) return WRONG_REAL_LIVE;

  if (
    !isFinished &&
    ((isExternalVideoSource && videoUrl) || (isExternalVideoSource && !videoId))
  )
    return EXTERNAL_SOURCE;

  if ((isLive || isFinished) && !videoId) return VIDEO_NOT_UPLOADED;

  return VIDEO_PLAYER;
}
