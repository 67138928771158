import { fetchUserSuccess, updateUserInfo, resetUser } from './action';

/**
 * @typedef {Object} userInfo
 * @property {string} firstName
 * @property {string} lastName
 * @property {string} role
 * @property {string} avatar
 * @property {string} username
 */

/**
 * @typedef {Object} Faq
 * @property {string} cancelSubscription the zendesk faq link about cancel subscription
 */

/** @type {{ token: string, info: userInfo,faq: Faq}} */
const INITIAL_STATE = {
  token: '',
  info: {},
  faq: { cancelSubscription: '' },
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case fetchUserSuccess.type:
      return { ...state, ...action.payload };
    case updateUserInfo.type:
      return { ...state, ...action.payload };
    case resetUser.type:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
}
