import { all, put, takeLatest, select, call } from 'redux-saga/effects';

import {
  apiFetchMyLearningUpNext,
  apiFetchMyLearningContinueWatching,
  apiFetchRecommendLiveLesson,
  apiFetchRecommendCourseLesson,
} from 'services/apis';
import {
  fetchMyLearningUpNext,
  fetchMyLearningUpNextSuccess,
  fetchMyLearningUpNextFailure,
  fetchMyLearningData,
  fetchMyLearningDataSuccess,
  fetchMyLearningDataFailure,
  fetchMyLearningContinueWatching,
  fetchMyLearningContinueWatchingSuccess,
  fetchMyLearningContinueWatchingFailure,
  fetchMyLearningRecommendLesson,
  fetchMyLearningRecommendLessonSuccess,
  fetchMyLearningRecommendLessonFailure,
} from 'reduxModules/myLearning/action';

import { getContinueWatchingPageSetting } from 'reduxModules/myLearning/selector';
import {
  fetchSubscribedTopic,
  fetchScheduleUpcomingLesson,
} from 'reduxModules/live/action';
import { fetchUserPurchasedCourse } from 'reduxModules/course/action';
import {
  getLiveUserSubscribedTopics,
  getLiveUserScheduleUpcomingLessons,
} from 'reduxModules/live/selector';
import { getUserPurchasedCourse } from 'reduxModules/course/selector';

import { transformLesson } from 'reduxModules/live/transform';
import { transformLesson as transformCourseLesson } from 'reduxModules/course/transform';

import {
  transformRecommendLiveLesson,
  transformRecommendCourseLesson,
} from './transform';

export function* fetchMyLearningDataFlow({ fetchAPI }) {
  const userTopic = yield select(getLiveUserSubscribedTopics);
  const userCourse = yield select(getUserPurchasedCourse);
  const { allLiveLessons: userScheduleLessons } = yield select(
    getLiveUserScheduleUpcomingLessons,
  );

  try {
    const { userContents } = yield call(
      apiFetchMyLearningContinueWatching,
      fetchAPI,
    );

    if (userTopic.length === 0) {
      yield put(fetchSubscribedTopic());
    }

    if (userCourse.length === 0) {
      yield put(fetchUserPurchasedCourse());
    }

    if (userScheduleLessons.length === 0) {
      yield put(fetchScheduleUpcomingLesson());
    }

    yield put(
      fetchMyLearningDataSuccess({ continueWatchingLessons: userContents }),
    );
  } catch (error) {
    yield put(fetchMyLearningDataFailure(error));
  }
}

export function* fetchMyLearningUpNextFlow({ fetchAPI }) {
  try {
    const { courseLesson, liveLesson } = yield call(
      apiFetchMyLearningUpNext,
      fetchAPI,
    );

    yield put(
      fetchMyLearningUpNextSuccess({
        ...(!!courseLesson && {
          courseLesson: transformCourseLesson(courseLesson),
        }),
        ...(!!liveLesson && { liveLesson: transformLesson(liveLesson) }),
      }),
    );
  } catch (error) {
    yield put(fetchMyLearningUpNextFailure(error));
  }
}

export function* fetchMyLearningContinueWatchingFlow({ fetchAPI }) {
  const { page, sizePerPage } = yield select(getContinueWatchingPageSetting);
  try {
    const { userContents, totalPages, currentPage } = yield call(
      apiFetchMyLearningContinueWatching,
      fetchAPI,
      {
        sizePerPage,
        page,
      },
    );

    yield put(
      fetchMyLearningContinueWatchingSuccess({
        continueWatchingLessons: userContents,
        totalPages,
        page: currentPage,
      }),
    );
  } catch (error) {
    yield put(fetchMyLearningContinueWatchingFailure(error));
  }
}

export function* fetchMyLearningRecommendLessonFlow({ fetchAPI }) {
  try {
    const [{ freeLiveTopics }, { freeCourses }] = yield all([
      call(apiFetchRecommendLiveLesson, fetchAPI, { limit: 8 }),
      call(apiFetchRecommendCourseLesson, fetchAPI, { limit: 8 }),
    ]);

    yield put(
      fetchMyLearningRecommendLessonSuccess({
        freeLiveTopics: transformRecommendLiveLesson(freeLiveTopics),
        freeCourses: transformRecommendCourseLesson(freeCourses),
      }),
    );
  } catch (error) {
    yield put(fetchMyLearningRecommendLessonFailure(error));
  }
}

export default function* myLearningSaga(helpers) {
  yield all([
    takeLatest(fetchMyLearningData.type, fetchMyLearningDataFlow, helpers),
    takeLatest(fetchMyLearningUpNext.type, fetchMyLearningUpNextFlow, helpers),
    takeLatest(
      fetchMyLearningContinueWatching.type,
      fetchMyLearningContinueWatchingFlow,
      helpers,
    ),
    takeLatest(
      fetchMyLearningRecommendLesson.type,
      fetchMyLearningRecommendLessonFlow,
      helpers,
    ),
  ]);
}
