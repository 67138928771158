import { combineReducers } from 'redux';

import actionStatus from './actionStatus/reducer';
import app from './app/reducer';
import user from './user/reducer';
import alertBanner from './alertBanner/reducer';
import report from './report/reducer';
import home from './home/reducer';
import homeRevamp from './homeRevamp/reducer';
import course from './course/reducer';
import live from './live/reducer';
import instructorProfile from './instructorProfile/reducer';
import upsellBanner from './upsellBanner/reducer';
import siteInfo from './siteInfo/reducer';
import checkoutCollection from './checkoutCollection/reducer';
import bundleCart from './bundleCart/reducer';
import myLearning from './myLearning/reducer';
import account from './account/reducer';
import socialWidget from './socialWidget/reducer';
import tacking from './tracking/reducer';

export default combineReducers({
  actionStatus,
  app,
  user,
  alertBanner,
  report,
  home,
  homeRevamp,
  course,
  live,
  upsellBanner,
  siteInfo,
  checkoutCollection,
  bundleCart,
  instructorProfile,
  myLearning,
  account,
  socialWidget,
  tacking,
});
