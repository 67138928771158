export const RC_ALL_INSTRUCTOR = 'rc_all_instructor';
export const HOME_EPISODE = 'home_episode';
export const UPCOMING_EPISODE = 'upcoming_episode';
export const MY_UPCOMING_SCHEDULE = 'my_upcoming_schedule';
export const MY_ENDED_SCHEDULE = 'my_ended_schedule';
export const TOPIC_ABOUT = 'topic_about';
export const MY_LEARNING = 'my_learning';
export const TOPIC_HOME = 'topic_home';

// for topic list
export const RC_HOME = 'rc_home';
export const ALL_CLASS = 'all_class';
export const MY_ONGOING_CLASS = 'my_ongoing_class';
export const MY_ENDED_CLASS = 'my_ended_class';

export const LIVE_TOPIC = 'live_topic';
export const AVAILABLE_LESSON = 'available_lesson';
