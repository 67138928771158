import { createAction, createCommonActions } from 'utils/redux';

export const [
  fetchCourseListWithGroup,
  fetchCourseListWithGroupSuccess,
  fetchCourseListWithGroupFailure,
] = createCommonActions('FETCH_COURSE_LIST_WITH_GROUP');

export const [
  fetchCourseFeaturedList,
  fetchCourseFeaturedListSuccess,
  fetchCourseFeaturedListFailure,
] = createCommonActions('FETCH_FEATURED_COURSE_LIST');

export const [
  fetchCourseFreeList,
  fetchCourseFreeListSuccess,
  fetchCourseFreeListFailure,
] = createCommonActions('FETCH_FREE_COURSE_LIST');

export const [
  fetchGroupCourseList,
  fetchGroupCourseListSuccess,
  fetchGroupCourseListFailure,
] = createCommonActions('FETCH_GROUP_COURSE_LIST');

export const [
  fetchCourse,
  fetchCourseSuccess,
  fetchCourseFailure,
] = createCommonActions('FETCH_COURSE');

export const [
  fetchCourseAnnouncements,
  fetchCourseAnnouncementsSuccess,
  fetchCourseAnnouncementsFailure,
  fetchCourseAnnouncementsClear,
] = createCommonActions('FETCH_ANNOUNCEMENTS');

export const [
  postCourseRating,
  postCourseRatingSuccess,
  postCourseRatingFailure,
] = createCommonActions('POST_COURSE_RATING');

export const [
  updateCourseProgress,
  updateCourseProgressSuccess,
  updateCourseProgressFailure,
] = createCommonActions('UPDATE_COURSE_PROGRESS');

export const [
  fetchLearningCourse,
  fetchLearningCourseSuccess,
  fetchLearningCourseFailure,
] = createCommonActions('FETCH_LEARNING_COURSE');

export const [
  fetchUserPurchasedCourse,
  fetchUserPurchasedCourseSuccess,
  fetchUserPurchasedCourseFailure,
] = createCommonActions('FETCH_USER_PURCHASED_COURSE');

export const [
  fetchCoursePromotion,
  fetchCoursePromotionSuccess,
  fetchCoursePromotionFailure,
] = createCommonActions('FETCH_COURSE_PROMOTION');

export const [
  fetchCourseBanners,
  fetchCourseBannersSuccess,
  fetchCourseBannersFailure,
] = createCommonActions('FETCH_COURSE_BANNERS');

export const [
  fetchCourseGradeLevels,
  fetchCourseGradeLevelsSuccess,
  fetchCourseGradeLevelsFailure,
] = createCommonActions('FETCH_COURSE_GRADE_LEVELS');

export const updateCourseSelectedGradeLevel = createAction(
  'UPDATE_COURSE_SELECTED_GRADE_LEVEL',
);

export const [
  fetchGroupCourseListForHome,
  fetchGroupCourseListForHomeSuccess,
  fetchGroupCourseListForHomeFailure,
] = createCommonActions('FETCH_GROUP_COURSE_LIST_FOR_HOME');

export const clearGroupCourseListForHome = createAction(
  'CLEAR_GROUP_COURSE_LIST_FOR_HOME',
);

export const updateCourseHomeCurrentPage = createAction(
  'UPDATE_COURSE_HOME_CURRENT_PAGE',
);
