import { createCommonActions, createAction } from 'utils/redux';

export const [
  submitReport,
  submitReportSuccess,
  submitReportFailed,
] = createCommonActions('SUBMIT_REPORT');

export const [
  uploadFile,
  uploadFileSuccess,
  uploadFileFailed,
] = createCommonActions('UPLOAD_FILE');

export const removeFile = createAction('REMOVE_FILE_REQUEST');

export const toggleReportForm = createAction('TOGGLE_REPORT_FORM');

export const saveCurrentData = createAction('SAVE_CURRENT_DATA');
