import { REGULAR_CLASS_TYPE, COURSE_TYPE } from 'constants/routingPath';
import { transformContentHashtags } from 'reduxModules/course/transform';

import { getSubjectString } from './utils';

export const transformLiveTopicList = liveTopics =>
  liveTopics.map(({ name, hashtag, ...props }) => ({
    ...props,
    contentType: REGULAR_CLASS_TYPE,
    title: name,
    hashtags: hashtag ? transformContentHashtags([hashtag]).hashtags : [],
  }));

export const transformCourseList = courses =>
  courses.map(({ picture, contentHashtags, ...props }) => {
    const { mediumUrl } = picture;
    return {
      ...props,
      picture,
      contentType: COURSE_TYPE,
      pictureUrl: mediumUrl,
      ...transformContentHashtags(contentHashtags),
    };
  });

export function transformInstructorProfile(instructorProfile) {
  const { about, courses, instructor, liveTopics } = instructorProfile;
  const { subjects, instructorHashtags } = instructor;

  return {
    about,
    // align the structor of course data with live's.
    liveTopics: transformLiveTopicList(liveTopics),
    courses: transformCourseList(courses),
    instructor: {
      ...instructor,
      subjectString: getSubjectString(subjects),
      hashtagString: instructorHashtags.join('•'),
    },
  };
}

export function transformInstructorList(instructors) {
  return instructors.map(instructor => ({
    ...instructor,
    subjectString: getSubjectString(instructor.subjects),
    hashtagString: instructor.instructorHashtags.join('•'),
  }));
}
