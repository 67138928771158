import { all, call } from 'redux-saga/effects';

import userSaga from './user/saga';
import authSaga from './auth/saga';
import reportSaga from './report/saga';
import homeSaga from './home/saga';
import homeRevamp from './homeRevamp/saga';
import courseSaga from './course/saga';
import liveSaga from './live/saga';
import googleTagManagerSaga from './googleTagManager/saga';
import upsellBannerSaga from './upsellBanner/saga';
import siteInfoSaga from './siteInfo/saga';
import checkoutCollectionSaga from './checkoutCollection/saga';
import bundleCartSaga from './bundleCart/saga';
import instructorProfileSaga from './instructorProfile/saga';
import myLearningSaga from './myLearning/saga';
import account from './account/saga';
import tracking from './tracking/saga';

export default function* root(helpers) {
  yield all([
    call(userSaga, helpers),
    call(authSaga, helpers),
    call(reportSaga, helpers),
    call(homeSaga, helpers),
    call(homeRevamp, helpers),
    call(courseSaga, helpers),
    call(liveSaga, helpers),
    call(upsellBannerSaga, helpers),
    call(googleTagManagerSaga, helpers),
    call(siteInfoSaga, helpers),
    call(checkoutCollectionSaga, helpers),
    call(bundleCartSaga, helpers),
    call(instructorProfileSaga, helpers),
    call(myLearningSaga, helpers),
    call(account, helpers),
    call(tracking, helpers),
  ]);
}
