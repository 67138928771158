import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

import getLanguageFolder from './getLanguageFolder';
import commonOptions from './options';

const options = {
  backend: {
    /**
     * the first i18n is created by crowdin.sh, and the second i18n is related to Crowdin setting.
     * it may be different between every web project, be careful!!
     */
    loadPath: (lngs, namespaces) =>
      `/i18n/${getLanguageFolder(lngs[0])}/${namespaces}.json`,
  },
  ...commonOptions,
};

i18n
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init(options);

if (!i18n.isInitialized) {
  i18n.init(options);
}

export default i18n;
