import { all, call, put, takeLatest, select } from 'redux-saga/effects';

import {
  apiFetchSchoolTypeList,
  apiFetchSubjectList,
  apiUpdateUserAttributeData,
  apiSendPhoneVerificationCode,
  apiVerifyPhone,
  apiResentPhoneVerificationCode,
} from 'services/apis';
import { getUserInfo } from 'reduxModules/user/selector';
import { showAlertBanner } from 'reduxModules/alertBanner/action';
import {
  SUCCESS,
  ERROR,
} from '@snapask/common-components/dist/constants/alertBannerType';

import {
  fetchSchoolTypeList,
  fetchSchoolTypeListSuccess,
  fetchSchoolTypeListFailure,
  fetchSubjectList,
  fetchSubjectListSuccess,
  fetchSubjectListFailure,
  updateUserAttributeData,
  updateUserAttributeDataSuccess,
  updateUserAttributeDataFailure,
  sendPhoneVerificationCode,
  sendPhoneVerificationCodeSuccess,
  sendPhoneVerificationCodeFailure,
  verifyPhone,
  verifyPhoneSuccess,
  verifyPhoneFailure,
  resentPhoneVerificationCode,
  resentPhoneVerificationCodeSuccess,
  resentPhoneVerificationCodeFailure,
} from './action';

export function* fetchSchoolTypesFlow({ fetchAPI }) {
  try {
    const schoolTypeList = yield call(apiFetchSchoolTypeList, fetchAPI);

    yield put(fetchSchoolTypeListSuccess(schoolTypeList));
  } catch (error) {
    yield put(fetchSchoolTypeListFailure(error));
  }
}

export function* fetchSubjectListFlow({ fetchAPI }, { payload }) {
  try {
    const { subjectCategories } = yield call(
      apiFetchSubjectList,
      fetchAPI,
      payload,
    );

    yield put(fetchSubjectListSuccess(subjectCategories));
  } catch (error) {
    yield put(fetchSubjectListFailure(error));
  }
}

export function* updateUserAttributeDataFlow({ fetchAPI }, { payload }) {
  const { userId } = yield select(getUserInfo);
  try {
    yield call(apiUpdateUserAttributeData, fetchAPI, { userId, data: payload });

    yield put(updateUserAttributeDataSuccess());
  } catch (error) {
    yield put(updateUserAttributeDataFailure(error));
  }
}

export function* sendPhoneVerificationCodeFlow({ fetchAPI }, { payload }) {
  try {
    yield call(apiSendPhoneVerificationCode, fetchAPI, payload);

    yield put(sendPhoneVerificationCodeSuccess());
  } catch (error) {
    yield put(sendPhoneVerificationCodeFailure(error));
    yield put(
      showAlertBanner({
        message: error.errorMessage,
        type: ERROR,
      }),
    );
  }
}

export function* verifyPhoneFlow({ fetchAPI }, { payload: verificationCode }) {
  const { userId } = yield select(getUserInfo);
  try {
    yield call(apiVerifyPhone, fetchAPI, { verificationCode, userId });

    yield put(verifyPhoneSuccess());
  } catch (error) {
    yield put(verifyPhoneFailure(error));
    yield put(
      showAlertBanner({
        message: error.errorMessage,
        type: ERROR,
      }),
    );
  }
}

export function* resentPhoneVerificationCodeFlow({ fetchAPI, i18n }) {
  const { userId } = yield select(getUserInfo);
  try {
    yield call(apiResentPhoneVerificationCode, fetchAPI, { userId });

    yield put(resentPhoneVerificationCodeSuccess());
    yield i18n.loadNamespaces(['onboarding']);
    yield put(
      showAlertBanner({
        message: i18n.t(
          'onboarding:onboarding.setUp.phoneNumber.sendAlertBanner',
        ),
        type: SUCCESS,
      }),
    );
  } catch (error) {
    yield put(resentPhoneVerificationCodeFailure(error));
    yield put(
      showAlertBanner({
        message: error.errorMessage,
        type: ERROR,
      }),
    );
  }
}

export default function* accountSaga(helpers) {
  yield all([
    takeLatest(fetchSchoolTypeList.type, fetchSchoolTypesFlow, helpers),
    takeLatest(fetchSubjectList.type, fetchSubjectListFlow, helpers),
    takeLatest(
      updateUserAttributeData.type,
      updateUserAttributeDataFlow,
      helpers,
    ),
    takeLatest(
      sendPhoneVerificationCode.type,
      sendPhoneVerificationCodeFlow,
      helpers,
    ),
    takeLatest(verifyPhone.type, verifyPhoneFlow, helpers),
    takeLatest(
      resentPhoneVerificationCode.type,
      resentPhoneVerificationCodeFlow,
      helpers,
    ),
  ]);
}
