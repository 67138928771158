import { all, select, put, takeLatest } from 'redux-saga/effects';

import {
  bundleCartAddItem,
  bundleCartAddItemSuccess,
  bundleCartRemoveItem,
  bundleCartRemoveItemSuccess,
} from './action';
import { getBundleCartItems } from './selector';
import { transformContent } from './transform';

export function* bundleCartAddItemFlow({ payload: content }) {
  const transformedContent = transformContent(content);
  yield put(bundleCartAddItemSuccess(transformedContent));
}

export function* bundleCartRemoveItemFlow({ payload: { type, contentId } }) {
  const currentItems = yield select(getBundleCartItems);
  const removeItemIndex = currentItems.findIndex(
    currentItem => currentItem.type === type && currentItem.id === contentId,
  );

  const copiedCurrentItems = [...currentItems];
  copiedCurrentItems.splice(removeItemIndex, 1);

  yield put(bundleCartRemoveItemSuccess(copiedCurrentItems));
}

export default function* bundleCartSaga() {
  yield all([
    takeLatest(bundleCartAddItem.type, bundleCartAddItemFlow),
    takeLatest(bundleCartRemoveItem.type, bundleCartRemoveItemFlow),
  ]);
}
