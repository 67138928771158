/**
 * js-cookie document:
 * https://github.com/js-cookie/js-cookie
 */

import jsCookie from 'js-cookie';
import config from 'config';

export function Cookie(
  domain = config.AppDomain,
  expires = config.CookieTTLDays,
) {
  const defaults = {
    domain,
    expires,
    ...(__DEV__ ? null : { sameSite: 'Lax', secure: true }),
  };

  this.set = function set(key, value, options) {
    jsCookie.set(key, value, { ...defaults, ...options });
  };

  this.get = function get(key) {
    return jsCookie.get(key);
  };

  this.remove = function remove(key, options) {
    jsCookie.remove(key, { ...defaults, ...options });
  };
}

export default new Cookie();
