import { put, call, takeLatest, select, all } from 'redux-saga/effects';
import { showModal, showMessage } from 'reduxModules/app/action';
import Cookie from 'utils/cookie';

import { apiAppLaunch, apiLogout } from 'services/apis';

import { HOME, LOGIN } from 'constants/routingPath';
import { getAppLocale } from 'reduxModules/app/selector';
import { updateUserInfo, resetUser } from 'reduxModules/user/action';
import { fetchSiteInfo } from 'reduxModules/siteInfo/action';
import { generateOfficialSiteURL } from 'reduxModules/navigation/selector';
import { go, push } from 'reduxModules/navigation/action';

import {
  TOKEN_INVALID_ERROR_CODE_REGEX,
  TOKEN_AMOUNT_EXCEED,
  ONLY_STUDENT_HAVE_AUTH,
  ONLY_TUTOR_HAVE_AUTH,
} from 'constants/errorCode';
import { NAME_TOKEN, NAME_USER_DATA } from 'constants/cookieName';
import {
  appLaunch,
  appLaunchSuccess,
  userLogout,
  userLogoutSuccess,
  userLogoutFailure,
} from './action';
import { transformProfileData } from './transform';

export function clearAuthCookies() {
  Cookie.remove(NAME_TOKEN);
  Cookie.remove(NAME_USER_DATA);
}

function* discardUserAuth() {
  yield call(clearAuthCookies);
  yield put(fetchSiteInfo());
}

export function* forceLogoutFlow({ i18n, errorCode }) {
  yield call(discardUserAuth);

  yield put(
    showModal({
      type: 'error',
      title: i18n.t('common:common.forceLogout.title'),
      content:
        errorCode === TOKEN_AMOUNT_EXCEED
          ? i18n.t('common:common.deviceAmountExceed')
          : i18n.t('common:common.tokenExpired'),
      okText: i18n.t('common:common.forceLogout.closeButtonText'),
    }),
  );

  if (process.env.BROWSER) {
    const getOfficialSiteURL = yield select(generateOfficialSiteURL);
    const loginURL = getOfficialSiteURL(LOGIN);
    yield put(push(loginURL));
  }
}

export function* handleGlobalException({ i18n }, { errorCode, message }) {
  if (TOKEN_INVALID_ERROR_CODE_REGEX.test(errorCode)) {
    yield call(forceLogoutFlow, { i18n, errorCode });
    return;
  }

  yield put(
    showMessage({
      type: 'error',
      content: message,
    }),
  );

  if (
    errorCode === ONLY_STUDENT_HAVE_AUTH ||
    errorCode === ONLY_TUTOR_HAVE_AUTH
  ) {
    if (process.env.BROWSER) {
      const getOfficialSiteURL = yield select(generateOfficialSiteURL);
      const homeURL = getOfficialSiteURL(HOME);
      yield put(push(homeURL));
    }
  }
}

export function* userLogoutFlow({ fetchAPI }) {
  try {
    yield call(apiLogout, fetchAPI);
    yield call(discardUserAuth);
    // @TODO: change footer lang to be site's language.
    // @TODO: show alert text.

    yield put(resetUser());
    yield put(userLogoutSuccess());
    // Refresh the page
    yield put(go(0));
  } catch (err) {
    yield put(userLogoutFailure());
  }
}

export function* appLaunchFlow({ fetchAPI }) {
  const locale = yield select(getAppLocale);

  const profileData = yield call(apiAppLaunch, fetchAPI, locale);
  yield put(updateUserInfo(transformProfileData(profileData)));
  yield put(appLaunchSuccess());
}

function* authSaga(helpers) {
  yield all([
    takeLatest(appLaunch.type, appLaunchFlow, helpers),
    takeLatest(userLogout.type, userLogoutFlow, helpers),
  ]);
}

export default authSaga;
