import { REGULAR_CLASS_TYPE } from 'constants/routingPath';
import { transformContentHashtags } from 'reduxModules/course/transform';

// eslint-disable-next-line import/prefer-default-export
export const transformRegularClassLessonList = lessonList =>
  lessonList.map(({ name, hashtag, ...props }) => ({
    ...props,
    contentType: REGULAR_CLASS_TYPE,
    title: name,
    hashtags: hashtag ? transformContentHashtags([hashtag]).hashtags : [],
  }));
