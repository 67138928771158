import { createCommonActions, createAction } from 'utils/redux';

export const [
  fetchUser,
  fetchUserSuccess,
  fetchUserFailure,
] = createCommonActions('FETCH_USER');

export const updateUserInfo = createAction('UPDATE_USER_PROFILE');
export const resetUser = createAction('RESET_USER');
