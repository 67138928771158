// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".xU-jJ{position:fixed;bottom:0;right:0;z-index:var(--zIndex-navbar)}", ""]);
// Exports
exports.locals = {
	"root": "xU-jJ"
};
module.exports = exports;
