import { fetchUpsellBannerSuccess } from './action';

export const INITIAL_UPSELL_BANNER = {};

const upsellBanner = (state = INITIAL_UPSELL_BANNER, { type, payload }) => {
  switch (type) {
    case fetchUpsellBannerSuccess.type:
      return {
        ...state,
        [payload.page]: payload.upsellBanner,
      };

    default:
      return state;
  }
};

export default upsellBanner;
