/**
 * Document: https://www.i18next.com/principles/namespaces
 * One translation file is one namespace.
 * Should be defined all namespaces here, or it will show the latency text-changed on client-side.
 */

export default [
  'common',
  'account',
  'seo',
  'navbar',
  'home',
  'course',
  'live',
  'instructorProfile',
  'premium',
  'footer',
  'navigation',
  'sdk',
  'rating',
  'purchase',
  'myLearning',
  'landingPage',
  'onboarding',
];

export const DEFAULT_NAMESPACE = 'seo';
